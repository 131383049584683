import { CostCenterData } from "~/application/types/entities/CustomerBI.type";
import { Box } from "~/components/Box";
import { Card } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { asCurrency } from "~/utils/mask.utils";

type CostCenterResumeProps = { costCenterSummary?: CostCenterData[] };

export function CostCenterResume({ costCenterSummary }: CostCenterResumeProps) {
  return (
    <Card css={{ p: "$5", overflow: "hidden" }}>
        <H4>Valor por centro de custo</H4>
      <Box css={{ overflowY: "auto", pt: "$10", maxHeight: "fit-content" }}>
        {costCenterSummary?.map((costCenter) => (
          <Flex
            key={costCenter.name}
            justify="between"
            css={{
              py: "$4",
              borderRadius: "$2",
              alignItems: "center",
              borderBottom: "1px solid $neutrals-lightest",
            }}
          >
            <Flex align="center" gap="4">
              <Flex gap="1" direction="column" justify="center">
                <Text fw="600">{costCenter.name}</Text>
                <Text css={{ fontSize: "$sm", color: "$neutrals-darkest" }}>
                  Último pedido: {costCenter.lastOrderDate}
                </Text>
              </Flex>
            </Flex>
            <Text fw="700" css={{ color: "$success-base" }}>
              {asCurrency(costCenter.value)}
            </Text>
          </Flex>
        ))}
      </Box>
    </Card>
  );
}
