import { Order, Vehicle, VehicleQuery } from "~/application/types";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgArrowBack, SvgSearch } from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { DateFormats, displayDate, toDate, toISOString } from "~/utils/date.utils";
import queryString from "query-string";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { VehicleBookingActions, VehicleBookingInfo } from "../utils/vehicle.utils";
import { VehicleQueryForm } from "./VehicleQueryForm";
import { Box } from "~/components/Box";
import { useVehicleBudgetResult } from "../hooks/useVehicleBudget/type";

type VehicleBookingBarProps = {
  bookingInfo: VehicleBookingInfo;
  activeStep: string;
  order?: Order;
  setActiveStep: Dispatch<SetStateAction<string>>;
  actions: VehicleBookingActions;
  vehicleBudget: useVehicleBudgetResult;
};

function createDateTime(date: Date, time: string) {
  const [hours, min] = time.split(":").map((i) => parseInt(i));
  const tmp = new Date(date);

  tmp.setHours(hours, min);
  return tmp;
}

export const VehicleBookingBar = ({
  bookingInfo,
  activeStep,
  setActiveStep,
  actions,
  vehicleBudget,
  order,
}: VehicleBookingBarProps) => {
  const [isEditable, setEditable] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  function handleSubmit(data: VehicleQuery) {
    const vehicleQueryStr = {
      destinationSelected: JSON.stringify(data.destinationSelected),
      originSelected: JSON.stringify(data.originSelected),
      dateGetSelected: toISOString(data.dateGetSelected as Date),
      dateReturnSelected: toISOString(data.dateReturnSelected as Date),
      timeGetSelected: data.timeGetSelected,
      timeReturnSelected: data.timeReturnSelected,
    };

    actions.setVehicle(undefined);

    navigate(
      queryString.stringifyUrl({
        url: "/busca/alugar-carros/resultados",
        query: { ...vehicleQueryStr, orderId: searchParams.get("orderId") },
      })
    );

    setActiveStep("vehicleChoose");

    setEditable(false);
  }

  const handleBarClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      // ignore clicks while editing
      if (!isEditable) {
        // Ignore clicks on the bar itself
        if (event.target !== event.currentTarget) {
          setEditable(true);
        }
      }
    },
    [isEditable]
  );

  const checkInDate = createDateTime(
    bookingInfo.query.dateGetSelected as Date,
    bookingInfo.query.timeGetSelected
  );

  const checkOutDate = createDateTime(
    bookingInfo.query.dateReturnSelected as Date,
    bookingInfo.query.timeReturnSelected
  );

  function switchTitleBar(currentStep: string) {
    const cases: { [key: string]: string } = {
      vehicleChoose: "Escolha do veículo",
      travelers: "Escolha do condutor",
      bookingConfirmation: "Confirmar reserva",
    };

    // eslint-disable-next-line no-prototype-builtins
    if (cases.hasOwnProperty(currentStep)) {
      return cases[currentStep];
    }
  }

  const handlePreviousStep = (currentStep: string) => {
    switch (currentStep) {
      case "travelers":
        setActiveStep("vehicleChoose");
        break;
      case "bookingConfirmation":
        setActiveStep("travelers");
        break;
      case "vehicleChoose":
        navigate("/");
        break;
    }
  };

  const titleBar = switchTitleBar(activeStep);

  return (
    <>
      <Container
        css={{
          "@mxlg": {
            display: "none",
          },
        }}
      >
        {isEditable ? (
          <VehicleQueryForm
            onSubmit={handleSubmit}
            defaultData={bookingInfo.query}
            vehicleBudget={vehicleBudget}
            order={order}
          />
        ) : (
          <Flex align="center" gap="6" onClick={handleBarClick} css={{ py: "$4" }}>
            <Flex direction="column" gap="4">
              <Text variant="dark" size="2" css={{ fw: "700" }}>
                Retirada
              </Text>
              <Text>
                {bookingInfo.query.originSelected.name},{" "}
                <strong>{bookingInfo.query.originSelected.state}</strong>
              </Text>
            </Flex>

            <Flex direction="column" gap="4">
              <Text variant="dark" size="2" css={{ fw: "700" }}>
                Devolução
              </Text>
              <Text>
                {bookingInfo.query.destinationSelected.name},{" "}
                <strong>{bookingInfo.query.destinationSelected.state}</strong>
              </Text>
            </Flex>

            <Flex direction="column" gap="4">
              <Text variant="dark" size="2" css={{ fw: "700" }}>
                Data e hora da retirada
              </Text>
              <Text>{displayDate(checkInDate, DateFormats.LONG_DATE_TIME)}</Text>
            </Flex>

            <Flex direction="column" gap="4">
              <Text variant="dark" size="2" css={{ fw: "700" }}>
                Data e hora da devolução
              </Text>
              <Text>{displayDate(checkOutDate, DateFormats.LONG_DATE_TIME)}</Text>
            </Flex>

            <Button variant="tertiary" css={{ ml: "auto" }}>
              <Icon as={SvgSearch} />
            </Button>
          </Flex>
        )}
      </Container>
      <Container
        css={{
          padding: "$6",
          background: "#0064C5",
          color: "#FFF",

          "@lg": {
            display: "none",
          },
        }}
      >
        <Flex css={{ width: "80%", margin: "0 auto" }} justify="between">
          <Box>
            <Icon fill="#FFF" as={SvgArrowBack} onClick={() => handlePreviousStep(activeStep)} />
          </Box>
          <Box>{titleBar}</Box>
          <Box></Box>
        </Flex>
      </Container>
    </>
  );
};

VehicleBookingBar.displayName = "VehicleBookingBar";
