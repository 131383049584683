import { Coverage } from "~/application/types";
import { VehicleBookingActions, VehicleBookingInfo } from "../utils/vehicle.utils";
import { Card } from "~/components/Card";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { CardHeader } from "../../../components/Card";
import { Flex } from "~/components/Flex";
import { asCurrency } from "~/utils/mask.utils";
import { Text } from "~/components/Text";

export type CoverageItemCardProps = {
  coverage: Coverage;
  bookingInfo: VehicleBookingInfo;
  actions: VehicleBookingActions;
};

export const CoverageItemCard: React.FC<CoverageItemCardProps> = ({
  bookingInfo,
  coverage,
  actions,
}) => {
  const isMobile = useMobile();
  const selected = coverage.id === bookingInfo.coverageId;
  return (
    <Card
      id={coverage.id.toString()}
      key={coverage.id}
      css={{
        "&:hover": {
          scale: "1.1",
          transition: "scale 0.3s ease",
        },

        transform: "scale(0.9)",
        transformOrigin: "center",
        transition: "transform 0.3s ease",
        minWidth: isMobile ? "350px" : "400px",
        cursor: "pointer",
        border: selected ? "4px solid " : "",
        borderColor: selected ? "$neutrals-black" : "",
      }}
      onClick={() => actions.setCoverage(coverage.id)}
    >
      <CardHeader
        css={{
          p: "$1",
          background: "$primary-base",
        }}
      ></CardHeader>
      <Flex
        css={{
          height: "100%",
          px: "$3",
          pt: "$1",
          pb: "$4",
        }}
        gap="4"
        direction={"column"}
        align={"center"}
      >
        <Flex
          justify="end"
          css={{
            width: "100%",
          }}
        >
          <Flex
            css={{
              visibility: selected ? "initial" : "hidden",
              p: "$1",
              px: "$2",
              background: "$neutrals-black",
              borderRadius: "$pill",
            }}
          >
            <Text fw="700" variant={"white"} size="2">
              Selecionado
            </Text>
          </Flex>
        </Flex>
        <Flex direction={"column"} gap="1" align={"center"}>
          <Text
            variant="black"
            size="5"
            fw="700"
            css={{
              ta: "center",
            }}
          >
            {coverage.type}
          </Text>
          <Flex
            css={{
              p: "$3",
              background: selected ? "$neutrals-black" : "$neutrals-lightest ",
              width: "fit-content",
              borderRadius: "$pill",
            }}
          >
            <Text fw="700" variant={selected ? "white" : "black"}>
              {asCurrency(coverage.price)}
            </Text>
          </Flex>
        </Flex>
        <Text
          css={{
            letterSpacing: "normal",
            textAlign: "center",
          }}
          variant={"darkest"}
          fw="400"
        >
          {coverage.description}
        </Text>
      </Flex>
    </Card>
  );
};
