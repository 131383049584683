import { useCallback } from "react";
import { GroupedOrderHistory } from "~/application/types/entities/OrderHistory.type";
import { Box } from "~/components/Box";
import { EmptyState } from "~/components/EmptyState";
import { Flex } from "~/components/Flex";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { H4, H6 } from "~/components/Typography";
import * as DateUtils from "~/utils/date.utils";
import { OrderHistoryListItem } from "./components/OrderHistoryListItem";
import { TabOrderHistoryProps } from "./types";

export function TabOrderHistory({
  order,
  isLoadingOrderHistory,
  orderHistory,
}: TabOrderHistoryProps) {
  
  const listRenderer = useCallback(
    (item: GroupedOrderHistory) => (
      <Box
        css={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <H6 css={{ mb: "$4" }}>
          {DateUtils.displayDate(
            item.date,
            DateUtils.DateFormats.LONG_BRAZILIAN_DATE
          )}
        </H6>

        <Flex gap="4" direction="column">
          {item.history.map((data) => (
            <OrderHistoryListItem order={order} data={data} key={data.uuid} />
          ))}
        </Flex>
      </Box>
    ),
    []
  );

  return (
    <Flex
      direction="column"
      css={{
        px: "$15",
        mt: "$10",
        "@mxlg": {
          p: "$0",
          mt: "$3",
        },
      }}
      gap="6"
    >
      <H4 css={{ "@mxmd": { display: "none" } }}>Histórico do pedido</H4>

      <LazyList
        gap="6"
        items={orderHistory}
        isLoading={isLoadingOrderHistory}
        render={listRenderer}
        skeletonQuantity={10}
        skeletonHeight={92}
        EmptyComponent={
          <EmptyState>
            <Text>Nenhum histórico encontrado para esse pedido</Text>
          </EmptyState>
        }
      />
    </Flex>
  );
}
