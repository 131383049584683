import { FC } from "react";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataColItem, DataListItem } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { CostCenterListItemProps } from "./types";
import { Caption } from "~/components/Typography";
import { Switch } from "~/components/Input";
import useMobile from "../../hooks/useMobile";
import { Text } from "~/components/Text";
import { Flex } from "~/components/Flex";

export const CostCenterListItem: FC<CostCenterListItemProps> = ({
  data,
  onEditClick,
  onToggleState,
  ...props
}) => {
  const isMobile = useMobile();
  return (
    <DataListItem
      data-active={data.isActive}
      {...props}
      css={{ "@mxlg": { flexDirection: "column" } }}
    >
      <DataColItem headerTitle="Nome" data={data.name} />

      <DataColItem headerTitle="Integração Externa" data={data.costCenterCode} />
      <Flex justify="between" css={{ width: "100%", "@lg": { display: "none" }}}>
        {onEditClick && (
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
        )}

        {onToggleState && (
          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        )}
      </Flex>

      <Flex>
        {onEditClick && !isMobile && (
          <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
        )}

        {onToggleState && !isMobile && (
          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        )}
      </Flex>
    </DataListItem>
  );
};

CostCenterListItem.displayName = "CostCenterListItem";
