import { FilterListItem } from "~/core/modules/Booking/components/FilterListItem";
import { Filter } from "~/core/modules/Booking/utils";
import { OrderItems, Vehicle } from "~/application/types";
import { Alert } from "~/components/Alert";
import { Box } from "~/components/Box";
import { Card } from "~/components/Card";
import { CartHeader } from "~/components/Cart";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgAlertTriangle, SvgClose, SvgFilter } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Skeleton } from "~/components/Skeleton";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { vehicleQueryService } from "~/application/usecases";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useCallback, useContext, useMemo, useState } from "react";
import { useFilters } from "../../../../Booking/hooks/useFilters";
import {
  DYNAMIC_VEHICLE_FILTERS,
  STATIC_VEHICLE_FILTERS,
} from "../../../../Booking/modules/BookingVehicle/pages/VehiclesPage/utils";
import { VehicleBookingContext } from "../contexts/VehicleBookingContext";
import { VehicleBookingCart } from "./VehicleBookingCart";
import { VehicleCard } from "./VehicleCard";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Checkbox } from "~/components/Input";
import { VehicleBudgetActionType } from "../hooks/useVehicleBudget/type";
import { Button } from "~/components/Button";
import { asCurrency } from "~/utils/mask.utils";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { usePoliciesFormats } from "~/presentation/shared/hooks/usePoliciesFormats";
import { NavigatorUtils } from "~/application/utils";

type VehicleChooseSectionProps = {
  activeStep: string;
  cartIsOpen: boolean;
  setNextStep: VoidFunction;
  setCartIsOpen: Dispatch<SetStateAction<boolean>>;
};

export function VehicleChooseSection({
  activeStep,
  cartIsOpen,
  setNextStep,
  setCartIsOpen,
}: VehicleChooseSectionProps) {
  const { actions, bookingInfo, queryData, queryClient, vehicleBudget } =
    useContext(VehicleBookingContext);

  const { onActiveBudget, activeBudget, state, dispatch } = vehicleBudget;

  const { contexts, user } = useUser();

  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;

  const returnFeeAnotherCity = bookingInfo.vehicleSelected?.returnFeeAnotherCity || 0;

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const isMobile = useMobile();

  const { data: queryLinks, isFetching: isFetchingLinks } = useQuery(
    [QueryKeys.QUERY_VEHICLE, queryData, queryClient],
    () => queryClient.query(),
    {
      staleTime: QueryTimes.LONGEST,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
      retry: 2,
    }
  );

  const vehiclesQueries = useQueries({
    queries:
      queryLinks?.links.map((link) => ({
        queryFn: () => vehicleQueryService.find(link),
        queryKey: ["road", link],
        retryDelay: queryLinks.waitTime,
        retry: 10,
        staleTime: QueryTimes.LONGEST,
        refetchOnWindowFocus: false,
        keepExistingData: true,
        enabled: !isFetchingLinks,
      })) || [],
  });

  const isLoadingVehicle = useMemo(
    () => vehiclesQueries.some((q) => q.isFetching),
    [vehiclesQueries]
  );

  const isSomeSettled = useMemo(
    () => vehiclesQueries.some((q) => !q.isFetching),
    [vehiclesQueries]
  );

  const vehicleList = useMemo(
    () =>
      vehiclesQueries
        .reduce<Vehicle[]>((p, c) => [...p, ...(c.data || [])], [])
        .sort((a, b) => a.totalPrice - b.totalPrice),
    [vehiclesQueries]
  );

  const listRenderer = useCallback(
    (item: Vehicle) => (
      <VehicleCard
        key={item.id}
        data-active={bookingInfo.vehicleSelected?.id === item.id}
        data={item}
        buttonProps={{
          disabled: bookingInfo.vehicleSelected?.id === item.id,
          onClick: () => {
            NavigatorUtils.scrollToTop(true);
            actions.setVehicle(item);
          },
        }}
        vehicleBudget={vehicleBudget}
      />
    ),
    [bookingInfo, actions, vehicleBudget]
  );

  const vehicles = usePoliciesFormats({
    customerId,
    data: vehicleList,
    itemType: OrderItems.VEHICLE,
  });

  const { filteredData, filters, onFilterChange } = useFilters({
    filters: STATIC_VEHICLE_FILTERS,
    filtersGenerator: DYNAMIC_VEHICLE_FILTERS,
    data: vehicles as Vehicle[],
  });

  const allVehicleBudget = state.length === filteredData.length;

  const handleRemoveAllVehicleBudget = useCallback(() => {
    if (allVehicleBudget) {
      dispatch?.({
        paylod: [],
        type: VehicleBudgetActionType.ADD,
      });
      return;
    }
    dispatch?.({
      paylod: filteredData,
      type: VehicleBudgetActionType.ADD,
    });
  }, [allVehicleBudget, filteredData]);

  const filterRenderer = useCallback(
    (item: Filter) => (
      <FilterListItem
        data={item}
        onFilterChange={onFilterChange}
        key={item.key}
        isLoading={!isSomeSettled}
      />
    ),
    [onFilterChange, isSomeSettled]
  );

  return (
    <Row
      gap="6"
      css={{
        "@mxlg": {
          display: "flex",
          flexDirection: "column",
          zIndex: "999",
          alignItems: "center",
        },
      }}
    >
      <Col
        css={{
          position: "fixed",
          bottom: "0",
          left: "0",
          height: "100%",
          overflowY: "auto",
          transition: "$slow",
          zIndex: "999",
          display: !menuIsOpen ? "none" : "block",
          width: "90%",
          backgroundColor: "White",
        }}
      >
        <Flex justify="between" css={{ width: "90%", margin: "0 auto", mt: "$6" }}>
          <Text>Filtrar resultados</Text>
          <Icon as={SvgClose} onClick={() => setMenuIsOpen(false)} />
        </Flex>

        {isSomeSettled ? (
          <Box>{filters.map(filterRenderer)}</Box>
        ) : (
          <Flex direction="column" gap="4" css={{ p: "$4" }}>
            <Skeleton variant="text-4" />
            <Skeleton variant="text-4" />
            <Skeleton variant="text-4" />
            <Skeleton variant="text-4" />
          </Flex>
        )}
      </Col>
      <Col
        sz="3"
        css={{
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        <Card>
          <CartHeader
            css={{
              "@mxlg": {
                backgroundColor: "$neutrals-lightest",
                display: "flex",
                justifyContent: "center",
                color: "#0064C5",
              },
            }}
            onClick={isMobile ? () => setMenuIsOpen(true) : undefined}
          >
            <Icon
              as={SvgFilter}
              css={{
                "@mxlg": {
                  fill: "#0064C5",
                },
              }}
            />
            <Text>Filtrar resultados</Text>
          </CartHeader>

          {isSomeSettled ? (
            <Box
              css={{
                "@mxlg": {
                  display: "none",
                },
              }}
            >
              {filters.map(filterRenderer)}
            </Box>
          ) : (
            <Flex
              direction="column"
              gap="4"
              css={{
                p: "$4",
                "@mxlg": {
                  display: "none",
                },
              }}
            >
              <Skeleton variant="text-4" />
              <Skeleton variant="text-4" />
              <Skeleton variant="text-4" />
              <Skeleton variant="text-4" />
            </Flex>
          )}
        </Card>
      </Col>

      <Col
        sz="6"
        css={{
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        <Flex direction="column" gap="6">
          <Flex direction="column">
            <Text variant="darkest" fw="500">
              Total de <Text fw="700">{filteredData?.length}</Text> carro
              {Number(filteredData?.length) - 1 ? "s" : ""}
            </Text>
            <Flex justify="between">
              {activeBudget && (
                <Flex align="center" gap="2">
                  <Checkbox checked={allVehicleBudget} onChange={handleRemoveAllVehicleBudget} />
                  <Text fw="500" size="3">
                    Selecionar todos desta página
                  </Text>
                </Flex>
              )}
              <Flex></Flex>
              <Button
                variant="secondary-light"
                onClick={() => onActiveBudget(!activeBudget)}
                disabled={!filteredData.length}
                css={{
                  backgroundColor: activeBudget ? "$neutrals-black" : "transparent",
                  color: activeBudget ? "$neutrals-white" : "$neutrals-darkest",
                }}
              >
                Cotação eletrônica
              </Button>
            </Flex>
          </Flex>

          {(isFetchingLinks || isLoadingVehicle) && (
            <Box>
              <Alert variant="info">
                <Spinner size="sm" />
                <Text css={{ lineHeight: "$6" }}>
                  Aguarde, estamos procurando as melhores condições para sua locação de veículo
                </Text>
              </Alert>
            </Box>
          )}

          <LazyList
            isLoading={!isSomeSettled}
            items={filteredData}
            gap={6}
            render={listRenderer}
            skeletonHeight={348}
            skeletonQuantity={8}
          />
        </Flex>
      </Col>

      <Col
        sz="3"
        css={{
          "@mxlg": {
            width: "0",
          },
        }}
      >
        <Box>
          <Box
            css={{
              top: "116px",
              "@mxlg": {
                top: "0",
              },
            }}
          >
            <Flex direction="column" gap="2">
              <VehicleBookingCart
                currentActiveStep={activeStep}
                cartIsOpen={cartIsOpen}
                setCarIsOpen={setCartIsOpen}
                bookingInfo={bookingInfo}
                setNextStep={setNextStep}
                onRemoveVehicle={() => actions.setVehicle(null as any)}
                vehicleEditable
                vehicleBudget={vehicleBudget}
                vehicleQuery={queryData}
              />
              {!!returnFeeAnotherCity && (
                <Alert variant="warning">
                  <Icon as={SvgAlertTriangle} size="xl" />
                  <Text size="3" css={{}}>
                    Para devolução em outra loja, será cobrado uma taxa de{" "}
                    <Text fw="600" variant="warning-dark">
                      {asCurrency(returnFeeAnotherCity)}
                      {""}
                    </Text>
                    {"."}
                  </Text>
                </Alert>
              )}
            </Flex>
          </Box>
        </Box>
      </Col>
    </Row>
  );
}
