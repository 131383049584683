import { useCallback, useState } from "react";
import { CustomerEmployee, UserContext as UserContextEnum } from "~/application/types";
import { travelerService } from "~/application/usecases";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { HotelDetailsContainer } from "./HotelDetailsContainer";
import { useSelectGuests } from "./hooks/useSelectGuests";
import { useSelectRooms } from "./hooks/useSelectRooms";
import { BookingHotelSteps } from "./utils";
import { useBookingHotel } from "../../contexts/BookingHotelContext";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingHotel/HotelDetailsPage";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SNACKBAR_MESSAGES = {} as const;

export function HotelDetailsPage() {
  const { order } = useBookingHotel();

  const { user, contexts } = useUser();
  const [issuerId, setIssuerId] = useState(user.profiles.customer?.uuid);
  const [activeStep, setActiveStep] = useState(BookingHotelSteps.SELECT_ROOMS);
  const [isShowCart, setIsShowCart] = useState(false);

  const canSelectIssuer =
    user.context === UserContextEnum.Agency && contexts.customer && !order?.issuer.uuid;

  const onSelectIssuer = useCallback(({ uuid }: CustomerEmployee) => {
    setIssuerId(uuid);
  }, []);

  const selectGuestsState = useSelectGuests({
    enabled: activeStep === BookingHotelSteps.SELECT_GUESTS,
    setIsShowCart,
    issuerId,
  });

  const selectRoomsState = useSelectRooms({
    enabled: activeStep === BookingHotelSteps.SELECT_ROOMS,
    setIsShowCart,
    onGoNextStep: () => {
      setIsShowCart(false);
      setActiveStep(BookingHotelSteps.SELECT_GUESTS);
    },
  });

  const roomsState = { ...selectRoomsState, isShowCart, setIsShowCart };
  const guestsState = { ...selectGuestsState, isShowCart, setIsShowCart };

  const fetchTravelersByName = useCallback(
    async (name: string) => {
      return travelerService.find(contexts.customer, { name, isActive: "ativo" });
    },
    [contexts]
  );

  return (
    <HotelDetailsContainer
      activeStep={activeStep}
      onSetActiveStep={setActiveStep}
      selectRoomsState={roomsState}
      selectGuestsState={guestsState}
      onSelectIssuer={onSelectIssuer}
      canSelectIssuer={!!canSelectIssuer}
      fetchTravelers={fetchTravelersByName}
    />
  );
}
