import { FC, useCallback } from "react";
import {
  Order,
  OrderAirwayItem as OrderAirway,
  OrderAirwayItemType,
  OrderItems,
  OrderStatus,
} from "~/application/types";
import { DateUtils } from "~/application/utils";
import { DateFormats } from "~/application/utils/date-functions";
import { Alert } from "~/components/Alert";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgAlertTriangle, SvgCopy, SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { DataColItem, DataListItem } from "~/components/List";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H4, Label } from "~/components/Typography";
import { ManualEmissionButtons } from "~/core/modules/Order/pages/ManageOrderPage/views/OrderItems/components/ManualEmissionButtons";
import { getAirlineLogo } from "~/core/shared/utils";
import { OrderItemStatusTag } from "~/presentation/shared/components/OrderItemStatusTag";
import { Track } from "~/presentation/shared/components/Track";
import { TravelerListItem } from "~/presentation/shared/components/TravelerListItem";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { getAirlineUrl } from "~/presentation/shared/utils";
import { displayDate, toHour12 } from "~/utils/date.utils";
import { asCurrency } from "~/utils/mask.utils";

export type OrderAirwayItemProps = {
  data: OrderAirwayItemType;
  isLoading: boolean;
  order: Order;
  onCopyText: (value: string) => void;
  onIssueOrder: () => void;
  onCancelItem: ({
    orderItemId,
    orderItemType,
  }: {
    orderItemId: string;
    orderItemType: OrderItems;
  }) => void;
  onIssueAirway: (orderData: OrderAirway) => void;
};

export const OrderAirwayItem: FC<OrderAirwayItemProps> = ({
  data,
  order,
  onCopyText,
  onCancelItem,
  onIssueAirway,
  onIssueOrder,
}: OrderAirwayItemProps) => {
  const isMobile = useMobile();
  const renderCopyCredential = useCallback(
    (credential: string) => (
      <Tooltip content={<TooltipLabel>Copiar credencial</TooltipLabel>}>
        <IconButton onClick={() => onCopyText(credential)} size="md">
          <Icon as={SvgCopy} />
        </IconButton>
      </Tooltip>
    ),
    [onCopyText]
  );

  const renderCopyTracker = useCallback(
    (tracker: string) => (
      <Tooltip content={<TooltipLabel>Copiar localizador</TooltipLabel>}>
        <IconButton onClick={() => onCopyText(tracker)} size="md">
          <Icon as={SvgCopy} />
        </IconButton>
      </Tooltip>
    ),
    [onCopyText]
  );

  return (
    <Flex gap="2" direction="column">
      {data.flights.map((item, index) => {
        const showAlertUnflownAirway = item?.passengers?.some(
          (passenger) => passenger?.unflownAirway?.uuid
        );
        return (
          <Card key={item.uuid}>
            <Flex direction="column" as={CardBody} gap="6">
              {showAlertUnflownAirway && (
                <Alert variant="warning">
                  <Flex
                    align="center"
                    justify="center"
                    css={{
                      width: "100%",
                    }}
                    gap="4"
                  >
                    <Icon as={SvgAlertTriangle} />
                    <Text>Solicitação de remarcação de crédito</Text>
                  </Flex>
                </Alert>
              )}
              <Row gap="6">
                <Col sz="6">
                  <Label paragraph css={{ mb: "$2" }}>
                    Data
                  </Label>
                  <Text size={{ "@mxlg": "4" }} css={{ fw: "600" }}>
                    {DateUtils.format(item.expiresAt, DateFormats.SMALL_DATE_TIME)}
                  </Text>
                </Col>

                <Col
                  sz="6"
                  css={{
                    "@mxlg": {
                      ta: "end",
                    },
                  }}
                >
                  <Label paragraph css={{ mb: "$2" }}>
                    Status
                  </Label>
                  <Text css={{ fw: "600" }}>
                    <OrderItemStatusTag data={item.status} />
                  </Text>
                </Col>

              
              </Row>
              <Text css={{ fw: "600" }}>Reserva</Text>
              <Flex direction="column" gap="2">
                <Text variant="dark" size="2">
                  Localizador
                </Text>

                <Card
                  css={{
                    "@mxlg": {
                      border: "0",
                    },
                  }}
                >
                  <Flex
                    css={{
                      p: "$4",
                      "@mxlg": {
                        p: "0",
                      },
                    }}
                    gap="4"
                    align="center"
                  >
                    <CompanyLogo src={getAirlineUrl(item.segments[0].airline)} />

                    <Text
                      size={{ "@mxlg": "3" }}
                      variant="darkest"
                      css={{
                        fw: "600",
                        flexGrow: 1,
                        "@mxlg": {
                          ta: "end",
                        },
                      }}
                    >
                      {item.tracker ?? "---"}
                    </Text>

                    {item.tracker && renderCopyTracker(item.tracker)}
                  </Flex>
                </Card>
              </Flex>

              {item.passengers.map((traveler) => (
                <Flex direction="column" gap="3">
                  <TravelerListItem data={traveler} key={traveler.uuid} />

                  {traveler?.unflownAirway && (
                    <Flex direction="column" gap="2">
                      <Text fw="600">Banco de Bilhete</Text>
                      <DataListItem
                        css={{
                          cursor: "pointer",
                        }}
                        isActive
                      >
                        <DataColItem
                          data={
                            <CompanyLogo
                              size="lg"
                              src={getAirlineLogo(traveler?.unflownAirway.airline)}
                            />
                          }
                        />
                        <DataColItem
                          headerTitle="Data de emissão"
                          data={displayDate(
                            traveler?.unflownAirway.issueDate,
                            DateFormats.SMALL_DATE
                          )}
                        />
                        <DataColItem
                          headerTitle="Localizador"
                          data={traveler?.unflownAirway.tracker}
                        />
                        <DataColItem
                          headerTitle="Valor"
                          data={asCurrency(traveler?.unflownAirway.value)}
                        />
                      </DataListItem>
                    </Flex>
                  )}
                </Flex>
              ))}
              <Text css={{ fw: "600" }}>Vôos</Text>
              <Flex direction="column" gap="3">
                {item.segments.map((seg) => (
                  <Card
                    key={seg.flightNumber}
                    css={{
                      border: "0",
                    }}
                  >
                    <Flex
                      as={CardBody}
                      gap="6"
                      css={{
                        "@mxlg": {
                          p: 0,
                        },
                      }}
                    >
                      <Track
                        data-orientation="vertical"
                        css={{
                          "@mxlg": {
                            display: "none",
                          },
                        }}
                      />
                      <Card css={{ p: "$3 $4", flexGrow: 1 }}>
                        <Row align="center">
                          <Col
                            sz="auto"
                            css={{
                              "@mxlg": {
                                display: "none",
                              },
                            }}
                          >
                            <CompanyLogo
                              size="lg"
                              src={getAirlineUrl(seg.airline)}
                              alt={seg.airline}
                            />
                          </Col>

                          <Col sz="auto">
                            <Text variant="darkest" css={{ fw: "600" }}>
                              {seg.flightNumber}
                            </Text>
                          </Col>

                          {!isMobile ? (
                            <>
                              <Col>
                                <Text as="p" size={{ "@mxlg": "3" }} css={{ fw: "600", mb: "$3" }}>
                                  {seg.departureFlightInfo.airport}
                                </Text>
                                <Text size={{ "@initial": "3", "@mxlg": "3" }}>
                                  {DateUtils.format(
                                    seg.departureFlightInfo.dateTime,
                                    DateFormats.SMALL_DATE_TIME
                                  )}
                                </Text>
                              </Col>
                              <Col css={{ textAlign: "end" }}>
                                <Text size={{ "@mxlg": "2" }} as="p" css={{ fw: "600", mb: "$3" }}>
                                  {seg.arrivalFlightInfo.airport}
                                </Text>
                                <Text size="3">
                                  {DateUtils.format(
                                    seg.arrivalFlightInfo.dateTime,
                                    DateFormats.SMALL_DATE_TIME
                                  )}
                                </Text>
                              </Col>
                            </>
                          ) : (
                            <Flex css={{ mt: "$4" }}>
                              <Col>
                                <Text as="p" size={{ "@mxlg": "4" }} css={{ fw: "600", mb: "$3" }}>
                                  {seg.departureFlightInfo.airport}
                                </Text>
                                <Text size={{ "@initial": "3", "@mxlg": "2" }}>
                                  {DateUtils.format(
                                    seg.departureFlightInfo.dateTime,
                                    DateFormats.SMALL_DATE
                                  )}
                                </Text>
                                <Text size={{ "@initial": "3", "@mxlg": "2" }} variant="dark">
                                  {" "}
                                  {toHour12(seg.departureFlightInfo.dateTime, true)}
                                </Text>
                              </Col>

                              <Col css={{ textAlign: "end" }}>
                                <Text size={{ "@mxlg": "4" }} as="p" css={{ fw: "600", mb: "$3" }}>
                                  {seg.arrivalFlightInfo.airport}
                                </Text>
                                <Text size={{ "@initial": "3", "@mxlg": "2" }}>
                                  {DateUtils.format(
                                    seg.arrivalFlightInfo.dateTime,
                                    DateFormats.SMALL_DATE
                                  )}
                                </Text>
                                <Text variant="dark" size={{ "@initial": "3", "@mxlg": "2" }}>
                                  {" "}
                                  {toHour12(seg.arrivalFlightInfo.dateTime, true)}
                                </Text>
                              </Col>
                            </Flex>
                          )}
                        </Row>
                      </Card>
                    </Flex>
                  </Card>
                ))}
              </Flex>
              <Card
                css={{
                  "@mxlg": {
                    border: "0",
                  },
                }}
              >
                <CardBody
                  css={{
                    "@mxlg": {
                      p: 0,
                      overflow: "hidden",
                    },
                  }}
                >
                  <Row gap="6">
                    <Col sz="4">
                      <Text
                        as="p"
                        size={{ "@mxlg": "2" }}
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Valor do pedido
                      </Text>
                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }}>{asCurrency(item.value)}</H4>
                        <Tooltip
                          content={
                            <Flex direction="column" gap="2">
                              <TooltipLabel>
                                Taxa de embarque: {asCurrency(item.boardingTax)}
                              </TooltipLabel>
                              <TooltipLabel>Du: {asCurrency(item.du)}</TooltipLabel>
                              <TooltipLabel>Tarifa: {asCurrency(item.fare)}</TooltipLabel>
                              <TooltipLabel>Markup: {asCurrency(item.markup)}</TooltipLabel>
                            </Flex>
                          }
                        >
                          <IconButton
                            css={{
                              "@mxlg": {
                                display: "none",
                              },
                            }}
                            size="md"
                          >
                            <Icon as={SvgInfo} />
                          </IconButton>
                        </Tooltip>
                      </Flex>
                    </Col>

                    <Col sz="4">
                      <Text
                        as="p"
                        size={{ "@mxlg": "2" }}
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Valor do fornecedor
                      </Text>

                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }}>{asCurrency(item.providerValue)}</H4>
                        <Tooltip
                          content={<TooltipLabel>{asCurrency(item.providerValue)}</TooltipLabel>}
                        >
                          <IconButton
                            css={{
                              "@mxlg": {
                                display: "none",
                              },
                            }}
                            size="md"
                          >
                            <Icon as={SvgInfo} />
                          </IconButton>
                        </Tooltip>
                      </Flex>
                    </Col>

                    <Col sz="4">
                      <Text
                        as="p"
                        size={{ "@mxlg": "2" }}
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Classe tarifária
                      </Text>

                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }}>{item.familyFare}</H4>
                      </Flex>
                    </Col>

                    <Col sz="4">
                      <Text
                        as="p"
                        size={{ "@mxlg": "2" }}
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Valor antes do reajuste
                      </Text>
                      <Flex gap="2" align="center">
                        <H4 size={{ "@mxlg": "4" }} variant="dark">
                          {asCurrency(item.value)}
                        </H4>
                        <Tooltip content={<TooltipLabel>{asCurrency(item.value)}</TooltipLabel>}>
                          <IconButton
                            size="md"
                            css={{
                              "@mxlg": {
                                display: "none",
                              },
                            }}
                          >
                            <Icon as={SvgInfo} />
                          </IconButton>
                        </Tooltip>
                      </Flex>
                    </Col>

                    <Col sz="4">
                      <Text
                        as="p"
                        size={{ "@mxlg": "2" }}
                        variant="dark"
                        css={{ fw: "600", mb: "$3" }}
                      >
                        Nome do fornecedor
                      </Text>
                      <Text size={{ "@mxlg": "2" }}>{item.provider}</Text>
                    </Col>

                    <Col sz="4">
                      <Text
                        as="p"
                        size={{ "@mxlg": "2" }}
                        variant="dark"
                        css={{
                          fw: "600",
                          mb: "$3",
                          "@mxlg": {
                            mb: "$1",
                          },
                        }}
                      >
                        Credenciais
                      </Text>

                      <Flex gap="2" align="center">
                        <Text size={{ "@mxlg": "2" }}>{item.credential}</Text>

                        {renderCopyCredential(item.credential)}
                      </Flex>
                    </Col>

                    <Col sz="4">
                      <Text
                        as="p"
                        size={{ "@mxlg": "2" }}
                        variant="dark"
                        css={{
                          fw: "600",
                          mb: "$3",
                          "@mxlg": {
                            mb: "$1",
                          },
                        }}
                      >
                        Credenciais
                      </Text>

                      <Flex gap="2" align="center">
                        <Text size={{ "@mxlg": "2" }}>{item.credential}</Text>

                        {renderCopyCredential(item.credential)}
                      </Flex>
                    </Col>
                    {order.status === OrderStatus.PENDING_ISSUE && (
                      <ManualEmissionButtons
                        data={order}
                        item={item}
                        onCancelItem={onCancelItem}
                        onIssueItem={onIssueAirway}
                        itemType={OrderItems.AIRWAY}
                      />
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Flex>
          </Card>
        );
      })}
    </Flex>
  );
};

OrderAirwayItem.displayName = "OrderAirwayItem";
