import { FC, useCallback } from "react";
import { AirlineCode, Traveler, UnflownAirway } from "~/application/types";
import { formatNumber } from "~/application/utils/string-functions";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Flex } from "~/components/Flex";
import { FieldLabel } from "~/components/FormControl";
import { Col, Row } from "~/components/Grid";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { AsyncSelect } from "~/presentation/shared/components/AsyncSelect";
import { TravelersSectionUIProps } from "./types";
import { LazyList } from "~/components/LazyList";
import { DateFormats, displayDate, toDate } from "~/utils/date.utils";
import { DataColItem, DataListItem } from "~/components/List";
import { CompanyLogo } from "~/components/CompanyLogo";
import { getAirlineLogo } from "~/core/shared/utils";
import { asCurrency } from "~/utils/mask.utils";
import { RadioButton, Select } from "~/components/Input";
import { useQuery } from "@tanstack/react-query";
import { customerEmployeeService } from "~/application/usecases";
import { QueryKeys } from "~/application/constants";
import { AirwayBookingDispatchActionType } from "../../../hooks/useAirwayReducer/types";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Divider } from "~/components/Divider";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Icon } from "~/components/Icon";
import { SvgInfo } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";

export const TravelersSectionUI: FC<TravelersSectionUIProps> = ({
  data,
  travelers,
  selectedTravelers,
  travelersPlaceholder,
  isLoadingFetchTraveler,
  onCreateCustomerEmployee,
  onSelectUnflownsAirway,
  onSelectTraveler,
  onFetchTraveler,
  fetchTravelers,
  adultQuantity,
  bookingState,
}) => {
  const getUnflownsAirway = useCallback(
    (customerEmployeeId: string) =>
      useQuery(
        [QueryKeys.UNFLOWNS_AIRWAYS, customerEmployeeId, adultQuantity],
        async () =>
          await customerEmployeeService.findAllUnflownsAirways({
            customerEmployeeId,
            status: "open",
          }),
        {
          enabled: !!customerEmployeeId && adultQuantity < 2,
        }
      ),
    [adultQuantity]
  );

  const isMobile = useMobile();

  const SelectFormatedOption = (option: Traveler) => {
    return (
      <Flex direction="column">
        <Text>Nome: {option.fullName}</Text>
        <Text css={{ color: "#999999", mt: "$2" }}>CPF: {option.cpf}</Text>
      </Flex>
    );
  };

  const getTravelerLabel = useCallback(
    (item: Traveler) => `Nome: ${item.fullName} \n CPF: ${item.cpf}`,
    []
  );

  const getTravelerValue = useCallback((item: Traveler) => item.uuid, []);

  const UnflownsAirwaysItem = useCallback(
    ({
      item,
      traveler: newTraveler,
      type,
    }: {
      item: UnflownAirway;
      traveler: Traveler;
      type: "departure" | "arrival";
    }) => {
      const switchTravelType =
        type === "departure"
          ? AirwayBookingDispatchActionType.GO_UNFLOW
          : AirwayBookingDispatchActionType.RETURN_UNFLOW;

      const isGo = type === "departure";
      const isGoActive = bookingState.goFlightSelected?.unflownAirway?.some(
        (unflownAirway) =>
          unflownAirway?.unflown?.uuid === item.uuid &&
          unflownAirway.traveler?.uuid === newTraveler.uuid
      );
      const isReturnActive = bookingState.returnFlightSelected?.unflownAirway?.some(
        (unflownAirway) =>
          unflownAirway?.unflown?.uuid === item.uuid &&
          unflownAirway.traveler?.uuid === newTraveler.uuid
      );
      const isActive = isGo ? isGoActive : isReturnActive;
      return (
        <DataListItem
          css={{
            cursor: "pointer",
            p: isMobile ? "$2" : "$6",
          }}
          isActive={isActive}
          onClick={() => {
            if (isGoActive || isReturnActive) {
              return onSelectUnflownsAirway({
                item: undefined,
                traveler: newTraveler,
                type: switchTravelType,
              });
            }
            onSelectUnflownsAirway({ item, traveler: newTraveler, type: switchTravelType });
          }}
        >
          <DataColItem data={<CompanyLogo size="lg" src={getAirlineLogo(item.airline)} />} />
          <DataColItem
            headerTitleSize={isMobile ? "1" : "2"}
            boxCss={{
              fontSize: isMobile ? "8px" : "small",
            }}
            headerTitle="Data de emissão"
            data={displayDate(item.issueDate, DateFormats.SMALL_DATE)}
          />
          <DataColItem
            headerTitleSize={isMobile ? "1" : "2"}
            boxCss={{
              fontSize: isMobile ? "8px" : "small",
            }}
            headerTitle="Localizador"
            data={item.tracker}
          />
          <DataColItem
            headerTitleSize={isMobile ? "1" : "2"}
            boxCss={{
              fontSize: isMobile ? "8px" : "small",
            }}
            headerTitle="Valor"
            data={
              <Flex
                gap="1"
                align="center"
                css={{
                  width: "100%",
                }}
              >
                <Text size={isMobile ? "1" : "4"}>{asCurrency(item.value)}</Text>
                <Tooltip
                  content={
                    <Flex
                      css={{
                        maxWidth: "300px",
                      }}
                    >
                      <TooltipLabel>
                        O valor apresentado é uma estimativa. Este valor pode sofrer alterações caso
                        algum trecho do bilhete já tenha sido utilizado.
                      </TooltipLabel>
                    </Flex>
                  }
                >
                  <IconButton
                    size="md"
                    css={{
                      height: "fit-content",
                    }}
                  >
                    <Icon as={SvgInfo} variant="primary" />
                  </IconButton>
                </Tooltip>
              </Flex>
            }
          />
          <DataColItem
            css={{
              flex: "0",
            }}
            boxCss={{
              mt: "10px",
            }}
            data={<RadioButton data-checked={isActive} />}
          />
        </DataListItem>
      );
    },
    [onSelectUnflownsAirway, bookingState, isMobile]
  );

  return (
    <Box>
      <Flex
        align="center"
        direction={{ "@mxlg": "column" }}
        css={{
          mb: "$6",
          "@mxlg": {
            width: "100%",
          },
        }}
      >
        <Col>
          <H4>Adicione passageiros</H4>
        </Col>

        <Button
          css={{
            "@mxlg": {
              mt: "$6",
              width: "90%",
            },
          }}
          onClick={onCreateCustomerEmployee}
          variant="secondary"
        >
          <Text>Novo funcionário</Text>
        </Button>
      </Flex>

      <Flex direction="column" gap="8">
        {travelersPlaceholder.map((placeholder, index) => {
          const controlLabel = `${placeholder.type === "ADT" ? "Adulto" : "Criança"} ${formatNumber(
            1 + placeholder.index,
            2
          )}`;

          const defaultValue = selectedTravelers[index] || null;

          const { data: unflownsAirwaysData, isLoading } = getUnflownsAirway(defaultValue?.uuid);

          const unflownGoFlight = unflownsAirwaysData?.filter((unflown) => {
            return (
              unflown.airline === bookingState.goFlightSelected?.flight.airline &&
              (bookingState?.returnFlightSelected?.unflownAirway
                ? bookingState?.returnFlightSelected?.unflownAirway?.some(
                    (returnUnflow) => returnUnflow?.unflown?.uuid !== unflown.uuid
                  )
                : true)
            );
          });

          const unflownReturnFlight = unflownsAirwaysData?.filter(
            (unflown) =>
              unflown.airline === bookingState.returnFlightSelected?.flight.airline &&
              (bookingState?.goFlightSelected?.unflownAirway
                ? bookingState?.goFlightSelected?.unflownAirway?.some(
                    (goUnflow) => goUnflow?.unflown?.uuid !== unflown.uuid
                  )
                : true)
          );

          const canshowReturnUnflownAirway =
            !!bookingState.returnFlightSelected &&
            !!unflownReturnFlight?.length &&
            !bookingState.returnFlightSelected.isCombined;

          return (
            <Row gap="6" key={`traveler_${index}`}>
              <Col sz="12">
                <FieldLabel>{controlLabel}</FieldLabel>
                <Select
                  options={travelers}
                  placeholder="Digite o passageiro"
                  isLoading={isLoadingFetchTraveler}
                  formatOptionLabel={(option) => SelectFormatedOption(option)}
                  onInputChange={onFetchTraveler}
                  getOptionValue={getTravelerValue}
                  getOptionLabel={getTravelerLabel}
                  onChange={(value) => onSelectTraveler(value, index)}
                />
              </Col>
              {defaultValue && (
                <Flex gap="2" css={{ width: "100%" }} direction={isMobile ? "column" : "row"}>
                  {!!unflownGoFlight?.length && (
                    <Flex direction="column" css={{ width: "100%", overflow: "auto" }} gap="2">
                      <Flex align="center">
                        <Text fw="800">Banco de bilhetes ida</Text>
                        <Tooltip
                          content={
                            <Flex
                              css={{
                                maxWidth: "300px",
                              }}
                            >
                              <TooltipLabel>
                                Selecione o bilhete listado abaixo para solicitar um orçamento de
                                remarcação.
                              </TooltipLabel>
                            </Flex>
                          }
                        >
                          <IconButton size="md">
                            <Icon as={SvgInfo} variant="primary" />
                          </IconButton>
                        </Tooltip>
                      </Flex>
                      <Flex
                        css={{
                          width: "100%",
                          overflow: "auto",
                          maxHeight: "200px",
                        }}
                      >
                        <LazyList
                          items={unflownGoFlight}
                          isLoading={isLoading}
                          render={(item) =>
                            UnflownsAirwaysItem({ item, traveler: defaultValue, type: "departure" })
                          }
                          css={{
                            width: "100%",
                          }}
                        />
                      </Flex>
                    </Flex>
                  )}
                  {canshowReturnUnflownAirway && !!unflownGoFlight?.length && (
                    <Divider
                      css={{
                        height: !isMobile ? "100%" : "",
                        border: "1px solid $neutrals-base",
                      }}
                    />
                  )}
                  {canshowReturnUnflownAirway && (
                    <Flex direction="column" css={{ width: "100%" }} gap="2">
                      <Flex align="center">
                        <Text fw="800">Banco de bilhetes volta</Text>
                        <Tooltip
                          content={
                            <Flex
                              css={{
                                maxWidth: "300px",
                              }}
                            >
                              <TooltipLabel>
                                Selecione o bilhete listado abaixo para solicitar um orçamento de
                                remarcação.
                              </TooltipLabel>
                            </Flex>
                          }
                        >
                          <IconButton size="md">
                            <Icon as={SvgInfo} variant="primary" />
                          </IconButton>
                        </Tooltip>
                      </Flex>
                      <Flex css={{ width: "100%", overflow: "auto", maxHeight: "200px" }}>
                        <LazyList
                          items={unflownReturnFlight}
                          isLoading={isLoading}
                          render={(item) =>
                            UnflownsAirwaysItem({
                              item,
                              traveler: defaultValue,
                              type: "arrival",
                            })
                          }
                          css={{
                            width: "100%",
                          }}
                        />
                      </Flex>
                    </Flex>
                  )}
                </Flex>
              )}
            </Row>
          );
        })}
      </Flex>
    </Box>
  );
};
