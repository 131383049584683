import {
  AirlineCode,
  AirwayWithCheapestFlightsItemType,
  FlightOriginType,
  Order,
  OrderAdvanceItem,
  OrderAdvanceItemType,
  OrderAirwayItem,
  OrderAirwayItemType,
  OrderApprover,
  OrderHotelItem,
  OrderHotelItemType,
  OrderItems,
  OrderRoadItem,
  OrderRoadItemType,
  OrderVehicleItem,
  OrderVehicleItemType,
  ViolatedPolicyOrder,
} from "~/application/types";
import { DateUtils } from "~/application/utils";
import { toDate } from "~/utils/date.utils";
import {
  AirwayWithCheapestFlightsItemTypeDTO,
  OrderAdvanceItemTypeDTO,
  OrderAirwayItemTypeDTO,
  OrderDTO,
  OrderHotelItemTypeDTO,
  OrderRoadItemTypeDTO,
  OrderVehicleItemTypeDTO,
  ViolatedPolicyOrderDTO,
} from "../dtos";
import { mapFlightOptionDTO } from "./mapFlightOptionDTO";
import { mapFlightSegmentDTO } from "./mapFlightSegmentDTO";
import { mapReasonTripDTO } from "./mapReasonTripDTO";
import { mapTravelerDTO } from "./mapTravelerDTO";
import { mapVehicleDetailDTO } from "./mapVehicleDTO";
import { mapViolatedPoliciesDTO } from "./mapViolatedPoliciesDTO";
import { mapUnflownAirway } from "./mapUnflownAirway";
import { mapHotelRuleDTO } from "./mapHotelRuleDTO";
import slugify from "slugify";
import { mapAgreementDTO } from "./mapAgreementDTO";
import { mapVehicleCoverageDTO } from "./mapVehicleCoverageDTO";

export function mapOrderAirwayItemTypeDTO(
  data: OrderAirwayItemTypeDTO
): OrderAirwayItemType | null {
  if (!data) return null;

  return {
    flights: data.flights.map(
      (item): OrderAirwayItem => ({
        uuid: item.uuid,
        value: item.value,
        codeshare: item.codeshare,
        credential: item.credential,
        flightType: item.flight_type,
        isInternational: item.is_international,
        baggages: item?.baggages,
        arrivalAirportIata: item?.arrival_airport_iata,
        departureAirportIata: item?.departure_airport_iata,

        passengers: item.passengers.map((traveler) => ({
          ...mapTravelerDTO(traveler),
          amountLuggage: traveler.amount_luggage,
          ticket: traveler.ticket,
          unflownAirway: traveler.unflown_ticket
            ? mapUnflownAirway(traveler.unflown_ticket)
            : undefined,
        })),
        availableSeatMap: item.available_seat_map,
        isTheCheapestFlight: item.is_the_cheapest_flight,
        servicesIncluded: item.services_included
          ? {
            baggage: item.services_included.baggage,
            checkedBaggage: item.services_included.checked_baggage,
            comfortSeat: item.services_included.comfort_seat,
            refund: item.services_included.refund,
            refundDescription: item.services_included.refund_description,
            rescheduling: item.services_included.rescheduling,
            seatAssignment: item.services_included.seat_assignment,
          }
          : undefined,
        provider: item.provider,
        segments: item.segments.map((seg) => ({
          airline: slugify(seg.airline.toString()) as AirlineCode,
          type: seg.type,
          arrivalFlightInfo: {
            shortAirport: seg.arrivalInfo.short_airport,
            airportIata: seg.arrivalInfo.airport_iata,
            airport: seg.arrivalInfo.airport,
            dateTime: DateUtils.toDate(seg.arrivalInfo.dateTime),
          },
          departureFlightInfo: {
            shortAirport: seg.departureInfo.short_airport,
            airportIata: seg.departureInfo.airport_iata,
            airport: seg.departureInfo.airport,
            dateTime: DateUtils.toDate(seg.departureInfo.dateTime),
          },
          flightNumber: seg.flightNumber,
        })),
        familyFare: item.family_fare,
        status: item.status,
        departureAirport: item.departure_airport,
        arrivalAirport: item.arrival_airport,
        createdAt: DateUtils.toDate(item.created_at),
        reasonRejected: item.reason_rejected,
        expiresAt: toDate(item.expires_at),
        tracker: item.tracker ?? undefined,
        markdown: item.markdown,
        du: item.du,
        boardingTax: item.boarding_tax,
        fee: item.fee,
        providerValue: item.provider_value,
        markup: item.markup,
        fare: item.fare,
        changeTax: parseFloat(item.change_tax),
        violatedPolicies: mapViolatedPoliciesDTO(item.violated_policies),
        brokenPolicyJustification: item.broken_policy_justification,
      })
    ),
    value: data.value,
    fees: {
      fee: data.fees?.fee || 0,
      boardingTax: data.fees?.boarding_tax,
      seatValue: data.fees?.seat_value,
    },
  };
}

export function mapAirwayWithCheapestFlightsItemDTO(
  data: AirwayWithCheapestFlightsItemTypeDTO
): AirwayWithCheapestFlightsItemType {
  const { airway_order, cheapest_flights } = data;
  return {
    airwayOrder: {
      id: airway_order.id,
      uuid: airway_order.uuid,
      airline: airway_order.airline,
      codeshare: airway_order.codeshare,
      departureAirport: airway_order.departure_airport,
      arrivalAirport: airway_order.arrival_airport,
      departureDate: toDate(airway_order.departure_date),
      arrivalDate: toDate(airway_order.arrival_date),
      baggages: airway_order.baggages,
      servicesIncluded: airway_order.services_included
        ? {
          baggage: airway_order.services_included.baggage,
          checkedBaggage: airway_order.services_included.checked_baggage,
          comfortSeat: airway_order.services_included.comfort_seat,
          refund: airway_order.services_included.refund,
          refundDescription: airway_order.services_included.refund_description,
          rescheduling: airway_order.services_included.rescheduling,
          seatAssignment: airway_order.services_included.seat_assignment,
        }
        : undefined,
      segments: airway_order.segments.map?.((segment) => ({
        airline: airway_order.airline,
        flightNumber: segment.flightNumber,
        departureFlightInfo: {
          shortAirport: segment.departureInfo.short_airport,
          airportIata: segment.departureInfo.airport_iata,
          airport: segment.departureInfo.airport,
          dateTime: toDate(segment.departureInfo.dateTime),
        },
        arrivalFlightInfo: {
          shortAirport: segment.arrivalInfo.short_airport,
          airportIata: segment.arrivalInfo.airport_iata,
          airport: segment.arrivalInfo.airport,
          dateTime: toDate(segment.arrivalInfo.dateTime),
        },
      })),
      availableSeatMap: airway_order.avaliabel_seat_map,
      isTheCheapestFlight: airway_order.is_the_cheapest_flight,
      familyFare: airway_order.family_fare,
      flightNumber: airway_order.flight_number,
      flightType: airway_order.flight_type,
      value: parseFloat(airway_order.value),
      fare: airway_order.fare,
      boardingTax: airway_order.boarding_tax,
      fee: airway_order.fee,
      tracker: airway_order.tracker,
      expiresAt: toDate(airway_order.expires_at),
      ticket: airway_order.ticket,
      passengers: airway_order.passengers,
      justification: airway_order.justification,
      reasonRejected: airway_order.reason_rejected,
      isRejected: Boolean(airway_order.rejected),
      violatedPolicies: mapViolatedPoliciesDTO(airway_order.violated_policies),
    },
    cheapestFlights: cheapest_flights
      ? cheapest_flights.map((item) => {
        const hasDiscount = item.discount && !Array.isArray(item.discount);

        return {
          id: item.id,
          airline: item.airline,
          scales: item.scales,
          hash: item.hash,
          baggages: item.baggages,
          flightOrigin: item.flightOrigin.toLowerCase() as FlightOriginType,
          segments: item.segments.map(mapFlightSegmentDTO),
          minimumPrice: item.minimumPrice,
          discount: hasDiscount && item.discount,
          options: item.options.map(mapFlightOptionDTO),
          violatedPolicies: mapViolatedPoliciesDTO(item.violated_policies),
        };
      })
      : null,
  };
}

export function mapOrderRoadItemTypeDTO(data: OrderRoadItemTypeDTO): OrderRoadItemType | null {
  if (!data) return null;

  return {
    travels: data.travels.map(
      (item): OrderRoadItem => ({
        uuid: item.uuid,
        arrival: item.arrival,
        arrivalDate: DateUtils.toDate(item.arrival_date),
        companyImage: item.company_image,
        companyName: item.company_name,
        createdAt: DateUtils.toDate(item.created_at),
        credential: item.credential,
        departure: item.departure,
        departureDate: DateUtils.toDate(item.departure_date),
        provider: item.provider,
        seatClass: item.seatClass,
        duration: item.duration,
        status: item.status,
        fee: item.fee,
        originalValue: item.original_value,
        value: item.value,
        fare: item.fare,
        isTheCheapestRoad: item.is_the_cheapest_road,
        tracker: item.tracker ?? undefined,
        travelers: item.travelers.map((traveler) => ({
          ...mapTravelerDTO(traveler),
          seats: traveler.seats.map((seat) => ({
            seatType: seat.seat_type,
            isConnection: seat.is_connection,
            seatNumber: seat.seat_number,
            ticket: seat.ticket,
            bpe: seat.bpe,
          })),
        })),
        connection: item.connection
          ? {
            name: item.connection?.name,
            seatClass: item.connection?.seat_class,
            departureDate: toDate(item.connection?.departure_date),
            arrivalDate: toDate(item.connection?.arrival_date),
            availableSeats: item.connection?.available_seats,
            bpe: item.connection?.bpe,
            companyName: item.connection?.company_name,
            companyUrl: item.connection?.company_url,
          }
          : undefined,
        reasonRejected: item.reason_rejected,
        bpe: item.bpe_ticket,
        ticket: item.ticket,
        markup: item.markup,
        providerFees: item.provider_fees,
        providerUnitFee: item.provider_unit_fee,
        cancellationTax: item.cancellation_tax,
        providerIdentification: item.provider_identification,
        violatedPolicies: mapViolatedPoliciesDTO(item.violated_policies),
        arrivalCity: item.arrival_city,
        departureCity: item.departure_city,
        brokenPolicyJustification: item.broken_policy_justification,
      })
    ),
    value: data.value,
    fees: data.fees,
  };
}

export function mapOrderVehicleItemTypeDTO(
  data: OrderVehicleItemTypeDTO
): OrderVehicleItemType | null {
  if (!data) return null;

  return {
    vehicles: data.vehicles.map(
      (item): OrderVehicleItem => ({
        arrival: item.arrival,
        arrivalCity: item.arrival_city,
        arrivalDate: DateUtils.toDate(item.arrival_date),
        conductor: mapTravelerDTO(item.conductor),
        createdAt: DateUtils.toDate(item.created_at),
        credential: item.credential,
        departure: item.departure,
        departureDate: DateUtils.toDate(item.departure_date),
        provider: item.provider,
        status: item.status,
        uuid: item.uuid,
        vehicleCompanyImage: item.vehicle_company_image,
        vehicleDescription: item.vehicle_description,
        vehicleGroup: item.vehicle_group,
        vehicleImage: item.vehicle_image,
        tracker: item.tracker ?? undefined,
        vehicleDoorsNumbers: item.vehicle_doors_numbers,
        vehicleAirConditioner: item.vehicle_air_conditioner,
        vehicleTransmissionType: item.vehicle_transmission_type,
        reasonRejected: item.reason_rejected,
        detail: mapVehicleDetailDTO(item?.detail),
        violatedPolicies: mapViolatedPoliciesDTO(item.violated_policies),
        returnFeeAnotherCity: item.return_fee_another_city,
        coverage: item.coverage ? mapVehicleCoverageDTO(item.coverage) : undefined,
        brokenPolicyJustification: item.broken_policy_justification,
      })
    ),
    value: data.value,
    fees: data.fees,
  };
}

export function mapOrderHotelItemTypeDTO(data: OrderHotelItemTypeDTO): OrderHotelItemType | null {
  if (!data) return null;

  return {
    rooms: (data.rooms || [])?.map(
      (item): OrderHotelItem => ({
        accommodation: item.accommodation,
        imageLink: item.image_link,
        checkIn: DateUtils.toDate(item.checkin_date),
        credentialDescription: item.credential_description,
        checkOut: DateUtils.toDate(item.checkout_date),
        cityName: item.city_name,
        createdAt: DateUtils.toDate(item.created_at),
        credential: item.credential,
        obs: item.obs,
        guests: item.guests.map((t) => ({
          ...mapTravelerDTO(t),
          age: t.age,
          passengerType: t.passenger_type,
        })),
        hotel: item.hotel,
        provider: item.provider,
        status: item.status,
        uuid: item.uuid,
        roomDescription: item.room_description,
        roomRegimen: item.room_regimen,
        value: item.value,
        fee: item.fee,
        markup: item.markup,
        agreement: item?.agreement ? mapAgreementDTO(item?.agreement) : undefined,
        approvedValue: item.approved_value,
        tracker: item.tracker ?? undefined,
        reasonRejected: item.reason_rejected,
        isOffline: item.is_offline,
        stars: item.stars,
        address: item.address,
        obsOffline: {
          amenities: item.obs_offline?.amenities,
          city: item.obs_offline?.city,
          suggestion: item.obs_offline?.suggestion,
          obs: item.obs_offline?.obs,
          regimen: item.obs_offline?.regimen,
        },
        amenities: item.amenities,
        options: item?.options?.map((option) => ({
          uuid: option.uuid,
          description: option.description,
          address: option.address,
          phone: option.phone,
          email: option.email,
          roomType: option.room_type,
          regimen: option.regimen,
          providerValue: parseFloat(option.provider_value),
          customerValue: parseFloat(option.customer_value),
          hotelFee: parseFloat(option.hotel_fee),
          hotelMarkup: parseFloat(option.hotel_markup),
          paymentPix: option.payment_pix,
          paymentCreditCard: option.payment_credit_card,
          obsIssuer: option.obs_issuer,
          obsAgency: option.obs_agency,
          status: option.status,
          amenities: option.amenities?.map((amenity) => ({
            uuid: amenity.uuid,
            name: amenity.name,
          })),
          paymentMethod: {
            description: option.payment_method?.description,
            uuid: option.payment_method?.uuid,
          },
          otherTaxes: parseFloat(option.other_taxes || ""),
          note: option.note,
          checkIn: toDate(option?.check_in as string),
          checkOut: toDate(option?.check_out as string),
          whatsapp: option.whatsapp,
        })),
        violatedPolicies: mapViolatedPoliciesDTO(item.violated_policies),
        brokenPolicyJustification: item.broken_policy_justification,
        bookingRules: item.booking_rules?.map(mapHotelRuleDTO),
      })
    ),
    value: data.value,
    fees: data.fees,
  };
}

export function mapOrderAdvanceItemTypeDTO(
  data: OrderAdvanceItemTypeDTO
): OrderAdvanceItemType | null {
  if (!data) return null;

  return {
    advances: data.advances.map(
      (item): OrderAdvanceItem => ({
        uuid: item.uuid,
        createdAt: DateUtils.toDate(item.created_at),
        description: item.description,
        traveler: mapTravelerDTO(item.traveler),
        value: parseFloat(item.value) ?? 0,
      })
    ),
    value: data.value,
    fees: data.fees,
  };
}

export const mapOrderViolatedPolicies = (
  data?: ViolatedPolicyOrderDTO[] | null
): ViolatedPolicyOrder[] | undefined =>
  data?.map((policy) => ({ message: policy.message, value: policy.policy_value }));

export function mapOrderDTO(data: OrderDTO): Order {
  return {
    uuid: data.uuid,
    issuer: data.issuer,
    costCenter: data.cost_center,
    reasonTrip: data.reason_trip ? mapReasonTripDTO(data.reason_trip) : undefined,
    orderNumber: data.order_number,
    totalValue: data.total_value,
    totalFees: data.total_fees,
    expirationDate: DateUtils.toDate(data.expires_at),
    isExpired: data.is_expired,
    status: data.status,
    observation: data.obs,
    expenseOnly: data.expense_only,
    travelers: data.travelers?.map(mapTravelerDTO),
    itemsIncluded: (Array.isArray(data.items_included)
      ? data.items_included
      : Object.keys(data.items_included)) as OrderItems[],
    items: {
      [OrderItems.AIRWAY]: mapOrderAirwayItemTypeDTO(data.items_included.airway),
      [OrderItems.ROAD]: mapOrderRoadItemTypeDTO(data.items_included.road),
      [OrderItems.VEHICLE]: mapOrderVehicleItemTypeDTO(data.items_included.vehicle),
      [OrderItems.HOTEL]: mapOrderHotelItemTypeDTO(data.items_included.hotel),
      [OrderItems.ADVANCE]: mapOrderAdvanceItemTypeDTO(data.items_included.advance),
      [OrderItems.HOTEL_OFFLINE]: mapOrderHotelItemTypeDTO(data.items_included.offline_hotel),
    },
    consultant:
      !data.consultant || Array.isArray(data.consultant)
        ? undefined
        : {
          uuid: data.consultant.uuid,
          fullName: data.consultant.full_name,
        },
    approvalModel: {
      approvalType: data.approval_model?.approval_type,
      approvalRequests: {
        orderId: data.approval_model?.approval_request?.order_uuid,
        branchName: data.approval_model?.approval_request?.branch_name,
        branchId: data.approval_model?.approval_request?.branch_uuid,
        advanceOrderId: data.approval_model?.approval_request?.advance_order_uuid,
        approvalModelId: data.approval_model?.approval_request?.approval_model_uuid,
        approved: data.approval_model?.approval_request?.approved,
      },
      approvers: (data.approval_model?.approvers || []).map(
        (approver): OrderApprover => ({
          uuid: approver?.uuid,
          name: approver?.name,
          email: approver?.email,
          approvalStatus: approver?.approval_status,
          isSelfApprover: approver?.self_approver,
          level: approver?.level,
        })
      ),
    },
    customer: {
      uuid: data.customer.uuid,
      name: data.customer.name,
      agencyId: data.customer.agency_uuid,
    },
    cancellationTax: {
      road: data.cancellation_tax?.road,
    },
    createdAt: DateUtils.toDate(data.created_at),
    issuedAt: data.issued_at ? DateUtils.toDate(data.issued_at) : undefined,
    linkVoucher: data.link_voucher,
    project: data.project,
    phase: data.phase,
    hasNotifiedExpiration: data.has_notified_expiration,
    orderViolatedPolicies: {
      advance: mapOrderViolatedPolicies(data?.order_violated_policies?.advance),
      airway: mapOrderViolatedPolicies(data?.order_violated_policies?.airway),
      hotel: mapOrderViolatedPolicies(data.order_violated_policies?.hotel),
      road: mapOrderViolatedPolicies(data?.order_violated_policies?.road),
      vehicle: mapOrderViolatedPolicies(data?.order_violated_policies?.vehicle),
      offline_hotel: mapOrderViolatedPolicies(data?.order_violated_policies?.offline_hotel),
    },
  };
}
