import { FC, Fragment } from "react";
import { formatNumber } from "~/application/utils/string-functions";
import { Box } from "~/components/Box";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { H3, Label } from "~/components/Typography";
import { FlightReadListItem } from "~/presentation/shared/components/FlightReadListItem";
import { TravelerListItem } from "~/presentation/shared/components/TravelerListItem";
import { ConfirmationUIProps } from "./types";
import { DataColItem, DataListItem } from "~/components/List";
import { CompanyLogo } from "~/components/CompanyLogo";
import { getAirlineLogo } from "~/core/shared/utils";
import { asCurrency } from "~/utils/mask.utils";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { ItemBrokenPolicyJustication } from "~/presentation/shared/components/BrokenPolicyJusticationItem/ItemBrokenPolicyJusticationItem";

export const ConfirmationUI: FC<ConfirmationUIProps> = ({ bookingState, onOpenDetails }) => {
  const { goFlightSelected, returnFlightSelected, travelers, stretch } = bookingState;

  return (
    <Fragment>
      <Box css={{ mb: "$6" }}>
        <H3
          css={{
            "@mxlg": {
              textAlign: "center",
              fw: "bold",
              fontSize: "$lg",
            },
          }}
        >
          Confirme as informações de sua reserva
        </H3>
      </Box>

      <Flex direction="column" gap="4">
        {goFlightSelected && (
          <>
            <Text size="5" css={{ fw: 600, mt: "$6" }}>
              Ida
            </Text>

            <FlightReadListItem data={goFlightSelected.flight} onOpenDetails={onOpenDetails} />
            {!!goFlightSelected?.flight?.violatedPolicies?.length && (
              <ItemBrokenPolicyJustication
                itemId={`${goFlightSelected.flight.id}-${goFlightSelected.flightOption.id}`}
              />
            )}
          </>
        )}
        {stretch.length > 1 &&
          stretch?.map((stre, index) => (
            <Flex
              direction="column"
              gap="2"
              key={`${stre.flight.id}-${stre.flightOption.id}-${index + 1}`}
            >
              <Text size="5" css={{ fw: 600, mt: "$6" }}>
                {`Trecho ${index + 1}`}
              </Text>

              <FlightReadListItem data={stre.flight} onOpenDetails={onOpenDetails} />
              {!!stre?.flight?.violatedPolicies?.length && (
                <ItemBrokenPolicyJustication
                  itemId={`${stre.flight.id}-${stre.flightOption.id}-${index + 1}`}
                />
              )}
            </Flex>
          ))}

        {returnFlightSelected && (
          <>
            <Text size="5" css={{ fw: 600 }}>
              Volta
            </Text>

            <FlightReadListItem data={returnFlightSelected.flight} onOpenDetails={onOpenDetails} />
            {!!returnFlightSelected?.flight?.violatedPolicies?.length && (
              <ItemBrokenPolicyJustication
                itemId={`${returnFlightSelected.flight.id}-${returnFlightSelected.flightOption.id}`}
              />
            )}
          </>
        )}
      </Flex>

      <Flex direction="column" gap="6" css={{ mt: "$6" }}>
        <Text size="5" css={{ fw: 600 }}>
          Passageiros
        </Text>

        {travelers.map(({ type, index, traveler }) => (
          <Flex direction="column" gap="2" key={`traveler-${index}-${traveler.uuid}`}>
            <Label>
              {type === "ADT" ? "Adulto" : "Criança"} {formatNumber(1 + index, 2)}{" "}
            </Label>
            <TravelerListItem key={traveler.uuid} data={traveler} />
            {(!!returnFlightSelected?.unflownAirway?.length ||
              !!goFlightSelected?.unflownAirway?.length) && (
              <Flex gap="2" direction="column">
                <Text size="5" css={{ fw: 600 }}>
                  Banco de bilhete
                </Text>

                {[
                  ...(goFlightSelected?.unflownAirway || []),
                  ...(returnFlightSelected?.unflownAirway || []),
                ]
                  .filter((unflown) => unflown.traveler.uuid === traveler.uuid)
                  ?.map(({ unflown: unflownAirway }) => (
                    <DataListItem
                      css={{
                        cursor: "pointer",
                      }}
                      isActive
                    >
                      <DataColItem
                        data={
                          <CompanyLogo
                            size="lg"
                            src={getAirlineLogo(unflownAirway?.airline || "")}
                          />
                        }
                      />
                      <DataColItem
                        headerTitle="Data de emissão"
                        data={displayDate(unflownAirway?.issueDate as Date, DateFormats.SMALL_DATE)}
                      />
                      <DataColItem headerTitle="Localizador" data={unflownAirway?.tracker} />
                      <DataColItem
                        headerTitle="Valor"
                        data={asCurrency(unflownAirway?.value || 0)}
                      />
                    </DataListItem>
                  ))}
              </Flex>
            )}
          </Flex>
        ))}
      </Flex>
    </Fragment>
  );
};

ConfirmationUI.displayName = "ConfirmationUI";
