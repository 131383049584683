import { api } from "~/infrastructure/api";
import { ICustomerDashboardBIService } from "./ICustomerDashboardService";
import {
  mapByCostCenters,
  mapByIssuer,
  mapByLeadTime,
  mapLastPurchases,
  mapPercentageByItem,
  mapRouteAnalysis,
  mapTopRoutes,
  mapTotalSpent,
} from "~/infrastructure/api/mappers/mapCustomerBiDTO";
import queryString from "query-string";
import {
  CostCenterData,
  IssuerData,
  Purchase,
  ByPercentagem,
  ItemData,
} from "~/application/types/entities/CustomerBI.type";

export type CustomerBIResponse =
  | CostCenterData[]
  | IssuerData[]
  | Purchase[]
  | ByPercentagem[]
  | ItemData[];

export type Filter = { startDate: string; endDate: string; costCenters: string[] };
export type CustomerDashboardProps = {
  customerId: string;
  query: CustomerBiQuery;
  filter: Filter;
};

export enum CustomerBiQuery {
  byCostCenter = "by_cost_center",
  byIssuer = "by_issuer",
  byItem = "by_item",
  byLeadTIme = "lead_time",
  lastPurchases = "last_purchases",
  topRoutes = "top_routes",
  routeAnalysis = "route_analysis",
  percentageByItem = "percentage_by_item",
  totalSpent = "total_spent",
}

export enum ItemQuery {
  AIRWAY = "airway",
  ROAD = "road",
  VEHICLE = "vehicle",
  HOTEL = "hotel",
}


export class CustomerDashboardBIService implements ICustomerDashboardBIService {
  async findBi({ customerId, query, filter }: CustomerDashboardProps): Promise<CustomerBIResponse> {
    const url = queryString.stringifyUrl(
      {
        url: `customers/${customerId}/bi`,
        query: {
          [query]: true,
          start_date: filter.startDate,
          end_date: filter.endDate,
          cost_centers_uuid: filter.costCenters,
        },
      },
      {
        arrayFormat: "bracket",
        skipNull: true,
        skipEmptyString: true,
      }
    );

    const mapData = {
      [CustomerBiQuery.byCostCenter]: mapByCostCenters,
      [CustomerBiQuery.byIssuer]: mapByIssuer,
      [CustomerBiQuery.lastPurchases]: mapLastPurchases,
      [CustomerBiQuery.percentageByItem]: mapPercentageByItem,
      [CustomerBiQuery.byItem]: mapPercentageByItem,
      [CustomerBiQuery.totalSpent]: mapTotalSpent,
      [CustomerBiQuery.byLeadTIme]: mapByLeadTime,
      [CustomerBiQuery.topRoutes]: mapTopRoutes,
      [CustomerBiQuery.routeAnalysis]: mapRouteAnalysis,
    };

    return (await api.get(url).then(({ data }) => mapData[query](data.data))) as CustomerBIResponse;
  }

  async findItem({
    customerId,
    item,
    byItem,
    filter,
  }: {
    customerId: string;
    item: string;
    filter: Filter;
    byItem?: boolean;
  }): Promise<CustomerBIResponse> {
    const url = queryString.stringifyUrl(
      {
        url: `customers/${customerId}/bi`,
        query: {
          [item]: true,
          by_item: byItem,
          start_date: filter.startDate,
          end_date: filter.endDate,
          cost_centers_uuid: filter.costCenters,
        },
      },
      {
        arrayFormat: "bracket",
        skipNull: true,
        skipEmptyString: true,
      }
    );

    return (await api
      .get(url)
      .then(({ data }) => mapPercentageByItem(data.data))) as CustomerBIResponse;
  }
}
