import { OrderVehicle } from "~/application/types";
import { toDate } from "~/application/utils/date-functions";
import { OrderVehicleDTO, VehicleQueryDetailDTO } from "../dtos";
import { mapTravelerDTO } from "./mapTravelerDTO";
import { mapVehicleDetailDTO } from "./mapVehicleDTO";
import { mapViolatedPoliciesDTO } from "./mapViolatedPoliciesDTO";
import { mapVehicleCoverageDTO } from "./mapVehicleCoverageDTO";

export function mapOrderVehicleDTO(data: OrderVehicleDTO): OrderVehicle {
  return {
    uuid: data.uuid,
    orderId: data.order_uuid,
    conductor: mapTravelerDTO(data.conductor),
    rentalCompany: data.rental_company,
    rentalCompanyImageUrl: data.rental_company_image_url,
    departure: data.departure,
    departureDate: toDate(data.departure_date),
    arrival: data.arrival,
    arrivalDate: toDate(data.arrival_date),
    vehicleDescription: data.vehicle_description,
    vehicleGroup: data.vehicle_group,
    vehicleImage: data.vehicle_image_url,
    vehicleAirConditioner: Boolean(data.vehicle_air_conditioner),
    vehicleTransmissionType: data.vehicle_transmission_type,
    vehicleDoorsNumbers: data.vehicle_doors_numbers,
    vehicleDetail: data.vehicle_detail,
    price: data.value,
    tracker: data.tracker,
    provider: data.provider,
    credential: data.credential,
    reasonRejected: {
      approver: data.reason_rejected.approver,
      reason: data.reason_rejected.reason,
      date: data.reason_rejected.date,
    },
    coverage: data.coverage ? mapVehicleCoverageDTO(data.coverage) : undefined,
    detail: mapVehicleDetailDTO(data?.detail || ({} as VehicleQueryDetailDTO)),
    returnFeeAnotherCity: data.return_fee_another_city,
    violatedPolicies: mapViolatedPoliciesDTO(data.violated_policies),
  };
}
