import { CellWithTooltip } from "./CellWithTooltip";
import { Grid } from "~/components/Grid";
import { Flex } from "~/components/Flex";
import { Card } from "~/components/Card";
import { H5 } from "~/components/Typography";
import { TopRoutes } from "~/application/types/entities/CustomerBI.type";
import { Text } from "~/components/Text";

export interface Columns {
  header: string;
  value: (purchase?: any) => any;
  isNumeric: boolean;
}

export interface PurchaseTableProps {
  columnContent?: TopRoutes[];
  columData: Columns[];
  columns: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  title: string;
}

const PurchaseTable = ({ columnContent, columns, title, columData }: PurchaseTableProps) => {
  return (
    <Card>
      <Flex css={{ p: "$5" }}>
        <H5>{title}</H5>
      </Flex>
      <Grid
        columns={columns}
        css={{
          py: "$4",
          "& > *": {
            px: "$2",
          },
        }}
      >
        {columData.map((col, index) => (
          <Flex
            key={`header-${index}`}
            css={{ borderBottom: "1px solid $neutrals-light", px: "$5", py: "$3" }}
          >
            <CellWithTooltip content={col.header} isHeader />
          </Flex>
        ))}

        {columnContent && columnContent.length > 0 && (
          columnContent.map((item, rowIndex) =>
            columData.map((col, colIndex) => (
              <Flex
                key={`cell-${rowIndex}-${colIndex}`}
                css={{
                  borderBottom: "1px solid $neutrals-light",
                  px: "$5",
                  py: "$3",
                  justifyContent: col.isNumeric ? "flex-end" : "flex-start",
                }}
              >
                <CellWithTooltip content={col.value(item)} />
              </Flex>
            ))
          )
        )}
      </Grid>
      <Flex justify="center" css={{ width: "100%", p: "$5" }}>
        {!columnContent && (
          <Text fw="700">Nenhum dado disponível</Text>
        )}
      </Flex>
    </Card>
  );
};

export default PurchaseTable;
