import * as React from "react";
import { SVGProps } from "react";

const SvgMetric = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23 1h1a1 1 0 0 0-1-1v1Zm-9.5 9.5-.707.707a1 1 0 0 0 1.414 0L13.5 10.5Zm-5-5 .707-.707a1 1 0 0 0-1.414 0L8.5 5.5ZM.293 12.293a1 1 0 1 0 1.414 1.414L.293 12.293ZM17 0a1 1 0 1 0 0 2V0Zm5 7a1 1 0 1 0 2 0h-2Zm.293-6.707-9.5 9.5 1.414 1.414 9.5-9.5L22.293.293Zm-8.086 9.5-5-5-1.414 1.414 5 5 1.414-1.414Zm-6.414-5-7.5 7.5 1.414 1.414 7.5-7.5-1.414-1.414ZM17 2h6V0h-6v2Zm5-1v6h2V1h-2Z"
      fill="#475A6B"
    />
  </svg>
);

export default SvgMetric;
