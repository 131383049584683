import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { useDebounce } from "use-debounce";
import { ApiError, DeepPartial, ExpenseCategory } from "~/application/types";
import { expenseCategoryService } from "~/application/usecases";
import { CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { snackbarService } from "~/components/SnackbarStack";
import { Text } from "~/components/Text";
import { QueryKeys, QueryTimes } from "~/constants";
import { InactivateDialog } from "~/core/shared/components/InactivateDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { ExpenseCategoriesContainer } from "./ExpenseCategoriesContainer";
import { ExpenseCategoryDialog } from "./components/ExpenseCategoryDialog";

const LOG_TAG = "ExpenseCategory/ExpenseCategoriesPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao listar categoria de despesa",
  CREATE_SUCCESS_MESSAGE: "Categoria de despesa criada",
  CREATE_ERROR_MESSAGE: "Falha ao criar categoria de despesa",
  UPDATE_SUCCESS_MESSAGE: "Categoria de despesa atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar categoria de despesa",
  ACTIVATE_ERROR_MESSAGE: "Falha ao ativar categoria de despesa",
  ACTIVATE_SUCCESS_MESSAGE: "Categoria de despesa ativada",
  INACTIVATE_ERROR_MESSAGE: "Falha ao inativar categoria de despesa",
  INACTIVATE_SUCCESS_MESSAGE: "Categoria de despesa inativada",
} as const;

const DEFAULT_FORM_DATA: DeepPartial<ExpenseCategory> = {
  name: "",
};

export function ExpenseCategoriesPage() {
  const [searchText, setSearchText] = useState<string>("");
  const [search] = useDebounce<string>(searchText, 700);

  const { data, isFetching } = useQuery<ExpenseCategory[], ApiError>(
    [QueryKeys.EXPENSE_CATEGORIES, { search }],
    () => expenseCategoryService.find({ name: search }),
    {
      staleTime: QueryTimes.NORMAL,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateCreateExpenseCategory } = useMutation(
    (item: ExpenseCategory) => expenseCategoryService.create(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Creating ExpenseCategory(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully created ExpenseCategory(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.EXPENSE_CATEGORIES]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.CREATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateUpdateExpenseCategory } = useMutation(
    (item: ExpenseCategory) => expenseCategoryService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ExpenseCategory(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ExpenseCategory(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.EXPENSE_CATEGORIES]);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE, "success");

        dialogService.popDialog();
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE, "error");
      },
    }
  );

  const { mutate: mutateToggleState } = useMutation(
    (item: ExpenseCategory) => expenseCategoryService.toggleActive(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating ExpenseCategory(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated ExpenseCategory(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.EXPENSE_CATEGORIES]);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_SUCCESS_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_SUCCESS_MESSAGE,
          "success"
        );

        if (item.isActive) {
          dialogService.popDialog();
        }
      },
      onError: (error, item) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          item.isActive
            ? SNACKBAR_MESSAGES.INACTIVATE_ERROR_MESSAGE
            : SNACKBAR_MESSAGES.ACTIVATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const handleToggleState = useCallback((item: ExpenseCategory) => {
    if (!item.isActive) {
      return mutateToggleState(item);
    }

    dialogService.showDialog(
      <InactivateDialog
        loadingMessage="Inativando categoria de despesa"
        onConfirm={() => mutateToggleState(item)}
      >
        <CardBody>
          <Text variant="darkest">Você poderá ativa-la novamente.</Text>
        </CardBody>
      </InactivateDialog>
    );
  }, []);

  const handleOpenCreateModal = useCallback(() => {
    dialogService.showDialog(
      <ExpenseCategoryDialog
        isNew
        onSubmit={mutateCreateExpenseCategory}
        defaultData={DEFAULT_FORM_DATA}
      />
    );
  }, []);

  const handleOpenEditModal = useCallback((item: ExpenseCategory) => {
    dialogService.showDialog(
      <ExpenseCategoryDialog onSubmit={mutateUpdateExpenseCategory} defaultData={item} />
    );
  }, []);

  const handleChangeSearch = useCallback((text: string) => {
    setSearchText(text);
  }, []);

  return {
    isLoading: isFetching,
    expenseCategoriesList: data,
    searchText,
    setSearchText: handleChangeSearch,
    onToggleState: handleToggleState,
    onCreateExpenseCategory: handleOpenCreateModal,
    onEditExpenseCategory: handleOpenEditModal,
  };
}
