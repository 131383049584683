export enum AgencyTab {
  INFORMATION = "informacoes-gerais",
  EMPLOYEES = "administradores",
  EXPENSE_CATEGORIES = "categoria-de-despesas"
}

export const AGENCY_MENU_TABS = [
  { title: "Informações gerais", id: AgencyTab.INFORMATION },
  { title: "Administradores", id: AgencyTab.EMPLOYEES },
  { title: "Categoria de despesas", id: AgencyTab.EXPENSE_CATEGORIES },
];
