import { useCallback, useMemo, useState } from "react";
import { StackedBarChart } from "./components/StackedBarChart";
import { Container } from "~/components/Container";
import { Card, CardBody } from "~/components/Card";
import { Col, Grid, Row } from "~/components/Grid";
import { H4, Label } from "~/components/Typography";
import { Flex } from "~/components/Flex";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Box } from "~/components/Box";
import { CustomerBI as DashboardBI } from "~/application/types/entities/CustomerBI.type";
import { convertChartData, filters, secondTablebLastPurchase, topRoutes } from "./utils";
import { CostCenterResume } from "./components/CostCenterResume";
import { IssuerResume } from "./components/IssuerResume";
import { PercentageChart } from "./components/PorcentageChart";
import { ChartByItem } from "./components/ChartByItem";
import { Skeleton } from "~/components/Skeleton";
import MonthlyChart from "./components/MonthlyChart";
import PurchaseTable from "./components/LastPurchase";
import { getServiceLabel } from "~/core/shared/utils/order.utils";
import { Button } from "~/components/Button";
import { dialogService } from "~/components/DialogStack";
import { Select, TextInput } from "~/components/Input";
import { FieldValues, useForm, UseFormReturn } from "react-hook-form";
import { FormDialog } from "~/components/FormDialog";
import { DialogBody, DialogFooter } from "~/components/Dialog";
import {
  Popover,
  PopoverAnchor,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { Text } from "~/components/Text";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { DoubleCalendar } from "~/core/shared/components/DoubleCalendar";
import { displayDate } from "~/utils/date.utils";
import { DateFormats } from "~/application/utils/date-functions";
import { ModalPortal } from "~/core/modules/DeprecatedBooking/components/ModalPortal";
import { MultiSelectWrap } from "~/components/Input/MultiSelectWrap";

export interface CustomersBiContainerProps {
  dashboard?: DashboardBI;
  isLoadingDashboard: boolean;
  filterData: UseFormReturn<FieldValues, any>;
}

export function CustomerBiContaner({
  dashboard,
  isLoadingDashboard,
  filterData,
}: CustomersBiContainerProps) {

  const [activeFilter, setActiveFilter] = useState({ type: "year", value: "12 meses" });
  const [modalPrtalIsOpen, setModalPortalIsOpen] = useState(false);
  const { watch, setValue, trigger, control } = filterData;
  const { periodFrom, periodUntil } = watch();

  const hasValueChart =
    dashboard?.byCostCenter &&
    dashboard.byCostCenter.length > 0 &&
    dashboard?.byIssuer &&
    dashboard.byIssuer.length > 0 &&
    dashboard?.percentageByItem &&
    dashboard?.percentageByItem?.some(({ percentValue }) => percentValue && percentValue > 0);

  const filteredData = useMemo(() => convertChartData(dashboard?.totalSpent ?? []), [dashboard]);
  const filterKeyValue = activeFilter.type === "three-months" ? "threeMonths" : activeFilter.type;

  const dataItem =
    dashboard?.dataByItem?.map(({ item, percentValue }) => ({
      name: getServiceLabel(item),
      value: Number(percentValue * 100) || 0,
    })) || [];

  const handleDateClick = useCallback(
    (date: Date) => {
      if (!periodFrom || (periodFrom && periodUntil)) {
        setValue("periodFrom", date);
        setValue("periodUntil", null);
      } else if (periodFrom && !periodUntil) {
        if (date < periodFrom) {
          setValue("periodFrom", date);
          setValue("periodUntil", periodFrom);
        } else {
          setValue("periodUntil", date);
        }
      }

      trigger(["periodFrom", "periodUntil"]);
    },
    [periodFrom, periodUntil, setValue, trigger]
  );

  return (
    <Flex direction="column">
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Dashboard BI" />
        </AppBarContainer>
      </AppBar>
      <Container css={{ py: "$10", "@mxlg": { p: "$5" }, "@lg": { maxWidth: "95vw" } }}>
        <Flex justify="end" css={{ py: "$5" }}>
          <Button variant="secondary-light" onClick={() => setModalPortalIsOpen((prev) => !prev)}>
            Filtros
          </Button>
          <ModalPortal isOpen={modalPrtalIsOpen}>
            <Container size="13" css={{ overflow: "visible" }}>
              <FormDialog
                css={{ overflow: "visible" }}
                title="Filtros"
                onClickDismissButton={() => setModalPortalIsOpen(false)}
                positiveButton={
                  <Button onClick={() => setModalPortalIsOpen(false)}>Aplicar</Button>
                }
                negativeButton={
                  <Button variant="tertiary" onClick={() => setModalPortalIsOpen(false)}>
                    Cancelar
                  </Button>
                }
              >
                <DialogBody css={{ p: "$5", overflow: "visible" }}>
                  <Row gap="2">
                    <Popover
                      css={{
                        position: "relative",
                      }}
                    >
                      <Flex
                        gap="2"
                        css={{
                          "@mxlg": {
                            flexDirection: "column",
                          },
                        }}
                      >
                        <Col>
                          <FormControl name={`periodFrom`} control={control}>
                            <FieldLabel>Periodo (de)</FieldLabel>

                            <PopoverTrigger asChild>
                              <TextInput
                                size="smlg"
                                onBlur={(e) => e.preventDefault()}
                                placeholder="Selecione a data"
                                style={{ paddingRight: "5px" }}
                                value={
                                  periodFrom ? displayDate(periodFrom, DateFormats.LONG_DATE) : ""
                                }
                                readOnly
                              />
                            </PopoverTrigger>

                            <PopoverAnchor />
                          </FormControl>
                        </Col>
                        <Col>
                          <FormControl name={`periodUntil`} control={control}>
                            <FieldLabel>Periodo (até)</FieldLabel>

                            <PopoverTrigger asChild>
                              <TextInput
                                onBlur={(e) => e.preventDefault()}
                                size="smlg"
                                style={{ paddingRight: "5px" }}
                                placeholder="Data da check-out"
                                value={
                                  periodUntil ? displayDate(periodUntil, DateFormats.LONG_DATE) : ""
                                }
                                readOnly
                              />
                            </PopoverTrigger>
                          </FormControl>
                        </Col>
                      </Flex>

                      <PopoverContent>
                        <Card
                          css={{
                            position: "absolute",
                            width: "40vw",
                            "@mxlg": {
                              width: "80vw",
                            },
                          }}
                        >
                          <DoubleCalendar
                            date={new Date()}
                            activeMinDate={periodFrom}
                            activeMaxDate={periodUntil}
                            onDateClick={handleDateClick}
                          />

                          <DialogFooter>
                            <CardBody>
                              <Flex gap="4" css={{ justifyContent: "flex-end" }}>
                                <PopoverClose asChild>
                                  <Button
                                    variant="tertiary"
                                    onClick={() => {
                                      setValue("periodFrom", null);
                                      setValue("periodUntil", null);
                                    }}
                                  >
                                    <Text>Excluir</Text>
                                  </Button>
                                </PopoverClose>

                                <PopoverClose asChild>
                                  <Button variant="secondary">
                                    <Text>Aplicar</Text>
                                  </Button>
                                </PopoverClose>
                              </Flex>
                            </CardBody>
                          </DialogFooter>
                        </Card>
                      </PopoverContent>
                    </Popover>
                  </Row>
                  <Col sz="auto" css={{ py: "$5" }}>
                    <FormControl name="costCenter" control={control}>
                      <FieldLabel>Categoria de centro de custo</FieldLabel>
                      <MultiSelectWrap
                        isMulti
                        options={dashboard?.costCenterData}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.uuid}
                        placeholder="Selecione uma categoria de centro de custo"
                      />
                    </FormControl>
                  </Col>
                </DialogBody>
              </FormDialog>
            </Container>
          </ModalPortal>
        </Flex>
        {!isLoadingDashboard && (
          <>
            <Card css={{ overflow: "hidden" }}>
              <Col css={{ width: "95%", margin: "0 auto", py: "$10" }}>
                <H4>Gastos totais</H4>
                <Flex gap="3">
                  {filters.map((filter, index) => (
                    <Box
                      css={{
                        mt: "$5",
                        p: "$3",
                        cursor: "pointer",
                        backgroundColor:
                          activeFilter.type === filter.type ? "$primary-base" : "transparent",
                        color: activeFilter.type === filter.type ? "#fff" : "inherit",
                        borderRadius: "$md",
                      }}
                      key={`customer-bi-${index}`}
                      onClick={() => setActiveFilter({ type: filter.type, value: filter.value })}
                    >
                      {filter.value}
                    </Box>
                  ))}
                </Flex>
              </Col>
              <StackedBarChart
                chartData={filteredData[filterKeyValue as keyof typeof filteredData]}
              />
            </Card>
            <Grid
              gap="4"
              columns={{ "@initial": 3, "@mxlg": 1 }}
              css={{ height: "auto", py: "$5" }}
            >
              <>
                <CostCenterResume costCenterSummary={dashboard?.byCostCenter} />
                <IssuerResume issuerResume={dashboard?.byIssuer} />
                <Card css={{ p: "$5" }}>
                  <Flex>
                    <H4>Porcentagem por produto</H4>
                  </Flex>
                  <Flex align="center" justify="center" css={{ height: "500px" }}>
                    <PercentageChart size="xlarge" percents={dataItem} />
                  </Flex>
                </Card>
              </>
            </Grid>

            <Grid columns={2} gap="4">
              <Grid gap="4" columns={{ "@initial": 2, "@mxlg": 1 }}>
                <ChartByItem
                  chartByItem={dashboard?.dataByItem}
                  percentageByItem={dashboard?.percentageByItem}
                />
              </Grid>
              <Card>
               <MonthlyChart data={dashboard?.leadTime} />
              </Card>
            </Grid>

            <Grid gap="5" columns={2} css={{ py: "$5" }}>
              <PurchaseTable
                columnContent={dashboard?.topRoutes}
                columData={topRoutes}
                columns={4}
                title="Top Rotas"
              />

              <PurchaseTable
                columnContent={dashboard?.routeAnalysis}
                columData={secondTablebLastPurchase}
                columns={5}
                title="Análise de rota"
              />
            </Grid>
          </>
        )}

        {isLoadingDashboard && (
          <Flex gap="2" css={{ p: "$14", width: "100%" }} direction="column">
            <Skeleton css={{ width: "100%", height: "300px", margin: "0 auto" }} />
            <Grid gap="2" columns={{ "@initial": "3", "@mxlg": "1" }}>
              {Array(3)
                .fill(null)
                .map((_, index) => (
                  <Skeleton
                    key={index}
                    css={{ width: "100%", height: "300px", margin: "0 auto" }}
                  />
                ))}
            </Grid>
            <Skeleton css={{ width: "100%", height: "300px", margin: "0 auto" }} />
          </Flex>
        )}
      </Container>
    </Flex>
  );
}
