import { FC, useCallback, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgCalendar, SvgMop, SvgOrder } from "~/components/Icon/icons";
import { Checkbox, Select, TextInput } from "~/components/Input";
import { MultiSelect } from "~/components/Input/MultiSelect";
import { Text } from "~/components/Text";
import { TabShoppingReportProps } from "./types";
import { ShoppingReportData } from "~/application/usecases/Reports/types";
import { DoubleCalendar } from "~/core/shared/components/DoubleCalendar";
import {
  Popover,
  PopoverAnchor,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/core/shared/components/Popover";
import { displayDate, toDate } from "~/utils/date.utils";
import { CardBody, CardFooter } from "~/core/modules/DeprecatedBooking/components/Card";
import { Flex } from "~/components/Flex";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { MultiSelectWrap } from "~/components/Input/MultiSelectWrap";
import { Branch } from "~/application/types";

export const TabShoppingReport: FC<TabShoppingReportProps> = ({
  onSubmit,
  onSearchName,
  isLoadingBranches,
  isLoadingApprovers,
  isLoadingCostCenters,
  isLoadingPassengers,
  isLoadingIssuers,
  costCenters,
  passengers,
  approvers,
  branches,
  issuers,
}) => {
  const { contexts } = useUser();

  const { control, handleSubmit, watch, register, setValue, resetField } =
    useForm<ShoppingReportData>({
      defaultValues: {
        customer: { uuid: contexts?.customer?.uuid, name: contexts?.customer?.tradingName },
        markAllBranchs: false,
        items: {
          airway: true,
          road: true,
          hotel: true,
          vehicle: true,
          offline: true,
        },
      },
    });

  const { travelDate, shoppingDate, markAllBranchs } = watch();
  const excludeShoppingDates = useCallback(() => {
    setValue("shoppingDate.end", undefined);
    setValue("shoppingDate.start", "");
  }, [resetField]);

  const excludeTravelDates = useCallback(() => {
    setValue("travelDate.end", undefined);
    setValue("travelDate.start", "");
  }, [resetField]);

  const handleShoppingDateClick = useCallback(
    (date: Date) => {
      if (!shoppingDate.start) {
        return setValue("shoppingDate.start", date);
      }

      if (shoppingDate.end && toDate(shoppingDate.end as string).getTime() === date.getTime()) {
        return setValue("shoppingDate.end", undefined);
      }

      if (date.getTime() < toDate(shoppingDate.start as string).getTime()) {
        setValue("shoppingDate.start", date);
      } else {
        setValue("shoppingDate.end", date);
      }
    },
    [shoppingDate, setValue]
  );

  const handleDateClick = useCallback(
    (date: Date) => {
      if (!travelDate.start) {
        setValue("travelDate.start", date);
      } else if (!travelDate.end) {
        if (date.getTime() < toDate(travelDate.start as string).getTime()) {
          setValue("travelDate.start", date);
        } else {
          setValue("travelDate.end", date);
        }
      } else {
        setValue("travelDate.start", date);
        setValue("travelDate.end", undefined);
      }
    },
    [travelDate, setValue]
  );

  const onSubmitCallback = (data: ShoppingReportData) => {
    onSubmit({ ...data, markAllBranchs });
  };

  return (
    <Container css={{ py: "$8", "@mxlg": { p: "$3" } }} fixed>
      <Form css={{ border: "none" }} onSubmit={handleSubmit(onSubmitCallback)}>
        <FormDialog
          withCloseButton={false}
          title="Relatório personalizado"
          negativeButton={
            <Button variant="tertiary">
              <Icon as={SvgMop} />
              <Text>Limpar dados</Text>
            </Button>
          }
          positiveButton={
            <Button type="submit">
              <Icon as={SvgOrder} />
              <Text>Baixar em .XLS</Text>
            </Button>
          }
        >
          <DialogBody
            css={{
              px: "$6",
              display: "flex",
              flexDirection: "column",
              gap: "$4",
            }}
          >
            <Row css={{ px: "0" }}>
              <Col
                sz={{ "@initial": "6", "@mxlg": "auto" }}
                css={{ mt: "$2", "@mxlg": { width: "100%", flexDirection: "column", p: "$0" } }}
              >
                <FormControl name="customer" control={control} required>
                  <FieldLabel>Empresa</FieldLabel>
                  <Select
                    disabled
                    placeholder="Selecione a empresa/filial"
                    isLoading={isLoadingBranches}
                    options={branches}
                    defaultValue={contexts?.customer?.uuid}
                    getOptionLabel={(c) => c.name}
                    getOptionValue={(c) => c.uuid}
                  />
                </FormControl>
              </Col>

              <Col
                sz={{ "@initial": "auto", "@mxlg": "auto" }}
                css={{ mt: "$2", "@mxlg": { width: "100%", flexDirection: "column", p: "0" } }}
              >
                <FormControl name="costCenters" control={control}>
                  <FieldLabel>Centro de custo</FieldLabel>
                  <MultiSelect
                    onInputChange={(e) => onSearchName({ cost_center: e })}
                    placeholder="Selecione o centro de custo"
                    isLoading={isLoadingCostCenters}
                    options={costCenters}
                    getOptionLabel={(c) => c.name}
                    getOptionValue={(c) => c.uuid as string}
                  />
                </FormControl>
              </Col>
            </Row>

            <Col
              sz={{ "@initial": "4", "@mxlg": "auto" }}
              css={{
                mt: "$2",
                "@mxlg": { width: "100%", flexDirection: "column", p: "0" },
                width: "100%",
              }}
            >
              <FormControl name="branchs" control={control}>
                <FieldLabel>Filiais</FieldLabel>
                <MultiSelectWrap
                  placeholder="Selecione as filiais"
                  isLoading={isLoadingBranches}
                  options={branches}
                  onChange={(branchs: Branch[]) => {
                    if (branchs.some((branch) => branch.name.toLowerCase() === "marcar todos")) {
                      setValue("markAllBranchs", true);
                      return setValue("branchs", branches?.filter((branch) => branch.uuid) || []);
                    }

                    if (branchs.some((branch) => branch.name.toLowerCase() === "desmarcar todos")) {
                      setValue("markAllBranchs", false);
                      return setValue("branchs", []);
                    }

                    setValue("markAllBranchs", false);
                    setValue("branchs", branchs);
                  }}
                  getOptionLabel={(c) => c.name}
                  getOptionValue={(c) => c.uuid}
                />
              </FormControl>
            </Col>

            <Row css={{ "@mxlg": { flexDirection: "column", gap: "$4" } }}>
              <Col>
                <FormControl name="issuers" control={control}>
                  <FieldLabel>Solicitante</FieldLabel>
                  <MultiSelect
                    onInputChange={(e) => onSearchName({ issuer: e })}
                    placeholder="Selecione o solicitante"
                    isLoading={isLoadingIssuers}
                    options={issuers}
                    getOptionLabel={(c) => `${c.name} ${c.lastName || ""}`}
                    getOptionValue={(c) => c.uuid}
                  />
                </FormControl>
              </Col>

              <Col>
                <FormControl name="approvers" control={control}>
                  <FieldLabel>Aprovador</FieldLabel>
                  <MultiSelect
                    onInputChange={(e) => onSearchName({ approver: e })}
                    placeholder="Selecione o aprovador"
                    isLoading={isLoadingApprovers}
                    options={approvers}
                    getOptionLabel={(c) => `${c.name} ${c.lastName || ""}`}
                    getOptionValue={(c) => c.uuid}
                  />
                </FormControl>
              </Col>

              <Col>
                <FormControl name="passengers" control={control}>
                  <FieldLabel>Passageiro</FieldLabel>
                  <MultiSelect
                    onInputChange={(e) => onSearchName({ passenger: e })}
                    placeholder="Digite o passageiro"
                    isLoading={isLoadingPassengers}
                    options={passengers}
                    getOptionLabel={(c) => `${c.name} ${c.lastName || ""}`}
                    getOptionValue={(c) => c.uuid}
                  />
                </FormControl>
              </Col>
            </Row>

            <Row></Row>

            <Row css={{ "@mxlg": { flexDirection: "column" } }}>
              <Popover>
                <Flex gap="2" justify="center" direction={{ "@mxlg": "column" }}>
                  <Col>
                    <FormControl control={control} name="shoppingDate.start" required>
                      <FieldLabel>Data de compra</FieldLabel>

                      <PopoverTrigger asChild css={{ "@mxlg": { mb: "$10" } }}>
                        <TextInput
                          {...register("shoppingDate.start", {
                            required: true,
                          })}
                          placeholder="Escolha a data"
                          leftIcon={SvgCalendar}
                          value={shoppingDate?.start && displayDate(shoppingDate?.start)}
                          style={{ width: "100%" }}
                          onChange={() => null}
                          onBlur={() => null}
                          readOnly
                        />
                      </PopoverTrigger>
                      <PopoverAnchor />

                      {/* Anchor */}
                    </FormControl>
                  </Col>
                  <Col>
                    <FormControl control={control} name="shoppingDate.end" required>
                      <FieldLabel
                        css={{
                          "@mxlg": {
                            color: "White",
                          },
                        }}
                      >
                        Fim
                      </FieldLabel>

                      <PopoverTrigger asChild>
                        <TextInput
                          {...(register("shoppingDate.end"),
                          {
                            required: true,
                          })}
                          placeholder="Escolha a data"
                          leftIcon={SvgCalendar}
                          style={{ width: "100%" }}
                          value={shoppingDate?.end ? displayDate(shoppingDate?.end) : undefined}
                          readOnly
                        />
                      </PopoverTrigger>
                    </FormControl>
                  </Col>

                  <PopoverContent>
                    <Flex as={Card} direction="column">
                      <DoubleCalendar
                        date={
                          shoppingDate?.start ? toDate(shoppingDate?.start as string) : new Date()
                        }
                        activeMinDate={toDate(shoppingDate?.start as string)}
                        activeMaxDate={toDate(shoppingDate?.end as string)}
                        maxDate={new Date()}
                        onDateClick={handleShoppingDateClick}
                      />

                      <CardFooter>
                        <CardBody
                          css={{
                            "@mxlg": {
                              p: "$2",
                            },
                          }}
                        >
                          <Flex
                            gap="4"
                            css={{
                              justifyContent: "flex-end",
                              "@mxlg": {
                                justifyContent: "space-around",
                              },
                            }}
                          >
                            <PopoverClose asChild>
                              <Button
                                css={{
                                  "@mxlg": {
                                    fontSize: "$sm",
                                    height: "$5",
                                  },
                                }}
                                variant="tertiary"
                                onClick={excludeShoppingDates}
                              >
                                Excluir
                              </Button>
                            </PopoverClose>

                            <PopoverClose asChild>
                              <Button
                                css={{
                                  "@mxlg": {
                                    fontSize: "$sm",
                                    height: "$5",
                                  },
                                }}
                                variant="secondary"
                              >
                                Aplicar
                              </Button>
                            </PopoverClose>
                          </Flex>
                        </CardBody>
                      </CardFooter>
                    </Flex>
                  </PopoverContent>
                </Flex>
              </Popover>
            </Row>
            <Row>
              <Popover>
                <Flex gap="2" justify="center" direction={{ "@mxlg": "column" }}>
                  <Col>
                    <FormControl
                      control={control}
                      name="travelDate.start"
                      required={!!travelDate?.end}
                    >
                      <FieldLabel>Data da viagem (Ida)</FieldLabel>

                      <PopoverTrigger asChild css={{ "@mxlg": { mb: "$10" } }}>
                        <TextInput
                          {...register("travelDate.start", {})}
                          placeholder="Escolha a data"
                          leftIcon={SvgCalendar}
                          value={travelDate?.start && displayDate(travelDate?.start)}
                          style={{ width: "100%" }}
                          onChange={() => null}
                          onBlur={() => null}
                          readOnly
                        />
                      </PopoverTrigger>
                      <PopoverAnchor />

                      {/* Anchor */}
                    </FormControl>
                  </Col>
                  <Col>
                    <FormControl
                      control={control}
                      name="travelDate.end"
                      required={!!travelDate?.start}
                    >
                      <FieldLabel
                        css={{
                          "@mxlg": {
                            color: "White",
                          },
                        }}
                      >
                        Volta
                      </FieldLabel>

                      <PopoverTrigger asChild>
                        <TextInput
                          {...register("travelDate.end")}
                          placeholder="Escolha a data"
                          leftIcon={SvgCalendar}
                          style={{ width: "100%" }}
                          value={travelDate?.end ? displayDate(travelDate?.end) : undefined}
                          readOnly
                        />
                      </PopoverTrigger>
                    </FormControl>
                  </Col>

                  <PopoverContent>
                    <Flex as={Card} direction="column">
                      <DoubleCalendar
                        date={travelDate?.start ? toDate(travelDate?.start as string) : new Date()}
                        activeMinDate={toDate(travelDate?.start as string)}
                        activeMaxDate={toDate(travelDate?.end as string)}
                        onDateClick={handleDateClick}
                      />

                      <CardFooter>
                        <CardBody
                          css={{
                            "@mxlg": {
                              p: "$2",
                            },
                          }}
                        >
                          <Flex
                            gap="4"
                            css={{
                              justifyContent: "flex-end",
                              "@mxlg": {
                                justifyContent: "space-around",
                              },
                            }}
                          >
                            <PopoverClose asChild>
                              <Button
                                css={{
                                  "@mxlg": {
                                    fontSize: "$sm",
                                    height: "$5",
                                  },
                                }}
                                variant="tertiary"
                                onClick={excludeTravelDates}
                              >
                                Excluir
                              </Button>
                            </PopoverClose>

                            <PopoverClose asChild>
                              <Button
                                css={{
                                  "@mxlg": {
                                    fontSize: "$sm",
                                    height: "$5",
                                  },
                                }}
                                variant="secondary"
                              >
                                Aplicar
                              </Button>
                            </PopoverClose>
                          </Flex>
                        </CardBody>
                      </CardFooter>
                    </Flex>
                  </PopoverContent>
                </Flex>
              </Popover>
            </Row>

            <Row css={{ "@mxlg": { flexDirection: "column" } }}>
              <FieldLabel>Produtos</FieldLabel>

              <Col sz={{ "@initial": "2", "@mxlg": "auto" }} css={{ "@mxlg": { py: "$2" } }}>
                <Card css={{ p: "$4" }}>
                  <FormControl name="items.airway" control={control}>
                    <Checkbox>
                      <Text size="4" variant="darkest">
                        Aéreo
                      </Text>
                    </Checkbox>
                  </FormControl>
                </Card>
              </Col>

              <Col sz={{ "@initial": "2", "@mxlg": "auto" }} css={{ "@mxlg": { py: "$2" } }}>
                <Card css={{ p: "$4" }}>
                  <FormControl name="items.road" control={control}>
                    <Checkbox>
                      <Text size="4" variant="darkest">
                        Rodoviário
                      </Text>
                    </Checkbox>
                  </FormControl>
                </Card>
              </Col>

              <Col sz={{ "@initial": "2", "@mxlg": "auto" }} css={{ "@mxlg": { py: "$2" } }}>
                <Card css={{ p: "$4" }}>
                  <FormControl name="items.hotel" control={control}>
                    <Checkbox>
                      <Text size="4" variant="darkest">
                        Hotel
                      </Text>
                    </Checkbox>
                  </FormControl>
                </Card>
              </Col>

              <Col sz={{ "@initial": "2", "@mxlg": "auto" }} css={{ "@mxlg": { py: "$2" } }}>
                <Card css={{ p: "$4" }}>
                  <FormControl name="items.vehicle" control={control}>
                    <Checkbox>
                      <Text size="4" variant="darkest">
                        Carro
                      </Text>
                    </Checkbox>
                  </FormControl>
                </Card>
              </Col>

              <Col sz={{ "@initial": "3", "@mxlg": "auto" }} css={{ "@mxlg": { py: "$2" } }}>
                <Card css={{ p: "$4" }}>
                  <FormControl name="items.offline" control={control}>
                    <Checkbox>
                      <Text size="4" variant="darkest">
                        Produtos offline
                      </Text>
                    </Checkbox>
                  </FormControl>
                </Card>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};
