import { AirlineCode } from "~/application/types"

export const airlineImageSize = (airline: string) => {
    switch (airline) {
      case AirlineCode.AIR_CANADA:
        return "3xl"
      case AirlineCode.UNITED_AIRLINES:
        return "lg"
      case AirlineCode.DELTA_AIRLINES:
        return "4xl"
      case AirlineCode.LUFTHANSA:
        return "lg"
      case AirlineCode.AVIANCA:
        return "2xl"
      case AirlineCode.BRITISH_AIRWAYS:
        return "3xl"
      case AirlineCode.TURKISH_AIRLINES:
        return "lg"
       case AirlineCode.KLM:
        return "lg"
      case AirlineCode.IBERIA:
        return "lg"
      case AirlineCode.AIR_FRANCE:
        return "lg"
      case AirlineCode.TAP:
        return "3xl"
      case AirlineCode.AT:
        return "2xl"
      default: "md"
        break;
    }
  }