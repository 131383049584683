import { CustomerEmployeesGroup } from "./CustomerEmployeesGroup.type";

export enum GenderEnum {
  MASCULINE = "M",
  FEMALE = "F",
  EMPYT = "",
}

export type Traveler = {
  uuid: string;
  fullName: string;
  cpf: string;
  ticket?: string;
  email: string;
  dateBirth: Date;
  position?: string;
  rg: string;
  phone: string;
  gender?: GenderEnum;
  company: {
    tradingName: string;
  };
  loyaltyLatam?: string;
  loyaltyAzul?: string;
  loyaltyGol?: string;
  seats?: {
    bpe: string | null;
    isConnection: boolean;
    seatNumber: string;
    seatType: string;
    ticket?: string | null;
    uuid?: string;
    departure?: string;
    arrival?: string;
    value?: number;
  }[];
  group?: CustomerEmployeesGroup;
  avatarUrl?: string;
};
