import { api } from "~/infrastructure/api";
import { DashboardBiResponse, FindDashboardProps, IDashboardBIService } from "./IDashboardService";
import {
  mapDashboardBIDTO,
  mapLogoutCustomers,
  LogoutCustomers,
  NewClientsPurchaseMonth,
  mapNewClientsPurchaseMonth,
  OrdersNotIssuedWeek,
  mapOrdersNotIssuedWeek,
  mapPendingFirstPurchase,
  PendingFirstPurchase,
  NotBuyingCustomers,
  mapNotBuyingCustomers,
  OperationalInterventions,
  mapOperationalInterventions,
  LogoutCustomersProps,
  NewClientsPurchaseMonthProps,
  PendingFirstPurchaseProps,
  NotBuyingCustomersProps,
  OrdersNotIssuedWeekProps,
} from "~/infrastructure/api/mappers/mapDashboardBIDTO";
import queryString, { stringifyUrl } from "query-string";
import { PaginatedResource } from "~/application/types";

type FindNotBuyingCustomer = { agencyId: string; page: number; name: string };

export class DashboardBIService implements IDashboardBIService {
  async findLogoutCustomer({
    agencyId,
    page,
    name,
  }: {
    agencyId: string;
    page: number;
    name: string;
  }): Promise<PaginatedResource<LogoutCustomers>> {
    const url = queryString.stringifyUrl({
      url: `/agencies/${agencyId}/dashboard-bi/logout-customers`,
      query: { page, name },
    });

    return await api.get<PaginatedResource<LogoutCustomersProps>>(url).then(({ data }) => ({
      data: mapLogoutCustomers(data.data),
      links: data.links,
      meta: data.meta,
    }));
  }

  async findNewClientsPurchaseMonth(agencyId: string): Promise<NewClientsPurchaseMonth> {
    return await api
      .get<{ data: NewClientsPurchaseMonthProps }>(
        `/agencies/${agencyId}/dashboard-bi/new-clients-purchase-month`
      )
      .then(({ data }) => mapNewClientsPurchaseMonth(data.data));
  }

  async findPendingFirstPurchase(agencyId: string): Promise<PendingFirstPurchase> {
    return await api
      .get<{ data: PendingFirstPurchaseProps }>(
        `/agencies/${agencyId}/dashboard-bi/pending-first-purchase`
      )
      .then(({ data }) => mapPendingFirstPurchase(data.data));
  }

  async findNotBuyingCustomers({
    agencyId,
    page,
    name,
  }: FindNotBuyingCustomer): Promise<PaginatedResource<NotBuyingCustomers>> {
    const url = queryString.stringifyUrl({
      url: `/agencies/${agencyId}/dashboard-bi/not-buying-customers`,
      query: { page, name },
    });

    return await api.get<PaginatedResource<NotBuyingCustomersProps>>(url).then((data) => ({
      data: mapNotBuyingCustomers(data.data.data),
      links: data.data.links,
      meta: data.data.meta,
    }));
  }

  async findOrdersNotIssuedWeek(agencyId: string): Promise<OrdersNotIssuedWeek> {
    return await api
      .get<{ data: OrdersNotIssuedWeekProps }>(
        `/agencies/${agencyId}/dashboard-bi/orders-not-issued-week`
      )
      .then(({ data }) => mapOrdersNotIssuedWeek(data.data));
  }

  async findOperationalInterventions({
    agencyId,
    maxDate,
    minDate,
  }: FindDashboardProps): Promise<OperationalInterventions> {
    const url = queryString.stringifyUrl({
      url: `/agencies/${agencyId}/dashboard-bi/operational-interventions`,
      query: {
        min_date: minDate,
        max_date: maxDate,
      },
    });
    return await api.get(url).then((data) => mapOperationalInterventions(data.data.data));
  }

  async find({
    agencyId,
    maxDate,
    minDate,
    chartResume,
  }: FindDashboardProps): Promise<DashboardBiResponse> {
    const url = queryString.stringifyUrl({
      url: `/agencies/${agencyId}/dashboard-bi`,
      query: {
        min_date: minDate,
        max_date: maxDate,
        chart_filter: chartResume,
      },
    });
    const { data } = await api.get(url);

    return {
      filters: data.data.filters.map(mapDashboardBIDTO),
    };
  }
}
