import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";

interface CellWithTooltipProps {
  content?: string;
  isHeader?: boolean;
}

export function CellWithTooltip({ content, isHeader = false }: CellWithTooltipProps) {
  return (
    <Tooltip
      content={
        <TooltipLabel>
          <Flex direction="column" gap="2">
            <Text>{content}</Text>
          </Flex>
        </TooltipLabel>
      }
    >
      <Flex
        css={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Text
          css={{
            cursor: "pointer",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            fontWeight: isHeader ? 700 : "inherit",
          }}
        >
          {content}
        </Text>
      </Flex>
    </Tooltip>
  );
}
