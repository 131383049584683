import { Button } from "~/components/Button";
import { Card } from "~/components/Card";
import { DialogHeader } from "~/components/Dialog";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgMystery } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";

export type CheckOrderDuplicityDialogProps = {
  data: {
    orderId: string;
    orderNumber: string;
  }[];
  onConfirm: () => void;
  onCancel: () => void;
};

export const CheckOrderDuplicityDialog = ({
  data,
  onCancel,
  onConfirm,
}: CheckOrderDuplicityDialogProps) => {
  return (
    <Card
      css={{
        boxShadow: "$sm",
        height: "auto",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: "$5",
        px: "$15",
        gap: "$4",
        textAlign: "center",
        width: "600px",
        position: "relative",
        color: "$neutrals-dark",
        "@mxsm": {
          width: "60%",
          margin: "0 auto",
          overflow: "hidden",
        },
      }}
    >
      <Icon
        size="4xl"
        as={SvgMystery}
        css={{
          fill: "$primary-base",
        }}
      />
      <IconButton
        css={{
          position: "absolute",
          right: "$5",
          stroke: "$primary-base",
        }}
        onClick={onCancel}
      >
        <Icon as={SvgClose} />
      </IconButton>
      <DialogHeader
        css={{
          borderBottom: "none",
          p: 0,
        }}
      >
        <H4
          fw="600"
          css={{
            width: "390px",
            gap: "$13",
            color: "$neutrals-black",
            "@mxmd": {
              fontSize: "$lg",
            },
          }}
        >
          Atenção!
        </H4>
      </DialogHeader>
      <Flex
        direction="column"
        css={{
          width: "85%",
          textAlign: "center",
          gap: "$3",
        }}
      >
        <Text css={{ lineHeight: "1.4" }}>Identificamos uma possível duplicidade nos pedidos:</Text>
        <Flex align="center" justify="center" gap="2">
          {data.map((order) => (
            <Link
              to={`/pedidos/${order.orderId}`}
              target="_blank"
              css={{ textDecoration: "underline", fw: 600 }}
            >#{order.orderNumber}</Link>
          ))}
        </Flex>
        <Text>Deseja continuar?</Text>
      </Flex>
      <Flex
        gap="2"
        css={{
          width: "100%",
          mt: "$7",
        }}
        align="center"
        justify="center"
      >
        <Button
          onClick={onCancel}
          variant="tertiary"
          css={{
            flex: "1",
          }}
        >
          Revisar pedido
        </Button>
        <Button
          onClick={onConfirm}
          css={{
            flex: "1",
          }}
        >
          Continuar assim mesmo
        </Button>
      </Flex>
    </Card>
  );
};
