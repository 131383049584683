import { Markdown } from "~/application/types";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem, DataListItemProps } from "~/components/List/DataListItem";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { getAirlineLogo } from "~/core/shared/utils";
import useMobile from "~/presentation/shared/hooks/useMobile";

export interface MarkdownListItemProps extends DataListItemProps {
  data: Markdown;
  onEditClick: (item: Markdown) => void;
  onToggleState: (item: Markdown) => void;
}

export function MarkdownListItem({
  data,
  onEditClick,
  onToggleState,
  ...props
}: MarkdownListItemProps) {
  const isMobile = useMobile();

  return (
    <DataListItem
      size="sm"
      data-active={data.isActive}
      {...props}
      css={{
        "@mxlg": {
          flexDirection: "column",
        },
      }}
    >
      <DataColItem headerTitle="Markdown" data={data.name} />
      <DataColItem
        headerTitle="Companias"
        data={
          <Flex align="center" gap="2">
            {data.items.map((item) => (
              <Flex align="center" gap="4" key={item.uuid}>
                <CompanyLogo
                  src={getAirlineLogo(item.airline.logo)}
                  alt={item.airline.name}
                  size="md"
                />
                <Text css={{ fw: "600" }}>{`${item.value}%`}</Text>
              </Flex>
            ))}
          </Flex>
        }
      />
      {!isMobile && (
        <>
          <Tooltip content={<TooltipLabel>Editar markdown</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </>
      )}

      {isMobile && (
        <Flex justify="between" css={{ width: "100%" }}>
          <Tooltip content={<TooltipLabel>Editar markdown</TooltipLabel>}>
            <IconButton size="md" onClick={() => onEditClick(data)}>
              <Icon as={SvgEdit} />
            </IconButton>
          </Tooltip>
          <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
            <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
          </Switch>
        </Flex>
      )}
    </DataListItem>
  );
}

MarkdownListItem.displayName = "MarkdownListItem";
