import { ApiError } from "~/application/types";
import { Agency, State } from "~/application/types";
import { snackbarService } from "~/components/SnackbarStack";
import { QueryKeys } from "~/constants/queryKeys";
import { QueryTimes } from "~/constants/queryTimes";
import { queryClient } from "~/services/queryClient";
import { agencyService, stateService } from "~/application/usecases";
import { log } from "~/utils/log";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { AgencyTab } from "../../utils";
import { AgencyContainer } from "./AgencyContainer";
import { useAgencyEmployees } from "./hooks/useAgencyEmployees";
import { useAgencyTab } from "./hooks/useAgencyTab";
import { ExpenseCategoriesPage as useExpenseCategories } from "~/core/modules/ExpenseCategory/pages/ExpenseCategoriesPage";

const LOG_TAG = "Agency/AgencyPage";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Falha ao carregar agência",
  UPDATE_SUCCESS_MESSAGE: "Agência atualizada",
  UPDATE_ERROR_MESSAGE: "Falha ao atualizar agência",
} as const;

export function AgencyPage() {
  const { agencyId } = useParams() as { agencyId: string };

  const tabValue = useAgencyTab();

  const { data, isFetching } = useQuery<Agency, ApiError>(
    [QueryKeys.AGENCY, agencyId],
    () => agencyService.findById(agencyId),
    {
      staleTime: QueryTimes.LONG,
      refetchOnWindowFocus: false,
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const { mutate: handleUpdateAgency } = useMutation(
    (item: Agency) => agencyService.updateById(item),
    {
      onMutate: (item) => {
        log.i(LOG_TAG, `Updating Agency(${item.uuid})`);
      },
      onSuccess: (_, item) => {
        log.i(LOG_TAG, `Successfully updated Agency(${item.uuid})`);

        queryClient.invalidateQueries([QueryKeys.AGENCY, agencyId]);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_SUCCESS_MESSAGE,
          "success"
        );
      },
      onError: (error) => {
        log.e(LOG_TAG, error);

        snackbarService.showSnackbar(
          SNACKBAR_MESSAGES.UPDATE_ERROR_MESSAGE,
          "error"
        );
      },
    }
  );

  const fetchCitiesByState = useCallback(
    (state: State) =>
      stateService
        .findCities(state)
        .then((values) => values.sort((a, b) => (a.name > b.name ? 1 : -1))),
    []
  );

  const agencyEmployeesState = useAgencyEmployees({
    agencyId,
    enabled: tabValue === AgencyTab.EMPLOYEES,
  });

   const expenseCategoriesState = useExpenseCategories();

  return (
    <AgencyContainer
      isLoading={isFetching}
      activeTab={tabValue}
      expenseCategoriesState={expenseCategoriesState}
      agency={data}
      fetchCitiesByState={fetchCitiesByState}
      agencyEmployeesState={agencyEmployeesState}
      onUpdateAgency={handleUpdateAgency}
    />
  );
}
