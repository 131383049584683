import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { Actions, CostCenter, CustomerEmployee, Profile, UserContext } from "~/application/types";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { DialogBody } from "~/components/Dialog";
import { Form } from "~/components/Form/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col, Row } from "~/components/Grid";
import {
  Checkbox,
  ContainedRadio,
  MaskedInput,
  Select,
  Switch,
  TextInput,
} from "~/components/Input";
import { Text } from "~/components/Text";
import { Caption, H5 } from "~/components/Typography";
import {
  EditableCustomerEmployee,
  editCustomerEmployee,
} from "~/presentation/shared/views/CustomerEmployeeDialog";
import * as MaskUtils from "~/utils/mask.utils";
import { CustomerEmployeeDialogProps } from "./types";
import { validateDate } from "~/utils/date.utils";
import { useQuery } from "@tanstack/react-query";
import { profileService } from "~/application/usecases/Profile";
import { QueryKeys } from "~/application/constants";
import { useDebounce } from "use-debounce";
import { useVerifyActions } from "../../hooks/useVerifyActions";
import { useUser } from "~/presentation/core/contexts/UserContext";

const customerEmployeeSchema = yup.object().shape({
  name: yup.string().required("O nome é obrigatório"),
  lastName: yup.string().required("O sobrenome é obrigatório"),
  group: yup.object().shape({
    uuid: yup.string(),
    name: yup.string(),
  }),
  rg: yup.string().required("O RG é obrigatório"),
  cpf: yup.string().required("O CPF é obrigatório").matches(MaskUtils.PATTERN_CPF, "CPF inválido"),
  email: yup
    .string()
    .email("E-mail inválido")
    .when("canCreateUser", {
      is: true,
      then: (schema) => schema.required("O e-mail é obrigatório"),
    })
    .email("E-mail inválido")
    .nullable(),
  phone: yup.string().min(18, "Telefone inválido").required("O telefone é obrigatório"),
  birthDate: yup
    .string()
    .required("A data de nascimento é obrigatória")
    .test("is-valid-date", "Data inválida", (value) => validateDate(value!)),
  gender: yup.string().oneOf(["M", "F", ""]).nullable(),
  registration: yup.string().nullable(),
  canCreateUser: yup.boolean(),
  isAdmin: yup.boolean(),
  isApprover: yup.boolean(),
  costCenter: yup.object().shape({
    uuid: yup.string(),
    name: yup.string(),
  }),
  position: yup.string().nullable(),
  outsourced: yup.boolean().nullable(),
  passportNumber: yup.string().nullable(),
  passportExpiration: yup.string().nullable(),
  loyaltyLatam: yup.string().nullable(),
  loyaltyAzul: yup.string().nullable(),
  loyaltyGol: yup.string().nullable(),
  profile: yup.object().shape({
    uuid: yup.string(),
    name: yup.string(),
  }),
});

export type CustomerEmployeeSchema = yup.InferType<typeof customerEmployeeSchema>;

export const defaultFormManager = (data?: CustomerEmployee) => {
  return useForm<CustomerEmployeeSchema>({
    defaultValues: editCustomerEmployee(data),
    resolver: yupResolver(customerEmployeeSchema),
  });
};

export const CustomerEmployeeDialog: FC<CustomerEmployeeDialogProps> = ({
  isNew,
  user,
  data,
  getFormManager = () => defaultFormManager(data),
  onCloseClick,
  fetchProfile,
  fetchCostCenters,
  onSearchGroup,
  onSubmit,
  groupOptions,
  customerId,
}) => {
  const { profile } = fetchProfile(user);
  const [searchProfileName, onSearchProfileName] = useState("");
  const [debounceProfileName] = useDebounce(searchProfileName, 700);
  const [searchCostCenter, onSearchCostCenter] = useState("");
  const [debounceCostCenter] = useDebounce(searchCostCenter, 700);
  const { contexts } = useUser();

  const { data: profiles, isLoading: isLoadingProfiles } = useQuery(
    [QueryKeys.PROFILES, debounceProfileName],
    async () =>
      await profileService.findAll({
        page: 1,
        customerId: customerId || user?.customer?.uuid || "",
        name: debounceProfileName,
      })
  );

  const canCreateProfile = useVerifyActions({
    actions: [Actions.ViewCustomerEmployee, Actions.CreateOrder],
    contexts,
    profile,
  });

  const isCustomerUser = user.context === UserContext.Customer;

  isNew = isNew || !data;

  const { control, formState, handleSubmit, watch, setError, setValue, clearErrors } =
    getFormManager();
  const { costCenters, isLoadingCostCenters } = fetchCostCenters({ name: debounceCostCenter });

  const { phone, canCreateUser, loyaltyAzul, loyaltyGol, cpf, group } = watch();

  const getCostCenterLabel = useCallback(({ name }: CostCenter) => name, []);
  const getCostCenterValue = useCallback(({ uuid }: CostCenter) => uuid, []);

  const handleRgInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    onChange: (value: string) => void
  ) => {
    const value = e.target.value.replace(/\D/g, "");
    onChange(value);
  };

  const canShowEmailInput = ((isCustomerUser && isNew) || canCreateProfile) && canCreateUser;

  useEffect(() => {
    if (loyaltyAzul && cpf && loyaltyAzul === cpf) {
      setError("loyaltyAzul", {
        message: "Fidelidade não pode ser igual ao CPF",
        type: "disabled",
      });
    } else {
      clearErrors("loyaltyAzul");
    }

    if (loyaltyGol && cpf && loyaltyGol === cpf) {
      setError("loyaltyGol", {
        message: "Fidelidade não pode ser igual ao CPF",
      });
    } else {
      clearErrors("loyaltyGol");
    }
  }, [loyaltyAzul, cpf, loyaltyGol, setError, setValue]);

  return (
    <Container size="8" fixed>
      <Form
        onSubmit={handleSubmit((data) => onSubmit(data as EditableCustomerEmployee))}
        css={{
          "@mxmd": {
            width: "90%",
            margin: "0 auto",
          },
        }}
      >
        <FormDialog
          title={isNew ? "Novo funcionário" : "Editar funcionário"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={formState.isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Box css={{ mb: "$10" }}>
              <H5>Informações básicas</H5>
            </Box>

            <Row
              gap="6"
              css={{
                "@mxmd": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome</FieldLabel>
                  <TextInput />
                  {formState.errors.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.name.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="lastName" control={control} required>
                  <FieldLabel>Sobrenome</FieldLabel>
                  <TextInput />
                  {formState.errors.lastName && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.lastName.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="rg" control={control} required>
                  <FieldLabel>RG</FieldLabel>
                  <Controller
                    name="rg"
                    control={control}
                    render={({ field }) => (
                      <TextInput
                        {...field}
                        onChange={(e) => handleRgInputChange(e, field.onChange)}
                      />
                    )}
                  />
                  {/* <TextInput /> */}
                  {formState.errors.rg && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.rg.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="cpf" control={control} required pattern={MaskUtils.PATTERN_CPF}>
                  <FieldLabel>CPF</FieldLabel>
                  <MaskedInput mask={MaskUtils.MASK_CPF} />
                  {formState.errors.cpf && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.cpf.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="phone" control={control} required>
                  <FieldLabel>Telefone</FieldLabel>
                  <MaskedInput mask={phone ? MaskUtils.pickPhoneNumberMask(phone) : ""} />
                  {formState.errors.phone && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.phone.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="group" control={control}>
                  <FieldLabel>Grupo</FieldLabel>
                  <Select
                    placeholder="Selecione o grupo"
                    options={groupOptions}
                    onInputChange={onSearchGroup}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.uuid}
                  />
                  {formState.errors.group && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.group.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="birthDate" control={control} required>
                  <FieldLabel>Data de nascimento</FieldLabel>
                  <MaskedInput mask={MaskUtils.MASK_DATE} />
                  {formState.errors.birthDate && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.birthDate.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="registration" control={control}>
                  <FieldLabel>Matrícula</FieldLabel>
                  <TextInput />
                  {formState.errors.registration && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.registration.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              {canShowEmailInput && (
                <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                  <FormControl name="email" control={control} required={canCreateUser}>
                    <FieldLabel>E-mail</FieldLabel>
                    <TextInput />
                    {formState.errors.email && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.email.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}

              {!canShowEmailInput && <Col sz="6" css={{ "@mxmd": { display: "none" } }} />}
              {canCreateProfile && (
                <Col>
                  <FormControl name="canCreateUser" control={control}>
                    <FieldLabel>Criar usuário de acesso</FieldLabel>
                    <Switch checked={canCreateUser} />
                    {formState.errors.canCreateUser && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.canCreateUser.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}

              {canCreateUser && canCreateProfile && (
                <Col>
                  <FormControl control={control} name="profile" required>
                    <FieldLabel>Perfil</FieldLabel>
                    <Select
                      isLoading={isLoadingProfiles}
                      options={profiles?.data as Profile[]}
                      getOptionLabel={(profile) => profile.name}
                      getOptionValue={(profile) => profile.uuid}
                      onInputChange={onSearchProfileName}
                      placeholder="Selecione o perfil"
                      portalled
                    />
                    {formState.errors.profile?.uuid && (
                      <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                        {formState.errors.profile?.uuid.message}
                      </Text>
                    )}
                  </FormControl>
                </Col>
              )}

              <Col sz="12">
                <FormControl name="gender" control={control}>
                  <FieldLabel>Sexo</FieldLabel>
                  <Row
                    gap="6"
                    css={{
                      "@mxmd": {
                        display: "flex",
                        flexDirection: "column",
                      },
                    }}
                  >
                    <Col>
                      <ContainedRadio value="">
                        <Text size="3" css={{ fw: "600" }}>
                          Não informado
                        </Text>
                      </ContainedRadio>
                    </Col>

                    <Col>
                      <ContainedRadio value="M">
                        <Text size="3" css={{ fw: "600" }}>
                          Masculino
                        </Text>
                      </ContainedRadio>
                    </Col>

                    <Col>
                      <ContainedRadio value="F">
                        <Text size="3" css={{ fw: "600" }}>
                          Feminino
                        </Text>
                      </ContainedRadio>
                    </Col>
                  </Row>
                  {formState.errors.gender && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.gender.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>

            <Box css={{ my: "$10" }}>
              <H5>Faturamento</H5>
            </Box>

            <Row
              gap="6"
              css={{
                "@mxmd": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="costCenter" control={control}>
                  <FieldLabel>Centro de Custo</FieldLabel>
                  <Select
                    placeholder="Selecione um centro de custo"
                    options={costCenters}
                    onInputChange={onSearchCostCenter}
                    getOptionLabel={getCostCenterLabel}
                    getOptionValue={getCostCenterValue}
                    isLoading={isLoadingCostCenters}
                  />
                  {formState.errors.costCenter && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.costCenter.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="position" control={control}>
                  <FieldLabel>Função</FieldLabel>
                  <TextInput />
                  {formState.errors.position && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.position.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="outsourced" control={control}>
                  <Checkbox>
                    <Caption>Funcionário terceirizado</Caption>
                  </Checkbox>
                  {formState.errors.outsourced && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.outsourced.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>

            <Box css={{ my: "$10" }}>
              <H5>Viagens</H5>
            </Box>

            <Row
              gap="6"
              css={{
                "@mxmd": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="passportNumber" control={control}>
                  <FieldLabel>Passaporte</FieldLabel>
                  <TextInput />
                  {formState.errors.passportNumber && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.passportNumber.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz={{ "@initial": "6", "@mxmd": "auto" }}>
                <FormControl name="passportExpiration" control={control}>
                  <FieldLabel>Validade do passaporte</FieldLabel>
                  <MaskedInput mask={MaskUtils.MASK_DATE} />
                  {formState.errors.passportExpiration && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.passportExpiration.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>

            <Box css={{ my: "$10" }}>
              <H5>Fidelidade</H5>
            </Box>

            <Row gap="6">
              <Col sz="12">
                <FormControl name="loyaltyAzul" control={control}>
                  <FieldLabel>Fidelidade Azul</FieldLabel>
                  <TextInput warning={!!formState.errors.loyaltyAzul} />
                  {formState.errors.loyaltyAzul && (
                    <Text variant="warning-dark" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.loyaltyAzul.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="loyaltyGol" control={control}>
                  <FieldLabel>Fidelidade Gol</FieldLabel>
                  <TextInput warning={!!formState.errors.loyaltyGol} />
                  {formState.errors.loyaltyGol && (
                    <Text variant="warning-dark" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.loyaltyGol.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz="12">
                <FormControl name="loyaltyLatam" control={control}>
                  <FieldLabel>Fidelidade Latam</FieldLabel>
                  <TextInput />
                  {formState.errors.loyaltyLatam && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {formState.errors.loyaltyLatam.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

CustomerEmployeeDialog.displayName = "CustomerEmployeeDialog";
