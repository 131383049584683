import { PieChart, Pie, Cell, Legend, ResponsiveContainer, Tooltip } from "recharts";
import { ByPercentagem } from "~/application/types/entities/CustomerBI.type";
import { Card } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import useMobile from "~/presentation/shared/hooks/useMobile";
interface PercentageChartProps {
  percents?: { name?: string; value?: number }[];
  size?: "small" | "medium" | "large" | "xlarge";
  title?: string;
  colors?: string[];
  showLegend?: boolean;
  showTooltip?: boolean;
  legendPosition?: "bottom" | "right";
}

const DEFAULT_COLORS = ["#0D47A1", "#1976D2", "#42A5F5", "#B0BEC5"];


const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <Card css={{ p: "$2" }}>
        {" "}
        <Flex gap="1">
          {" "}
          <Text>{payload[0].name}</Text> - <Text>{payload[0].value}%</Text>{" "}
        </Flex>{" "}
      </Card>
    );
  }
  return null;
};
export function PercentageChart({
  percents,
  size = "medium",
  title,
  colors = DEFAULT_COLORS,
  showLegend = true,
  showTooltip = true,
  legendPosition = "bottom",
}: PercentageChartProps) {

  const chartSizes = {
    small: { height: 80, innerRadius: 20, outerRadius: 30 },
    medium: { height: 120, innerRadius: 25, outerRadius: 35 },
    large: { height: 160, innerRadius: 30, outerRadius: 40 },
    xlarge: { height: 300, innerRadius: 60, outerRadius: 80 },
  };

  const percentsWithValue = percents?.filter((item) => item.value && item.value > 0) || [];

  const { height, innerRadius, outerRadius } = chartSizes[size];

  return (
    <Flex direction="column" css={{ width: "100%", height: "100%" }}>
      {title && <Text css={{ mb: "$2", fontWeight: "500" }}>{title}</Text>}

      {percentsWithValue.length === 0 ? (
        <Flex justify="center" align="center" css={{ height }}>
          <Text>Nenhum dado disponível</Text>
        </Flex>
      ) : (
        <ResponsiveContainer width="100%" height={height}>
          <PieChart>
            <Pie
              dataKey="value"
              data={percents}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              paddingAngle={0}
              startAngle={90}
              endAngle={-270}
            >
              {percents?.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} strokeWidth={0} />
              ))}
            </Pie>

            {showTooltip && <Tooltip content={<CustomTooltip />} />}

            {showLegend && (
              <Legend
                iconType="circle"
                layout={legendPosition === "right" ? "vertical" : "horizontal"}
                align={legendPosition === "right" ? "right" : "center"}
                margin={{ top: 10 }}
                verticalAlign={legendPosition === "right" ? "middle" : "bottom"}
                iconSize={8}
                formatter={(value) => (
                    <Text css={{ fontSize: "$sm", color: "$gray600" }}>{value}</Text>
                )}
              />
            )}
          </PieChart>
        </ResponsiveContainer>
      )}
    </Flex>
  );
}
