import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export interface ChartDataProps {
  name: string;
  sales?: number;
  pv?: number;
  amt: number;
}

interface LineChartComponentProps {
  dataKey: string;
  name?: string;
  chartHeight?: number;
  chartData: ChartDataProps[];
}

export function LineChartComponent({ chartData, dataKey, name, chartHeight = 300 }: LineChartComponentProps) {
  return (
    <ResponsiveContainer width="100%" height={chartHeight} style={{ marginTop: "20px" }}>
      <LineChart
        data={chartData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis tickCount={30} interval={30} dataKey="name" />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey={dataKey} name={name} stroke="#0064c5" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
}
