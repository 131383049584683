import * as React from "react";
import { SVGProps } from "react";

const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#00ff6e"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="arrow-up_svg__lucide arrow-up_svg__lucide-arrow-up"
    {...props}
  >
    <path d="m5 12 7-7 7 7M12 19V5" />
  </svg>
);

export default SvgArrowUp;
