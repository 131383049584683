import React, { useRef, useState, useEffect } from "react";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft, SvgChevronRight } from "~/components/Icon/icons";
import { Skeleton } from "~/components/Skeleton";
import { CardHeader } from "~/core/modules/DeprecatedBooking/components/Card";

type CarrosselListProps<T extends { id: string | number }> = {
  isLoading: boolean;
  data?: T[] | null;
  render: (item: T) => React.ReactNode;
  selectedId?: string;
};

export default function CarrosselList<T extends { id: string | number }>({
  isLoading,
  data,
  render,
  selectedId,
}: CarrosselListProps<T>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const updateScrollState = () => {
    if (!containerRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    setIsAtStart(scrollLeft <= 0);
    setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - 1);
  };

  useEffect(() => {
    updateScrollState();
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener("scroll", updateScrollState);
    return () => container.removeEventListener("scroll", updateScrollState);
  }, []);

  useEffect(() => {
    if (!containerRef.current || !selectedId) return;

    const selectedElement = containerRef.current.querySelector(`[data-id='${selectedId}']`);
    if (selectedElement) {
      containerRef.current.scrollTo({
        left: (selectedElement as HTMLElement).offsetLeft,
        behavior: "smooth",
      });
    }
  }, [selectedId]);

  const handleArrowClick = (direction: "left" | "right") => {
    if (!containerRef.current) return;

    const scrollAmount = 300;
    containerRef.current.scrollBy({
      left: direction === "right" ? scrollAmount : -scrollAmount,
      behavior: "smooth",
    });
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    const container = containerRef.current;
    if (!container) return;

    const startX = event.pageX - container.offsetLeft;
    const scrollLeft = container.scrollLeft;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      const x = moveEvent.pageX - container.offsetLeft;
      const walk = (x - startX) * 1;
      container.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  if (isLoading) {
    return (
      <Flex
        gap="6"
        css={{
          width: "100%",
          overflow: "auto",
        }}
      >
        {Array.from({ length: 3 }).map((_, index) => (
          <Card
            key={index}
            css={{
              minWidth: "400px",
            }}
          >
            <CardHeader>
              <Flex
                gap="2"
                justify="between"
                css={{
                  width: "100%",
                }}
              >
                <Skeleton css={{ width: "100px", height: "20px" }} />
                <Skeleton css={{ width: "50px", height: "20px" }} />
              </Flex>
            </CardHeader>
            <CardBody>
              <Skeleton css={{ height: "60px" }} />
            </CardBody>
          </Card>
        ))}
      </Flex>
    );
  }

  return (
    <Flex
      css={{
        maxWidth: "96vw",
        width: "100%",
        position: "relative",
      }}
    >
      {!isAtStart && (
        <Flex
          onClick={() => handleArrowClick("left")}
          css={{
            position: "absolute",
            left: 0,
            zIndex: 1,
            height: "100%",
            p: "$2",
            cursor: "pointer",
            background:
              "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",
          }}
          justify="center"
          align="center"
        >
          <Icon size={"lg"} as={SvgChevronLeft} variant={"dark"} />
        </Flex>
      )}
      <Flex
        ref={containerRef}
        gap="1"
        css={{
          width: "100%",
          height: "100%",
          overflowX: "auto",
          cursor: "grab",
          "&:active": {
            cursor: "grabbing",
          },
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        onMouseDown={handleMouseDown}
      >
        {data?.map((item, index) => (
          <Flex key={index} data-id={item.id}>
            {render(item)}
          </Flex>
        ))}
      </Flex>
      {!isAtEnd && (
        <Flex
          onClick={() => handleArrowClick("right")}
          css={{
            position: "absolute",
            right: 0,
            zIndex: 1,
            height: "100%",
            p: "$2",
            cursor: "pointer",
            background:
              "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",
          }}
          justify="center"
          align="center"
        >
          <Icon size={"lg"} as={SvgChevronRight} variant={"dark"} />
        </Flex>
      )}
    </Flex>
  );
}
