import { useCallback } from "react";
import { OrderVehicle } from "~/application/types";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Icon } from "~/components/Icon";
import { SvgCarDoor, SvgCopy, SvgManual, SvgSnowflake } from "~/components/Icon/icons";
import { Image } from "~/components/Image";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { Label } from "~/components/Typography";
import { OrderVehicleDialogDetails } from "../OrderVehicleDialog";
import { ViolatedPoliciesButton } from "../ViolatedPoliciesButton/ViolatedPoliciesButton";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { IconButton } from "~/components/IconButton";
import { copyToClipboard } from "~/application/utils/navigator-functions";
import useMobile from "../../hooks/useMobile";

export interface OrderVehicleCardProps {
  data: OrderVehicle;
}

export function OrderVehicleCard({ data }: OrderVehicleCardProps) {
  const handleOpenOrderDetail = useCallback(() => {
    dialogService.showDialog(<OrderVehicleDialogDetails data={data} />);
  }, []);

  const isMobile = useMobile();

  const onCopyText = useCallback((value: string) => copyToClipboard(value), []);

  const renderCopyTracker = useCallback(
    (tracker: string) => (
      <Tooltip content={<TooltipLabel>Copiar localizador</TooltipLabel>}>
        <IconButton onClick={() => onCopyText(tracker)} size="md">
          <Icon as={SvgCopy} />
        </IconButton>
      </Tooltip>
    ),
    [onCopyText]
  );

  return (
    <Card
      css={{
        overflow: "visible",
        "@mxlg": {
          border: "0",
        },
      }}
    >
      <Flex direction={{ "@mxlg": "columnReverse" }} as={CardBody} justify={"between"} gap="6">
        <Flex
          direction="column"
          gap="4"
          justify="between"
          css={{
            maxWidth: "950px",
            width: "100%",
          }}
        >
          <Button
            variant="tertiary"
            onClick={handleOpenOrderDetail}
            css={{
              height: "$6",
              fontSize: "$sm",
              p: 10,
              "@lg": {
                display: "none",
              },
            }}
          >
            Ver detalhes
          </Button>
          <Flex direction="column" gap="4">
            <Flex align="center" justify="between" gap="2">
              <Flex
                gap="2"
                direction={{ "@mxlg": "column" }}
                justify="between"
                css={{ width: "100%" }}
              >
                <Flex direction="column" gap="1">
                  <Text size="5" css={{ fw: "700" }}>
                    {data.vehicleGroup}
                  </Text>

                  <Label style={{ lineHeight: 1.5 }}>{data.vehicleDescription}</Label>
                </Flex>
                {!!data.violatedPolicies?.length && (
                  <ViolatedPoliciesButton data={data.violatedPolicies} />
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex gap="3" wrap="wrap">
            {data.vehicleAirConditioner && (
              <Tag
                variant="neutral-light"
                css={{
                  "@mxlg": {
                    height: "$7",
                  },
                }}
              >
                <Icon as={SvgSnowflake} />
                <Text>Ar condicionado</Text>
              </Tag>
            )}

            <Tag
              variant="neutral-light"
              css={{
                "@mxlg": {
                  height: "$7",
                },
              }}
            >
              <Icon as={SvgManual} />
              <Text>{data.vehicleTransmissionType}</Text>
            </Tag>

            <Tag
              variant="neutral-light"
              css={{
                "@mxlg": {
                  height: "$7",
                },
              }}
            >
              <Icon as={SvgCarDoor} />
              <Text>{data.vehicleDoorsNumbers}</Text>
            </Tag>
          </Flex>
          <Flex
            direction={"column"}
            gap="3"
            css={{
              maxWidth: "750px",
            }}
          >
            <Text fw={"700"}>{data?.coverage?.type}</Text>
            <Label>{data?.coverage?.description}</Label>
          </Flex>
        </Flex>

        <Flex
          align={{ "@initial": "center" }}
          justify={{ "@initial": "center" }}
          css={{
            width: "100%",
            maxWidth: !isMobile ? "400px" : "100%",
          }}
          direction={"column"}
        >
          <Flex
            justify={"end"}
            css={{
              width: "100%",
            }}
          >
            {data?.tracker && (
              <Flex direction={"column"}>
                <Text fw="700">Localizador</Text>
                <Flex css={{ height: "fit-content" }} align="center">
                  <Text variant="darkest">{data.tracker}</Text>
                  {renderCopyTracker(data.tracker)}
                </Flex>
              </Flex>
            )}
          </Flex>
          <Image
            src={data.vehicleImage}
            css={{
              maxWidth: "300px",
            }}
          />
        </Flex>
      </Flex>
    </Card>
  );
}

OrderVehicleCard.displayName = "OrderVehicleCard";
