import { Fragment } from "react";
import { City, Customer, State } from "~/application/types";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Container } from "~/components/Container";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Tab, TabBar, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { CustomerForm } from "../../components/CustomerForm";
import { CUSTOMER_MENU_TABS, CustomerTab } from "../../utils";
import { UseFormReturn } from "react-hook-form";
import { Card } from "~/components/Card";
import { CustomerSchema } from "../CustomerPage";

export interface CreateCustomerContainerProps {
  defaultData?: Customer;
  isCreatingCustomer: boolean;
  customerHookForm: (defaultData?: Customer | undefined) => UseFormReturn<CustomerSchema, any>;
  onCreateCustomer: (customer: Customer) => void;
  fetchCitiesByState: (state: State) => Promise<City[]>;
}

export function CreateCustomerContainer({
  onCreateCustomer,
  defaultData,
  isCreatingCustomer,
  customerHookForm,
  fetchCitiesByState,
}: CreateCustomerContainerProps) {

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <Tooltip content={<TooltipLabel>Voltar para empresas</TooltipLabel>}>
            <Link to="/configuracoes/empresas">
              <IconButton size="md">
                <Icon as={SvgChevronLeft} css={{ "@mxlg": { fill: "#fff" } }} />
              </IconButton>
            </Link>
          </Tooltip>

          <AppBarHeading title="Nova Empresa" css={{ ta: "center" }} />
        </AppBarContainer>
      </AppBar>

      <Container css={{ py: "$10", "@mxlg": { p: "$5" } }}>
        <Tabs value={CustomerTab.INFORMATION} vertical>
          <Row
            gap="6"
            css={{
              py: "$10",
              "@mxlg": {
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            <Col sz="3" css={{ "@mxlg": { display: "none" } }}>
              <Card>
                <TabBar>
                  {CUSTOMER_MENU_TABS.map(({ title, id }) => (
                    <Tab disabled={id !== CustomerTab.INFORMATION} id={id} value={id} key={id}>
                      <Text>{title}</Text>
                    </Tab>
                  ))}
                </TabBar>
              </Card>
            </Col>

            <Col sz={{ "@lg": "9" }}>
              <CustomerForm
                defaultData={defaultData}
                customerHookForm={customerHookForm}
                onSubmit={onCreateCustomer}
                isSubmitting={isCreatingCustomer}
                fetchCitiesByState={fetchCitiesByState}
              />
            </Col>
          </Row>
        </Tabs>
      </Container>
    </Fragment>
  );
}
