import { useMemo } from "react";
import {
  FilterData,
  Purchase,
  RouteAnalysis,
  TopRoutes,
  TotalSpent,
} from "~/application/types/entities/CustomerBI.type";
import { SvgArrowDown, SvgArrowUp } from "~/components/Icon/icons";
import { displayDate } from "~/utils/date.utils";
import { asCurrency } from "~/utils/mask.utils";

export type ConvertedDataItem = {
  name: string;
  sales: number;
  amt: number;
};

type ConvertedData = {
  year: ConvertedDataItem[];
  month: ConvertedDataItem[];
  threeMonths: ConvertedDataItem[];
  week: ConvertedDataItem[];
  twoMonths: ConvertedDataItem[];
  day: ConvertedDataItem[];
};

export const topRoutes = [
  { header: "Origem", value: (route?: TopRoutes) => route?.departure, isNumeric: true },
  { header: "Destino", value: (route?: TopRoutes) => route?.arrival, isNumeric: false },
  {
    header: "Ticket médio",
    isNumeric: true,
    value: (route?: TopRoutes) => asCurrency(route?.average_ticket || 0),
  },
  {
    header: "Valor",
    isNumeric: true,
    value: (route?: TopRoutes) => asCurrency(route?.value || 0),
  },
];

export const secondTablebLastPurchase = [
  {
    header: "Ant.",
    isNumeric: true,
    value: (purchase?: RouteAnalysis) => purchase?.averageTicket,
  },
  {
    header: "Freq.",
    isNumeric: false,
    value: (purchase?: RouteAnalysis) => purchase?.frequency,
  },
  {
    header: "Ticket M.",
    isNumeric: true,
    value: (purchase?: RouteAnalysis) => purchase?.averageTicket,
  },
  { header: "Share", value: (purchase?: RouteAnalysis) => purchase?.share, isNumeric: true },
  {
    header: "Valor",
    isNumeric: true,
    value: (purchase?: RouteAnalysis) => asCurrency(purchase?.value || 0),
  },
];

export const filters = [
  { type: "year", value: "12 meses" },
  { type: "day", value: "24 horas" },
  { type: "month", value: "30 dias" },
  { type: "three-months", value: "3 meses" },
  { type: "week", value: "7 dias" },
];

export const getPercentValueIcon = (isUp: boolean) => {
  switch (isUp) {
    case false:
      return SvgArrowUp;
    case true:
      return SvgArrowDown;
  }
};

export function mapToEntries(totalSpent?: TotalSpent[]) {
  return totalSpent?.map((spent) => ({
    type: spent?.type,
    value: spent?.value,
    data: spent?.data,
  }));
}
export function convertChartData(jsonData?: TotalSpent[]): ConvertedData {
  const result: ConvertedData = {
    year: [],
    month: [],
    threeMonths: [],
    twoMonths: [],
    week: [],
    day: [],
  };

  jsonData?.forEach((entry) => {
    const period = entry.type;
    const data = entry?.data?.map((item) => ({
      name:
        period === "day"
          ? `${item.hour}`
          : period === "week" && entry.value === 7
          ? `${item.day}`
          : period === "month" && entry.value === 30
          ? `${item.month} / ${item.day} / ${displayDate(item.date)}`
          : period === "three-months" && entry.value === 3
          ? `${item.month}`
          : period === "year"
          ? `${item.month}`
          : "",
      sales: item.value,
      amt: item.value,
    }));

    if (period === "year" && entry.value === 12) {
      result.year = data;
    } else if (period === "three-months" && entry.value === 3) {
      result.threeMonths = data;
    } else if (period === "two-months") {
      result.twoMonths = data;
    } else if (period === "month" && entry.value === 30) {
      result.month = data;
    } else if (period === "week" && entry.value === 7) {
      result.week = data;
    } else if (period === "day") {
      result.day = data;
    }
  });

  return result;
}
