export enum AirlineCode {
  GOL = "gws",
  LATAM = "latam",
  AZUL = "azul",
  AMERICAN_AIRLINES = "aa",
  VOEPASS = "2z",
  XL_AIRWAYS_FRANCE = "xl",
  UNITED_AIRLINES = "United-Airlines",
  TURKISH_AIRLINES = "Turkish-Airlines",
  KLM = "KLM",
  IBERIA = "Iberia",
  AIR_FRANCE = "Air-France",
  TAP = "TAP",
  AT = "AT",
  LUFTHANSA = "Lufthansa",
  INTERNATIONAL_AMERICAN_AIRLINES = "American-Airlines",
  AIR_CANADA = "Air-Canada",
  BRITISH_AIRWAYS = "British-Airways",
  DELTA_AIRLINES = "Delta-Airlines",
  AVIANCA = "Avianca",
}
