import { PolicyParamsSlug } from "~/application/types";

export const mapSlugViolatedPoliciesType = (data: PolicyParamsSlug) => {
  switch (data) {
    case PolicyParamsSlug.FIXED_VALUE:
      return "Valor fixo";
    case PolicyParamsSlug.LEAD_TIME:
      return "Dias de antecedência";
    case PolicyParamsSlug.LOWER_FARE:
      return "Menor taxa";
    case PolicyParamsSlug.FIXED_VALUE_FOR_SINGLE_ROOM:
      return "Valor fixo por quarto individual";
    case PolicyParamsSlug.FIXED_VALUE_FOR_DOUBLE_ROOM:
      return "Valor fixo por quarto duplo";
    case PolicyParamsSlug.FIXED_VALUE_FOR_TRIPLE_ROOM:
      return "Valor fixo por quarto triplo";
  }
};
