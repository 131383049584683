import { styled } from "@stitches/react";
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from "recharts";
import type { LeadTime } from "~/application/types/entities/CustomerBI.type";

const ChartWrapper = styled("div", {
  width: "100%",
  height: "500px",
  padding: "20px",
  backgroundColor: "white",
  borderRadius: "8px",
});

const Title = styled("h2", {
  fontSize: "16px",
  fontWeight: "500",
  color: "#111",
  marginBottom: "24px",
});

export default function MonthlyChart({ data }: { data?: LeadTime[] }) {
  return (
    <ChartWrapper>
      <Title>Média Antecedência em Dias por Mês</Title>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart data={data} layout="vertical" margin={{ top: 5, right: 50, left: 40, bottom: 5 }}>
          <XAxis type="number" hide />
          <YAxis
            type="category"
            dataKey="month"
            axisLine={false}
            tickLine={false}
            tick={{ fill: "#111", fontSize: 14 }}
            width={40}
          />
          <Bar
            dataKey="value"
            fill="#0066CC"
            radius={[4, 4, 4, 4]}
            label={({ x, y, width, height, value }) => {
              const padding = 5;
              const isTextInsideBar = width > 50; 
              const labelX = isTextInsideBar ? x + width - padding : x + width + padding;
              const labelY = y + height / 2;

              return (
                <g>
                  <text
                    x={labelX}
                    y={labelY}
                    fill={isTextInsideBar ? "#fff" : "#000"} 
                    textAnchor={isTextInsideBar ? "end" : "start"} 
                    dominantBaseline="central"
                    fontSize="14px"
                  >
                    {`${Math.round(value)} dias`}
                  </text>
                </g>
              );
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
}
