import {
  AirwayWithCheapestFlightsItemType,
  ApprovalTypeEnum,
  OrderItemsWithChangedPrice,
  RoadOrderWithCheapestOptionsType,
  type Approver,
  type CustomerOrder,
  type Order,
  type OrderStatus,
  type OrderTaxCancellation,
  type RemakeSearchData
} from "~/application/types";
import { api } from "~/infrastructure/api";
import {
  AirwayWithCheapestFlightsItemTypeDTO,
  ApproverDTO,
  OrderDTO,
  OrderItemsWithChangedPriceDTO,
  RemakeSearchDataDTO,
  RoadOrderWithCheapestOptionsTypeDTO
} from "~/infrastructure/api/dtos";
import {
  mapAirwayWithCheapestFlightsItemDTO,
  mapApproverDTO,
  mapOrderDTO,
  mapRoadOrderWithCheapestOptionsTypeDTO
} from "~/infrastructure/api/mappers";
import { mapOrderItemsPriceChangeDTO } from "~/infrastructure/api/mappers/mapOrderItemsPriceChangeDTO";
import { mapOrderTaxCancellation } from "~/infrastructure/api/mappers/mapOrderTaxCancellation";
import { mapRemakeSearchDataDTO } from "~/infrastructure/api/mappers/mapRemakeSearchDataDTO";
import { downloadBlob } from "~/utils";
import { getAuthToken } from "~/utils/auth.utils";
import type { IOrderService } from "./IOrderService";
import { ICreateOrderData } from "./types";

export enum ApprovalTypeToApprover {
  COST_CENTER = 'cost-centers',
  GROUP = 'groups',
  PROJECT = 'projects',
  PHASE = 'phases',
}

export class OrderService implements IOrderService {
  async getStatus(id: string): Promise<OrderStatus> {
    return await api
      .get<{ status: OrderStatus }>(`/orders/${id}/status`)
      .then(({ data }) => data.status);
  }

  async findById(id: string): Promise<Order> {
    return await api
      .get<{ data: OrderDTO }>(`/orders/${id}`)
      .then(({ data }) => mapOrderDTO(data.data));
  }

  async getOrderItemsPriceChange(id: string): Promise<OrderItemsWithChangedPrice> {
    const url = `/orders/${id}/items/price`;
    return await api
      .get<
        OrderItemsWithChangedPriceDTO
      >(url)
      .then(({ data }) => mapOrderItemsPriceChangeDTO(data))
  }

  async issue(id: string): Promise<void> {
    return await api.patch(`/orders/${id}/checkout`).then(({ data }) => data);
  }

  async paymentMethod(): Promise<{ description: string; uuid: string }[]> {
    return await api.get(`/manual-emission-payment-methods`).then(({ data }) => data.data);
  }

  async manualCancel(id: string): Promise<void> {
    return await api.patch(`/orders/${id}/manual-cancellation`).then(({ data }) => data);
  }

  async returnOrderStatus(id: string): Promise<void> {
    return await api.patch(`/orders/${id}/on-approval`).then(({ data }) => data);
  }

  async cancel(id: string): Promise<void> {
    return await api.post(`/orders/${id}/cancel`).then(({ data }) => data);
  }

  async quote(id: string): Promise<void> {
    return await api.patch(`/orders/${id}/quote`).then(({ data }) => data);
  }

  async rejectQuote(id: string): Promise<void> {
    return await api.patch(`/orders/${id}/reject`).then(({ data }) => data);
  }

  async requestCancellation(id: string): Promise<void> {
    return await api.post(`/orders/${id}/request-cancellation`).then(({ data }) => data);
  }

  async issueOrder(id: string): Promise<void> {
    return await api.post(`/orders/${id}/emissions`).then(({ data }) => data);
  }

  async remakeSearch(orderUuid: string): Promise<RemakeSearchData> {
    return await api
      .get<RemakeSearchDataDTO>(`/orders/${orderUuid}/search`)
      .then(({ data }) => mapRemakeSearchDataDTO(data));
  }

  async taxCancellation(id: string): Promise<OrderTaxCancellation> {
    return await api
      .get(`/orders/${id}/cancellation-conditions`)
      .then(({ data }) => mapOrderTaxCancellation(data));
  }

  async create(data: ICreateOrderData): Promise<Order> {
    return await api
      .post<{ data: OrderDTO }>("/orders", {
        customer_uuid: data.customerId,
        issuer_uuid: data.issuerId,
        approver_uuid: data.approverId,
        reason_trip_uuid: data.reasonTripId,
        justification_uuid: data.justificationId,
        travelers: data.travelersIds,
      })
      .then(({ data }) => mapOrderDTO(data.data));
  }

  async notifyIssuerThatOrderExpired(orderId: string): Promise<void> {
    return await api.post(`/orders/${orderId}/notify-issuer`).then(({ data }) => data);
  }

  async rejectCancellation(orderId: string): Promise<void> {
    return await api.patch(`/orders/${orderId}/reject/cancelling`).then(({ data }) => data);
  }

  async findApprover(data:{uuid?: string, approvalType?:ApprovalTypeToApprover}): Promise<Approver[]> {
    const{ approvalType, uuid} = data
    return await api
      .get(`customers/${approvalType}/${uuid}/approvers`)
      .then(({ data }) => data.data.map((approver: ApproverDTO) => ({
        uuid: approver.uuid,
        level: approver.level,
        selfApprover: approver.self_approver,
        name: approver.name,
      })));
  }

  async downloadVoucher(order: CustomerOrder | Order): Promise<void> {
    return await fetch(`${api.baseUrl}/orders/${order.uuid}/download/pdf`, {
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => downloadBlob(blob, `voucher_${order.orderNumber}.pdf`));
  }

  async getCheapestFlights(itemId: string): Promise<AirwayWithCheapestFlightsItemType> {
    return await api
      .get<AirwayWithCheapestFlightsItemTypeDTO>(`/orders/airway/${itemId}/cheapest-flight`)
      .then(({ data }) => mapAirwayWithCheapestFlightsItemDTO(data));
  }

  async getCheapestRoads(itemId: string): Promise<RoadOrderWithCheapestOptionsType> {
    return await api
      .get<RoadOrderWithCheapestOptionsTypeDTO>(`/orders/road/${itemId}/cheapest-road`)
      .then(({ data }) => mapRoadOrderWithCheapestOptionsTypeDTO(data));
  }

  async editExpirationDate(data: Order): Promise<void> {
    const body = {
      expires_at: data.expirationDate,
    };

    await api.patch(`/orders/${data.uuid}/offline-hotel/expiration`, body);
  }
}
