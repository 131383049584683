import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";

import {
  FlightSegment,
  OrderAirwaySegment,
  OrderStatus,
  Passenger,
  SegmentType,
  UnflownAirway,
} from "~/application/types";
import { StringUtils } from "~/application/utils";
import { DateFormats, timestampInterval } from "~/application/utils/date-functions";
import { Alert } from "~/components/Alert";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Divider } from "~/components/Divider";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgAlertTriangle,
  SvgCheck,
  SvgClose,
  SvgLuggage,
  SvgRemove,
} from "~/components/Icon/icons";
import { Text } from "~/components/Text";
import { H5, Label } from "~/components/Typography";
import { TrackDots } from "~/presentation/shared/components/Track";
import { ViolatedPoliciesButton } from "~/presentation/shared/components/ViolatedPoliciesButton/ViolatedPoliciesButton";
import { useCopyTracker } from "~/presentation/shared/hooks/useCopyTracker";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { useOrder } from "~/presentation/shared/hooks/useOrder";
import { getAirlineUrl } from "~/presentation/shared/utils";
import * as DateUtils from "~/utils/date.utils";

export interface OrderAirwayCardProps {
  data: OrderAirwaySegment;
  onOpenDetails?: () => void;
  onOpenAddTrackerDialog: () => void;
}

export type AirwayPassengerType = Passenger & {
  unflownAirway?: UnflownAirway;
};

export function OrderAirwayCard({
  data,
  onOpenDetails,
  onOpenAddTrackerDialog,
}: OrderAirwayCardProps) {
  const navigate = useNavigate();
  const { orderId } = useParams() as { orderId: string };
  const { order } = useOrder();

  const scales = data.segments.length - 1;

  const displayText = useCallback(
    (scale?: number) => {
      if (!scale) return StringUtils.formatSentence([scales, "conexão", "conexões"]);
      return StringUtils.formatSentence([scale, "conexão", "conexões"]);
    },
    [data]
  );

  const lastIndex = data.segments.findIndex((ar) => ar.type === SegmentType.ARRIVAL) || 1;

  const firstIndex = data.segments.findLastIndex((ar) => ar.type === SegmentType.DEPARTURE) || 1;
  const flightDurationDeparture = timestampInterval(
    data.segments.at(0)?.departureFlightInfo.dateTime as Date,
    data.segments.at(lastIndex)?.arrivalFlightInfo.dateTime as Date
  );

  const flightDurationArrival = timestampInterval(
    data.segments.at(firstIndex)?.departureFlightInfo.dateTime as Date,
    data.segments.at(data.segments.length - 1)?.arrivalFlightInfo.dateTime as Date
  );

  const connectionsAirportIatas = data.segments
    .slice(0, -1)
    .map((segment) => segment.arrivalFlightInfo.airportIata);

  const isInternationalCombined =
    data.isInternational && data.segments.some((segment) => segment.type === SegmentType.ARRIVAL);

  const goFlight = data.segments[0].departureFlightInfo;
  const returnFlight = data.segments?.at(data.segments?.length - 1)?.arrivalFlightInfo;
  const airline = data.segments[0].airline;

  const isMobile = useMobile();
  const canShowTracker = data.tracker;

  const goFlightSeparator = goFlight?.airport.split("-");
  const returnFlightSeparator = returnFlight?.airport.split("-");

  const { renderCopyTracker } = useCopyTracker();

  function hasAirportChangeInConnections(segments: FlightSegment[]): boolean {
    return segments.some(
      (segment, index) =>
        index < segments.length - 1 &&
        segment.arrivalFlightInfo.airportIata !==
          segments[index + 1].departureFlightInfo.airportIata
    );
  }

  function navigateToCheapestFlights() {
    navigate(
      `/pedidos/${orderId}/voos-mais-baratos?${
        data.flightType === "departure" ? "departureId" : "arrivalId"
      }=${data.uuid}`
    );
  }

  const showAirportChangeAlert =
    data.segments.length > 1 && hasAirportChangeInConnections(data.segments);

  const showAlertUnflownAirway =
    data?.passengers?.some((passenger: AirwayPassengerType) => passenger?.unflownAirway?.uuid) &&
    order?.status === OrderStatus.QUOTING;

  const canShowAddTrackerButton = !data.tracker && order?.status === OrderStatus.OPEN;

  return (
    <>
      {showAirportChangeAlert && (
        <Alert variant="warning">
          <Flex direction="column" gap="3">
            <Text variant="warning-dark">Atenção: Troca de aeroporto necessária</Text>
            <Text css={{ lineHeight: "$6" }}>
              Você precisará se deslocar entre diferentes aeroportos durante sua conexão. Planeje
              tempo suficiente para o trajeto.
            </Text>
          </Flex>
        </Alert>
      )}

      <Col>
        <Card>
          <Flex css={{ p: "$4" }} gap="4" align="center">
            <Col>
              <CompanyLogo src={getAirlineUrl(slugify(airline))} size={"2xl"} />
            </Col>
            {data.expiresAt.toString().toLowerCase() !== "invalid date" && (
              <Text size="2" variant="darkest" css={{ fw: "600" }}>
                Expira em{" "}
                {DateUtils.displayDate(data.expiresAt, DateUtils.DateFormats.SMALL_DATE_TIME)}
              </Text>
            )}
            {canShowTracker && (
              <>
                <Text variant="darkest" css={{ fw: "600" }}>
                  <Flex align="center">{data.tracker && data.tracker}</Flex>
                </Text>

                {renderCopyTracker(data.tracker as string)}
              </>
            )}
            {canShowAddTrackerButton && (
              <Button
                variant="tertiary"
                title="Adicionar localizador"
                onClick={onOpenAddTrackerDialog}
              >
                Adicionar localizador
              </Button>
            )}
          </Flex>
        </Card>
      </Col>

      {showAlertUnflownAirway && (
        <Alert variant="warning">
          <Flex
            align="center"
            justify="center"
            css={{
              width: "100%",
            }}
            gap="4"
          >
            <Icon as={SvgAlertTriangle} />
            <Text>Solicitação de remarcação de crédito</Text>
          </Flex>
        </Alert>
      )}
      <Col>
        <Card>
          <Flex direction="column" as={CardBody} gap="6">
            <Flex align="start" direction={{ "@mxlg": "column" }} justify="between" gap="6">
              <Flex justify={{ "@mxlg": "between" }} css={{ "@mxlg": { width: "100%" } }}>
                <CompanyLogo src={getAirlineUrl(slugify(airline))} size={"2xl"} />
                {isMobile && (
                  <Button
                    variant="tertiary"
                    css={{
                      "@mxlg": {
                        height: "$5",
                        p: "$2",
                        fontSize: "$sm",
                      },
                    }}
                    onClick={onOpenDetails}
                  >
                    <Text>Ver detalhes</Text>
                  </Button>
                )}
              </Flex>

              <Flex align="center" gap="2" wrap="wrap" css={{ "@mxlg": { width: "100%" } }}>
                {!!data.violatedPolicies?.length && (
                  <ViolatedPoliciesButton data={data.violatedPolicies} />
                )}
                {data.isTheCheapestFlight ? (
                  <Button
                    variant="success-light"
                    css={{ "@mxlg": { width: "100%" } }}
                    onClick={navigateToCheapestFlights}
                  >
                    <Icon as={SvgCheck} />
                    <Text>Fez a melhor escolha</Text>
                  </Button>
                ) : (
                  <Button
                    variant="error-light"
                    css={{ "@mxlg": { width: "100%" } }}
                    onClick={navigateToCheapestFlights}
                  >
                    <Icon as={SvgRemove} />
                    <Text>Melhor opção disponível</Text>
                  </Button>
                )}

                {!isMobile && (
                  <Button
                    variant="tertiary"
                    css={{
                      "@mxlg": {
                        height: "$5",
                        p: "$2",
                        fontSize: "$sm",
                      },
                    }}
                    onClick={onOpenDetails}
                  >
                    <Text>Ver detalhes</Text>
                  </Button>
                )}
              </Flex>
            </Flex>
            {isInternationalCombined && (
              <Flex
                css={{
                  p: "$6",
                }}
                justify="center"
                direction="column"
                gap="3"
              >
                <Flex
                  direction="column"
                  css={{
                    width: "100%",
                  }}
                  gap="5"
                >
                  <Flex>
                    <Flex direction="column" justify="between" gap="2" css={{ flex: "1 0 0%" }}>
                      <Label css={{ fw: 400 }}>Saída</Label>
                      <H5>{data?.segments?.at(0)?.departureFlightInfo.airportIata}</H5>
                      <Text size={{ "@mxxxl": "1" }}>
                        {data.segments?.at(0)?.departureFlightInfo.airport}
                      </Text>
                      <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
                        {DateUtils.displayDate(
                          data.segments?.at(0)?.departureFlightInfo.dateTime as Date,
                          DateFormats.LONG_DATE
                        )}
                      </Text>
                      <Label css={{ fw: 400 }}>
                        {DateUtils.displayDate(
                          data.segments?.at(0)?.departureFlightInfo?.dateTime as Date,
                          DateFormats.SMALL_TIME
                        )}
                      </Label>
                    </Flex>

                    <Flex
                      direction="column"
                      align="center"
                      gap="4"
                      css={{
                        flex: "1 1 0%",
                        width: "$40",
                        "@xl": {
                          ml: "$4",
                        },
                      }}
                    >
                      <Label css={{ fw: 400 }}>Duração</Label>
                      <Text size="3">{flightDurationDeparture}</Text>
                      {scales > 0 &&
                        (isMobile ? (
                          <Box>
                            <Text variant="primary" size="2" css={{ lineHeight: 1.6, fw: 600 }}>
                              {displayText(firstIndex - 1)}
                            </Text>
                            <TrackDots numberOfDots={firstIndex - 1} />
                            <Text size="3" css={{ lineHeight: 1.25 }}>
                              Parada{connectionsAirportIatas.length > 1 && "s"}:{" "}
                              {connectionsAirportIatas.map((iata) => `(${iata}) `)}
                            </Text>
                          </Box>
                        ) : (
                          <>
                            <Box css={{ width: "70px" }}>
                              <TrackDots numberOfDots={firstIndex - 1} />
                              <Text variant="primary" size="2" css={{ lineHeight: "1.6", fw: 600 }}>
                                {displayText(firstIndex - 1)}
                              </Text>
                            </Box>
                            <Text size="3" css={{ lineHeight: 1.25 }}>
                              Parada{connectionsAirportIatas.length > 1 && "s"}:{" "}
                              {connectionsAirportIatas
                                .map((iata) => `(${iata}) `)
                                .slice(0, firstIndex - 1)}
                            </Text>
                          </>
                        ))}
                      <Flex direction="column">
                        <Label css={{ fw: 400 }}>Voo {data.segments[0].flightNumber}</Label>
                      </Flex>
                    </Flex>

                    <Flex align="end" justify="between" direction="column" css={{ flex: "1 0 0%" }}>
                      <Label css={{ fw: 400 }}>Chegada</Label>
                      <H5>{data.segments.at(lastIndex)?.arrivalFlightInfo.airportIata}</H5>
                      <Text size={{ "@mxxxl": "1" }}>
                        {data.segments.at(lastIndex)?.arrivalFlightInfo.airport}
                      </Text>
                      <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
                        {DateUtils.displayDate(
                          data.segments.at(lastIndex)?.arrivalFlightInfo.dateTime as Date
                        )}
                      </Text>
                      <Label css={{ fw: 400 }}>
                        {DateUtils.displayDate(
                          data.segments.at(lastIndex)?.arrivalFlightInfo.dateTime as Date,
                          DateFormats.SMALL_TIME_AM_PM
                        )}
                      </Label>
                    </Flex>
                  </Flex>

                  <Divider />

                  <Flex>
                    <Flex
                      align={{ "@mxxxl": "start" }}
                      justify={"between"}
                      direction="column"
                      css={{ flex: "1 0 0%" }}
                    >
                      <Label css={{ fw: 400 }}>Chegada</Label>
                      <H5>{data.segments.at(firstIndex)?.departureFlightInfo.airportIata}</H5>
                      <Text size={{ "@mxxxl": "1" }}>
                        {data.segments.at(firstIndex)?.departureFlightInfo.airport}
                      </Text>
                      <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
                        {DateUtils.displayDate(
                          data.segments.at(firstIndex)?.departureFlightInfo.dateTime as Date
                        )}
                      </Text>
                      <Label css={{ fw: 400 }}>
                        {DateUtils.displayDate(
                          data.segments.at(firstIndex)?.departureFlightInfo.dateTime as Date,
                          DateFormats.SMALL_TIME_AM_PM
                        )}
                      </Label>
                    </Flex>
                    <Flex
                      direction="column"
                      align={"center"}
                      gap="4"
                      css={{
                        flex: "1 1 0%",
                        width: "$40",
                        "@xl": {
                          ml: "$4",
                        },
                      }}
                    >
                      <Label css={{ fw: 400 }}>Duração</Label>
                      <Text size="3">{flightDurationArrival}</Text>

                      {scales > 0 &&
                        (isMobile ? (
                          <Box>
                            <Text variant="primary" size="2" css={{ lineHeight: 1.6, fw: 600 }}>
                              {displayText(connectionsAirportIatas.length - firstIndex)}
                            </Text>
                            <TrackDots numberOfDots={connectionsAirportIatas.length - firstIndex} />
                            <Text size="3" css={{ lineHeight: 1.25 }}>
                              Parada{connectionsAirportIatas.length > 1 && "s"}:{" "}
                              {connectionsAirportIatas
                                .map((iata) => `(${iata})`)
                                .slice(firstIndex, connectionsAirportIatas.length)}
                            </Text>
                          </Box>
                        ) : (
                          <>
                            <Box css={{ width: "70px" }}>
                              <TrackDots
                                numberOfDots={connectionsAirportIatas.length - firstIndex}
                              />
                              <Text variant="primary" size="2" css={{ lineHeight: "1.6", fw: 600 }}>
                                {displayText(connectionsAirportIatas.length - firstIndex)}
                              </Text>
                            </Box>
                            <Text size="3" css={{ lineHeight: 1.25 }}>
                              Parada{connectionsAirportIatas.length > 1 && "s"}:{" "}
                              {connectionsAirportIatas
                                .map((iata) => `(${iata})`)
                                .slice(firstIndex, connectionsAirportIatas.length)}
                            </Text>
                          </>
                        ))}

                      <Flex direction="column">
                        <Label css={{ fw: 400 }}>
                          Voo {data.segments?.at(firstIndex)?.flightNumber}
                        </Label>
                      </Flex>
                    </Flex>
                    <Flex
                      align={"end"}
                      justify={"between"}
                      direction="column"
                      css={{ flex: "1 0 0%" }}
                    >
                      <Label css={{ fw: 400 }}>Chegada</Label>
                      <H5>{returnFlight?.airportIata}</H5>
                      <Text size={{ "@mxxxl": "1" }}>{returnFlight?.airport}</Text>
                      <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
                        {DateUtils.displayDate(returnFlight?.dateTime as Date)}
                      </Text>
                      <Label css={{ fw: 400 }}>
                        {DateUtils.displayDate(
                          returnFlight?.dateTime as Date,
                          DateFormats.SMALL_TIME_AM_PM
                        )}
                      </Label>
                    </Flex>
                  </Flex>
                </Flex>

                <Flex
                  css={{
                    p: "$2",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  gap="3"
                  direction="column"
                >
                  <Divider
                    css={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "$neutrals-base",
                      alignSelf: "stretch",
                    }}
                  />
                  <Flex justify="between">
                    <Flex align="center" gap="2">
                      <Icon as={SvgLuggage} size="md" />
                      <Text fw="600">Bagagem</Text>
                    </Flex>
                    <Flex direction="column" align="end" gap="1">
                      {data.baggages?.length ? (
                        data.baggages.map(({ amount, included, weight }, index) => (
                          <Text
                            key={index}
                            variant={!index ? "black" : "darkest"}
                            fw={!index ? "500" : "400"}
                            size={!index ? "3" : "2"}
                          >
                            {amount}º bagagem: {weight}kg {included ? "(inclusa)" : ""}
                          </Text>
                        ))
                      ) : (
                        <Icon as={SvgClose} variant="error" />
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            )}
            {!isInternationalCombined && (
              <Flex direction={{ "@mxlg": "column" }}>
                <Flex
                  gap="6"
                  justify={{ "@mxlg": "between" }}
                  css={{
                    flex: "1 1 100%",
                  }}
                >
                  <Flex align="start" direction="column" gap="4">
                    <Label>Saída</Label>

                    {!isMobile ? (
                      <H5>{goFlight?.airport}</H5>
                    ) : (
                      <H5 size="3">{goFlightSeparator[1]}</H5>
                    )}

                    <Text size="3">{DateUtils.displayDate(goFlight?.dateTime as Date)}</Text>

                    <Label>{DateUtils.toHour12(goFlight?.dateTime as Date)}</Label>
                  </Flex>

                  {returnFlight && (
                    <Flex
                      align="start"
                      direction="column"
                      gap="4"
                      css={{
                        ml: "$20",
                        "@mxlg": {
                          ta: "end",
                          ml: "0",
                        },
                      }}
                    >
                      <Label>Chegada</Label>

                      {!isMobile ? (
                        <H5>{returnFlight?.airport}</H5>
                      ) : (
                        <H5 size="3">{returnFlightSeparator?.[1] || returnFlightSeparator?.[0]}</H5>
                      )}

                      <Text size="3" css={{ "@mxlg": { ta: "end" } }}>
                        {DateUtils.displayDate(returnFlight?.dateTime)}
                      </Text>

                      <Label css={{ "@mxlg": { ta: "end", width: "$10" } }}>
                        {DateUtils.toHour12(returnFlight.dateTime)}
                      </Label>
                    </Flex>
                  )}
                </Flex>
                <Flex
                  justify={{ "@mxlg": "between" }}
                  css={{
                    ml: "$20",
                    flex: "1 1 50%",
                    "@mxlg": {
                      mt: "$5",
                      ml: "0",
                    },
                  }}
                >
                  <Flex align="start" direction="column" gap="4">
                    <Label>Duração</Label>

                    <Label>
                      {DateUtils.timeInterval(goFlight.dateTime, returnFlight?.dateTime as Date)}
                    </Label>
                  </Flex>
                  <Flex
                    align="start"
                    direction="column"
                    gap="4"
                    css={{
                      flex: "1 1 50%",
                      ml: "$16",
                    }}
                  >
                    <Label>Tarifa</Label>
                    <Label>{data.familyFare}</Label>
                  </Flex>
                </Flex>
              </Flex>
            )}
            {data.isInternational && !isInternationalCombined && (
              <Flex
                css={{
                  p: "$2",
                  width: "100%",
                  cursor: "pointer",
                }}
                gap="3"
                direction="column"
              >
                <Divider
                  css={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "$neutrals-base",
                    alignSelf: "stretch",
                  }}
                />
                <Flex justify="between">
                  <Flex align="center" gap="2">
                    <Icon as={SvgLuggage} size="md" />
                    <Text fw="600">Bagagem</Text>
                  </Flex>
                  <Flex direction="column" align="end" gap="1">
                    {data.baggages?.length ? (
                      data.baggages.map(({ amount, included, weight }, index) => (
                        <Text
                          key={index}
                          variant={!index ? "black" : "darkest"}
                          fw={!index ? "500" : "400"}
                          size={!index ? "3" : "2"}
                        >
                          {amount}º bagagem: {weight}kg {included ? "(inclusa)" : ""}
                        </Text>
                      ))
                    ) : (
                      <Icon as={SvgClose} variant="error" />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Card>
      </Col>
    </>
  );
}

OrderAirwayCard.displayName = "OrderAirwayCard";
