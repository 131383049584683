import { FC } from "react";

import { TravelerListItemProps } from "./types";
import { DateUtils } from "~/application/utils";
import { Card } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { Col } from "~/components/Grid";

export const TravelerListItem: FC<TravelerListItemProps> = ({ data }) => {
  const date = data.dateBirth;

  return (
    <Card>
      <Flex
        css={{
          borderRadius: "$md",
          backgroundColor: "$neutrals-white",
          width: "100%",
          p: "$3 $6 $3 $6",
          "@mxmd": {
            p: 0,
          },
        }}
      >
        <Flex gap="4" direction="column" css={{ width: "100%" }}>
          <Flex
            justify="between"
            direction={{ "@mxmd": "column" }}
            gap="4"
            css={{
              p: "$3",
              "@mxlg": {
                p: "$3",
              },
            }}
          >
            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex direction="column" gap="2">
                <Text size="2" variant="dark" css={{ fw: "500", mb: "$1" }}>
                  Nome
                </Text>
                <Text size="3" css={{ fw: "600" }}>
                  {data.fullName}
                </Text>
              </Flex>
            </Col>

            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex direction="column" gap="2">
                <Text size="2" variant="dark" css={{ fw: "500", mb: "$1" }}>
                  CPF
                </Text>
                <Text size="3" css={{ fw: "600", color: "#727272" }}>
                  {data.cpf}
                </Text>
              </Flex>
            </Col>

            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex direction="column" gap="2">
                <Text size="2" variant="dark" css={{ fw: "500", mb: "$1" }}>
                  RG
                </Text>
                <Text size="3" css={{ fw: "600", color: "#727272", wordBreak: "break-word" }}>
                  {data.rg}
                </Text>
              </Flex>
            </Col>

            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex direction="column" gap="2">
                <Text size="2" variant="dark" css={{ fw: "500", mb: "$1" }}>
                  Data de Nascimento
                </Text>
                <Text size="3" css={{ fw: "600", color: "#727272" }}>
                  {DateUtils.smallDateFormat(date)}
                </Text>
              </Flex>
            </Col>
            <Col
              sz="2"
              css={{
                "@mxmd": {
                  width: "100%",
                },
              }}
            >
              <Flex direction="column" gap="2">
                <Text size="2" variant="dark" css={{ fw: "500", mb: "$1" }}>
                  Número do bilhete
                </Text>
                <Text size="3" css={{ fw: "600", color: "#727272" }}>
                  {data.ticket}
                </Text>
              </Flex>
            </Col>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
};

TravelerListItem.displayName = "TravelerListItem";
