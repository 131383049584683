import { Agency, City, ExpenseCategory, State } from "~/application/types";
import {
  AppBar,
  AppBarContainer,
  AppBarHeading,
} from "~/components/AppBar";
import { Card } from "~/components/Card";
import { Container } from "~/components/Container";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronLeft } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Link } from "~/components/Link";
import { Skeleton } from "~/components/Skeleton";
import { Tab, TabBar, TabContent, Tabs } from "~/components/Tabs";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Fragment } from "react";
import { AgencyTab, AGENCY_MENU_TABS } from "../../utils";
import {
  TabAgencyEmployees,
  TabAgencyEmployeesProps,
} from "./views/AgencyEmployee/TabAgencyEmployees";
import { TabAgencyInformation } from "./views/TabAgencyInformation";
import { ExpenseCategoriesContainer as TabExpenseCategories } from "~/core/modules/ExpenseCategory/pages/ExpenseCategoriesPage";
import { ExpenseCategoriesState } from "./types";

export interface AgencyContainerProps {
  isLoading: boolean;
  activeTab: AgencyTab;
  agency: Agency | undefined;
  expenseCategoriesState: ExpenseCategoriesState;
  agencyEmployeesState: TabAgencyEmployeesProps;
  fetchCitiesByState: (state: State) => Promise<City[]>;
  onUpdateAgency: (agency: Agency) => void;
}

export function AgencyContainer({
  isLoading,
  activeTab,
  agency,
  agencyEmployeesState,
  expenseCategoriesState,
  fetchCitiesByState,
  onUpdateAgency,
}: AgencyContainerProps) {
  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <Tooltip content={<TooltipLabel>Voltar para agências</TooltipLabel>}>
            <Link to="/configuracoes/agencias">
              <IconButton size="md">
                <Icon as={SvgChevronLeft} />
              </IconButton>
            </Link>
          </Tooltip>

          <AppBarHeading
            title={
              agency?.companyName || (
                <Skeleton variant="text-6" style={{ maxWidth: 360 }} />
              )
            }
          />
        </AppBarContainer>
      </AppBar>

      <Container css={{ py: "$10" }}>
        <Tabs value={activeTab} vertical>
          <Row gap="6">
            <Col sz="3">
              <Card>
                <TabBar>
                  {AGENCY_MENU_TABS.map(({ title, id }) => (
                    <Link to={`#${id}`} title={title} key={id}>
                      <Tab id={id} value={id}>
                        <Text>{title}</Text>
                      </Tab>
                    </Link>
                  ))}
                </TabBar>
              </Card>
            </Col>

            <Col sz="9">
              <TabContent value={AgencyTab.INFORMATION}>
                <TabAgencyInformation
                  data={agency}
                  isLoading={isLoading}
                  fetchCitiesByState={fetchCitiesByState}
                  onUpdateAgency={onUpdateAgency}
                />
              </TabContent>
              <TabContent value={AgencyTab.EMPLOYEES}>
                <TabAgencyEmployees {...agencyEmployeesState} />
              </TabContent>

              <TabContent value={AgencyTab.EXPENSE_CATEGORIES}>
                <TabExpenseCategories {...expenseCategoriesState} />
              </TabContent>
            </Col>
          </Row>
        </Tabs>
      </Container>
    </Fragment>
  );
}
