import { CostCenterData, IssuerData } from "~/application/types/entities/CustomerBI.type";
import { Box } from "~/components/Box";
import { Card } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Text } from "~/components/Text";
import { H4 } from "~/components/Typography";
import { asCurrency } from "~/utils/mask.utils";

export function IssuerResume({ issuerResume }: { issuerResume?: IssuerData[] }) {
  return (
    <>
      <Card css={{ p: "$5" }}>
          <H4>Valor por solicitante</H4>
        <Box css={{ py: "$10" }}>
          {issuerResume?.map((issuer) => (
            <>
              <Flex
                justify="between"
                css={{
                  py: "$4",
                  borderRadius: "$2",
                  alignItems: "center",
                  borderBottom: "1px solid $neutrals-lightest",
                }}
              >
                <Flex align="center" gap="4">
                  <Flex gap="1" direction="column" justify="center">
                    <Text fw="600">{issuer.name}</Text>
                    <Text css={{ fontSize: "$sm", color: "$neutrals-darkest" }}>
                      Último pedido: {issuer.lastOrderDate}
                    </Text>
                  </Flex>
                </Flex>
                <Text fw="700" css={{ color: "$success-base" }}>
                  {asCurrency(issuer.value)}
                </Text>
              </Flex>
            </>
          ))}
        </Box>
      </Card>
    </>
  );
}
