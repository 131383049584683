import { Fragment } from "react";
import { AppBar, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { TabContent, Tabs } from "~/components/Tabs";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { MenuTabs } from "~/presentation/shared/components/MenuTabs";
import { TabShoppingReport } from "./components/TabShoppingReport";
import { TabShoppingReportProps } from "./components/TabShoppingReport/types";
import { CUSTOMER_REPORTS_MENU_TABS, CustomerReportTab } from "./utils";

type CustomerReportsContainerProps = {
  activeTab: CustomerReportTab;
} & TabShoppingReportProps;

export function CustomerReportsContainer({
  onSubmit,
  onSearchName,
  activeTab,
  isLoadingBranches,
  isLoadingApprovers,
  isLoadingCostCenters,
  isLoadingPassengers,
  isLoadingIssuers,
  passengers,
  issuers,
  costCenters,
  approvers,
  branches,
}: CustomerReportsContainerProps) {
  const { contexts } = useUser();
  const customer = contexts?.customer;

  const tabShoppingReportProps = {
    onSubmit,
    onSearchName,
    isLoadingBranches,
    isLoadingApprovers,
    isLoadingCostCenters,
    isLoadingPassengers,
    isLoadingIssuers,
    costCenters,
    passengers,
    issuers,
    approvers,
    branches: [
      { name: "Marcar todos", uuid: "" },
      { name: "Desmarcar todos", uuid: "" },
    ].concat(branches || []),
  };

  return (
    <Fragment>
      <Tabs value={activeTab}>
        <AppBar>
          <AppBarContainer>
            <AppBarHeading title="Relatórios" />
          </AppBarContainer>

          <MenuTabs isLoading={false} tabs={CUSTOMER_REPORTS_MENU_TABS} />
        </AppBar>

        <TabContent value={CustomerReportTab.SHOPPING}>
          <TabShoppingReport {...tabShoppingReportProps} />
        </TabContent>
      </Tabs>
    </Fragment>
  );
}
