import { useCallback, useContext } from "react";
import { OrderItems, OrderStatus, OrderVehicle } from "~/application/types";
import { Box } from "~/components/Box";
import { Card, CardBody } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Col, Grid } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgAlertTriangle,
  SvgCall,
  SvgClock,
  SvgGps,
  SvgInfo,
  SvgTrash,
} from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { TravelerListItem } from "~/core/shared/components/TravelerListItem";
import { OrderVehicleCard } from "~/presentation/shared/components/OrderVehicleCard";
import { OrderVehicleItemSkeleton } from "~/presentation/shared/components/OrderVehicleItemSkeleton";
import { ReasonRejectedItem } from "~/presentation/shared/components/ReasonRejectedItem";
import { useOrder } from "~/presentation/shared/hooks/useOrder/useOrder";
import { canMarkAsRejected } from "~/presentation/shared/utils";
import { DateFormats, displayDate } from "~/utils/date.utils";
import { OrderApprovalItem } from "../../../../utils";
import { ApprovalOrderContext } from "../../hooks/ApprovalOrderContext";
import { ApprovalButtons } from "../ApprovalButtons";
import { ReprovalMessage } from "../ApprovalMessage";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { RemoveItemButton } from "../RemoveItemButton";
import { asCurrency } from "~/utils/mask.utils";
import { Alert } from "~/components/Alert";
import { AlertViolatedPoliciesPerProducts } from "~/presentation/shared/components/AlertViolatedPoliciePerProducts/AlertViolatedPoliciesPerProducts";
import { ItemBrokenPolicyJustication } from "~/presentation/shared/components/BrokenPolicyJusticationItem/ItemBrokenPolicyJusticationItem";

export interface OrderVehicleItemProps {
  isLoading: boolean;
  canShowApprovalButtons?: boolean;
  data?: OrderVehicle[];
  onDeleteItem?: (itemId: string) => void;
  itemIndex: number;
}

export function OrderVehicleItem({
  data,
  canShowApprovalButtons,
  onDeleteItem,
  itemIndex,
}: OrderVehicleItemProps) {
  const { items, approveItem, reproveItem } = useContext(ApprovalOrderContext);
  const { order } = useOrder();

  const isMobile = useMobile();

  const listRenderer = useCallback(
    (item: OrderVehicle) => {
      const data = {
        item: OrderApprovalItem.VEHICLE_ORDERS,
        itemOrderId: item.uuid,
      };

      const returnFeeAnotherCity = Number(item.returnFeeAnotherCity) || 0;

      const isRejectedItem =
        items?.rejected &&
        items.rejected.some(({ itemOrderId }) => itemOrderId === data.itemOrderId);

      const reasonRejected = item.reasonRejected;
      const shouldMarkAsRejected = canMarkAsRejected({
        reasonRejected: item.reasonRejected,
        order,
      });

      const orderStatus = order?.status as OrderStatus;
      const canRemoveItem =
        ![
          OrderStatus.APPROVED,
          OrderStatus.ON_APPROVAL,
          OrderStatus.ISSUED,
          OrderStatus.CANCELED,
          OrderStatus.CANCELING,
          OrderStatus.PENDING_ISSUE,
        ].includes(orderStatus) && onDeleteItem;

      const canShowBrokenPolicyJustification =
        (!!item?.violatedPolicies?.length &&
          [OrderStatus.OPEN, OrderStatus.REJECTED].includes(orderStatus)) ||
        item.brokenPolicyJustification;

      return (
        <Flex direction="column" gap="4">
          <Col
            css={{
              "@lg": {
                display: "none",
              },
            }}
          >
            <ApprovalButtons
              itemIndex={itemIndex}
              approveItem={approveItem}
              reproveItem={reproveItem}
              item={data}
            />
          </Col>

          <Card
            css={{
              mt: "$3",
              backgroundColor: "transparent",

              border: shouldMarkAsRejected ? "1px solid red" : "none",
            }}
          >
            <CardBody
              css={{
                p: 0,
              }}
            >
              <Flex direction="column" gap="8" key={item.uuid}>
                <Flex direction="column" gap="6">
                  <Flex justify="between">
                    <Text size="4" fw="600">
                      Veículo
                    </Text>

                    <Flex
                      css={{
                        "@mxlg": {
                          display: "none",
                        },
                      }}
                    >
                      <ApprovalButtons
                        itemIndex={itemIndex}
                        approveItem={approveItem}
                        reproveItem={reproveItem}
                        item={data}
                      />
                    </Flex>

                    {canRemoveItem && !isMobile && (
                      <Tooltip content={<TooltipLabel>Remover item</TooltipLabel>}>
                        <IconButton size="md" onClick={() => onDeleteItem(item.uuid)}>
                          <Icon as={SvgTrash} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Flex>
                  <OrderVehicleCard data={item} />
                  {canShowBrokenPolicyJustification && (
                    <ItemBrokenPolicyJustication
                      itemId={item.uuid}
                      brokenPolicyJustification={item.brokenPolicyJustification}
                      css={{
                        width: "100%",
                      }}
                    />
                  )}
                </Flex>

                <Flex
                  direction="column"
                  gap="6"
                  css={{
                    "@mxlg": {
                      display: "none",
                    },
                  }}
                >
                  <Text size="4" fw="600">
                    Datas e localização
                  </Text>

                  <Grid columns="2" gap="6">
                    <Card>
                      <Flex direction="column" as={CardBody} gap="4">
                        <Text size="3" css={{ fw: "600" }}>
                          Informações sobre retirada
                        </Text>

                        <Flex gap="3" align="center">
                          <Icon variant="primary" as={SvgClock} />
                          <Text size="2" css={{ fw: "500" }}>
                            Data e hora
                          </Text>
                          <Text size="2" variant="dark">
                            {displayDate(item.departureDate, DateFormats.LONG_DATE_TIME)}
                          </Text>
                        </Flex>
                        <Flex gap="3" align="center">
                          <Icon variant="primary" as={SvgGps} />
                          <Text size="2" css={{ fw: "500" }}>
                            Local
                          </Text>
                          <Text size="2" variant="dark">
                            {`${item.departure} - ${item.detail?.address?.state}, ${item.detail?.address?.country} - ${item.arrivalCity}`}
                          </Text>
                        </Flex>
                        {item.detail && (
                          <>
                            <Flex gap="3" align="center">
                              <Icon variant="primary" size="sm" as={SvgCall} />
                              <Text size="2" css={{ fw: "500" }}>
                                Contato
                              </Text>
                              <Text size="2" variant="dark">
                                {item.detail?.phone}
                              </Text>
                            </Flex>
                            <Flex gap="3" align="center">
                              <Icon variant="primary" as={SvgInfo} />
                              <Text size="2" css={{ fw: "500" }}>
                                Detalhes
                              </Text>
                              <Text size="2" variant="dark">
                                {item.detail?.deadLineDescription}
                              </Text>
                            </Flex>
                          </>
                        )}
                      </Flex>
                    </Card>

                    <Card>
                      <Flex direction="column" as={CardBody} gap="4">
                        <Text size="3" css={{ fw: "600" }}>
                          Informações sobre devolução
                        </Text>

                        <Flex gap="3" align="center">
                          <Icon variant="primary" as={SvgClock} />
                          <Text size="2" css={{ fw: "500" }}>
                            Data e hora
                          </Text>
                          <Text size="2" variant="dark">
                            {displayDate(item.arrivalDate, DateFormats.LONG_DATE_TIME)}
                          </Text>
                        </Flex>
                        <Flex gap="3" align="center">
                          <Icon variant="primary" as={SvgGps} />
                          <Text size="2" css={{ fw: "500" }}>
                            Local
                          </Text>
                          <Text size="2" variant="dark">
                            {`${item.arrival} - ${item.detail?.address.state}, ${item.detail?.address?.country} - ${item.arrivalCity}`}
                          </Text>
                        </Flex>
                        {item.detail && (
                          <>
                            <Flex gap="3" align="center">
                              <Icon variant="primary" size="sm" as={SvgCall} />
                              <Text size="2" css={{ fw: "500" }}>
                                Contato
                              </Text>
                              <Text size="2" variant="dark">
                                {item.detail?.phone}
                              </Text>
                            </Flex>
                            <Flex gap="3" align="center">
                              <Icon variant="primary" as={SvgInfo} />
                              <Text size="2" css={{ fw: "500" }}>
                                Detalhes
                              </Text>
                              <Text size="2" variant="dark">
                                {item.detail?.deadLineDescription}
                              </Text>
                            </Flex>
                          </>
                        )}
                      </Flex>
                    </Card>
                  </Grid>
                </Flex>

                <Flex direction="column" gap="6">
                  <Text size="4" fw="600">
                    Tarifas
                  </Text>
                  <Card
                    css={{
                      width: "100%",
                    }}
                  >
                    <Flex as={CardBody}>
                      <Flex direction="column" gap="2">
                        <Text fw="500">Esta tarifa inclui:</Text>
                        <ul>
                          <Grid columns="2" gapX="6" gapY="2">
                            {item.detail?.rentalPolicy?.map((rp) => (
                              <li key={rp}>
                                <Text size="2" variant="dark">
                                  {rp}
                                </Text>
                              </li>
                            ))}
                          </Grid>
                        </ul>
                      </Flex>
                    </Flex>
                  </Card>
                </Flex>
                {!!returnFeeAnotherCity && (
                  <Alert variant="warning">
                    <Icon as={SvgAlertTriangle} size="xl" />
                    <Text size="3" css={{}}>
                      Para devolução em outra loja, será cobrado uma taxa de{""}
                      <Text fw="700" variant="warning-dark">
                        {asCurrency(returnFeeAnotherCity)}
                        {""}
                      </Text>
                      {"."}
                    </Text>
                  </Alert>
                )}

                <Flex direction="column" gap="6">
                  <Text size="4" fw="600">
                    Condutor
                  </Text>
                  <TravelerListItem data={item.conductor} />
                </Flex>

                {shouldMarkAsRejected && <ReasonRejectedItem reasonRejected={reasonRejected} />}
              </Flex>

              {isRejectedItem && (
                <Flex>
                  <ReprovalMessage itemIndex={itemIndex} item={data} />
                </Flex>
              )}
            </CardBody>
          </Card>
          <Flex
            css={{
              width: "100%",
            }}
            justify="end"
          >
            {canRemoveItem && <RemoveItemButton onRemove={() => onDeleteItem(item.uuid)} />}
          </Flex>
        </Flex>
      );
    },
    [items]
  );

  return (
    <Box>
      <Flex direction="column">
        <AlertViolatedPoliciesPerProducts itemType={OrderItems.VEHICLE} order={order} />
        <LazyList
          items={data}
          gap="8"
          render={listRenderer}
          skeletonQuantity={1}
          SkeletonComponent={<OrderVehicleItemSkeleton />}
        />
      </Flex>
    </Box>
  );
}
