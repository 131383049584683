import type {
  AirwaySeats,
  AirwaySeatsForm,
  OrderAirway,
  OrderAirwaySegment,
} from "~/application/types";
import { api } from "~/infrastructure/api";
import { AirwaySeatsDTO, OrderAirwayDTO } from "~/infrastructure/api/dtos";
import { mapAirwaySeatsDTO, mapOrderAirwayDTO } from "~/infrastructure/api/mappers";
import type { IOrderAirwayService } from "./IOrderAirwayService";
import type { CheckAirwayOrderDuplicityParams, ICreateOrderAirwayData } from "./types";
import { UnflownData } from "~/presentation/ManageOrder/pages/ManageOrderPage/components/UnflownAirwayDialog/type";
import { DateUtils } from "~/application/utils";

export class OrderAirwayService implements IOrderAirwayService {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async create({
    orderId,
    returnFlight,
    goFlight,
    isCombined,
    lowerFareViolated,
    passengers,
    brokenPolicyJustification,
    isInternational,
    isInternationalCombined,
  }: ICreateOrderAirwayData): Promise<void> {
    return await api
      .post(`/orders/${orderId}/airway`, {
        departure: {
          search_key: goFlight.flight.searchKey,
          flight_id: goFlight.flight.id,
          fare_id: goFlight.flightOption.id,
          lower_fare_violated: lowerFareViolated?.departure,
          unflown_ticket_uuid: goFlight.unflownsAirway?.at(0)?.unflown?.uuid
            ? goFlight.unflownsAirway?.at(0)?.unflown?.uuid
            : null,
          broken_policy_justification: brokenPolicyJustification?.goJustification,
        },
        arrival: returnFlight
          ? {
            search_key: returnFlight.flight.searchKey,
            flight_id: returnFlight.flight.id,
            fare_id: returnFlight.flightOption.id,
            lower_fare_violated: lowerFareViolated?.arrival,
            unflown_ticket_uuid: returnFlight.unflownsAirway?.at(0)?.unflown?.uuid
              ? returnFlight.unflownsAirway?.at(0)?.unflown?.uuid
              : null,
            broken_policy_justification: brokenPolicyJustification?.returnJustification,
          }
          : undefined,
        travelers: passengers,
        combined: isInternationalCombined ? true : returnFlight ? isCombined : undefined,
        is_international: isInternational,
      })
      .then(() => undefined);
  }

  async findByOrderId(orderId: string): Promise<OrderAirway> {
    return await api
      .get<{ data: OrderAirwayDTO }>(`/orders/${orderId}/airway`)
      .then(({ data }) => mapOrderAirwayDTO(data.data));
  }

  async getSeats({ orderItemId }: { orderItemId: string }): Promise<AirwaySeats> {
    return await api
      .get<AirwaySeatsDTO>(`/orders/airway/${orderItemId}/seats`)
      .then(({ data }) => mapAirwaySeatsDTO(data));
  }

  async removeSeat({
    orderItemId,
    seatId,
  }: {
    orderItemId: string;
    seatId: string;
  }): Promise<void> {
    return api.delete<void>(`orders/airway/${orderItemId}/seats/${seatId}`).then();
  }

  async markSeatsAsSelected({
    orderItemId,
    seats,
  }: {
    orderItemId: string;
    seats: AirwaySeatsForm;
  }): Promise<void> {
    const seatsSelected = {
      ...seats,
      seats: seats.seats.filter((seat) => seat.selected),
    } as AirwaySeatsForm;
    await api.post(`/orders/airway/${orderItemId}/seats`, seatsSelected);
  }

  async delete(itemId: string): Promise<void> {
    await api.post<void>(`/orders/airway/${itemId}/cancel`);
  }

  async updateUnflownAirway(data: UnflownData[]): Promise<void> {
    const body = data
      .map((unflown) => ({
        tracker: unflown.tracker,
        markup: unflown.markup,
        value: unflown.totalValue,
        change_tax: unflown.taxOfChange,
        passenger: {
          uuid: unflown.passenger.uuid,
        },
      }))
      ?.at(0);

    const itemId = data?.at(0)?.itemId;

    await api.put<void>(`/orders/airway/${itemId}/update`, body);
  }

  async addTracker(data: OrderAirwaySegment): Promise<void> {
    const body = {
      tracker: data.tracker,
    };

    await api.patch(`/orders/airway/${data.uuid}/tracker`, body);
  }

  async checkDuplicity(data: CheckAirwayOrderDuplicityParams): Promise<{ orderId: string; orderNumber: string }[]> {
    const outboundFlight = data.goFlight.flight;
    const returnFlight = data.returnFlight?.flight;

    const body = {
      travelers: data.passengers,
      departure: {
        departure_airport: outboundFlight.segments[0].departureFlightInfo.airportIata,
        arrival_airport: outboundFlight.segments.at(-1)?.arrivalFlightInfo.airportIata,
        departure_date: DateUtils.toISOString(outboundFlight.segments[0].departureFlightInfo.dateTime),
        arrival_date: DateUtils.toISOString(outboundFlight.segments.at(-1)!.arrivalFlightInfo.dateTime),
      },
      arrival: returnFlight ? {
        departure_airport: returnFlight.segments[0].departureFlightInfo.airportIata,
        arrival_airport: returnFlight.segments.at(-1)?.arrivalFlightInfo.airportIata,
        departure_date: DateUtils.toISOString(returnFlight.segments[0].departureFlightInfo.dateTime),
        arrival_date: DateUtils.toISOString(returnFlight.segments.at(-1)!.arrivalFlightInfo.dateTime),
      } : null,
    };

    return api
      .post<{ data: { order_uuid: string; order_number: string }[] }>(`/orders/airway/check-duplicity`, body)
      .then(({ data }) => data.data.map(({ order_number, order_uuid }) => ({
        orderId: order_uuid,
        orderNumber: order_number
      })));
  }
}
