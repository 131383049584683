import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { TooltipProps } from "recharts";
import { Card } from "~/components/Card";
import { Flex } from "~/components/Flex";
import { Text } from "~/components/Text";
import { asCurrency } from "~/utils/mask.utils";

  export const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload && payload.length) {
      return (
        <Card css={{ p: "$3" }}>
          <Text>{`${label}`}</Text>
          <Flex>
            {payload.map((pld, index) => (
              <Flex key={`payload-item-${index}`}>
                <Text>{asCurrency(pld.value as number)}</Text>
              </Flex>
            ))}
          </Flex>
        </Card>
      );
    }
    return null;
  };

export function StackedBarChart({ chartData }: { chartData: { name: string; sales: number; amt: number }[]}) {
  return (
    <ResponsiveContainer width="100%" height={300} style={{ marginTop: "20px" }}>
          <BarChart
            data={chartData}
            margin={{ top: 10, right: 10, left: 10, bottom: 20 }}
            barSize={40}
          >
            <CartesianGrid 
              vertical={false} 
              stroke="#E5E7EB"
              strokeDasharray="3 3"
            />
            <XAxis
              dataKey="name"
              tickCount={30} interval={30}
              tick={{ fill: '#6B7280', fontSize: 12 }}
              dy={10}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#6B7280', fontSize: 12 }}
            />
     

            <Bar
              dataKey="amt"
              radius={[4, 4, 0, 0]}
            >
              {chartData?.map((_, index) => (
                <Cell key={`cell-${index}`} fill="#2563EB" />
              ))}
            </Bar>
            <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent", radius: "10" }} />
          </BarChart>
        </ResponsiveContainer>
  )
}