import { ComponentProps } from "react";
import { styled } from "~/application/theme";
import { Image } from "../Image";

export type CompanyLogoProps = ComponentProps<typeof CompanyLogo>;

export const CompanyLogo = styled(Image, {
  variants: {
    size: {
      sm: {
        height: "$4",
      },
      md: {
        height: "$6",
      },
      lg: {
        height: "$8",
      },
      "2xl": {
        height: "$12",
      },
      "3xl": {
        height: "$16",
      },
      "4xl": {
        height: "$18",
      },
    },
  },
});
