import { CostCenterDataDTO, CustomerBiDTO } from "../dtos/CustomerBiDTO";

export function mapByCostCenters(costCenters: CostCenterDataDTO[]) {
  return costCenters.map((costCenter) => ({
    uuid: costCenter.uuid,
    lastOrderDate: costCenter.last_order_date,
    name: costCenter.name,
    value: costCenter.value,
  }));
}

export function mapByIssuer(issuers: CustomerBiDTO["by_issuer"]) {
  return issuers.map((issuer) => ({
    lastOrderDate: issuer.last_order_date,
    name: issuer.name,
    value: issuer.value,
  }));
}

export function mapLastPurchases(purchases: CustomerBiDTO["last_purchases"]) {
  return purchases.map((purchase) => ({
    uuid: purchase.uuid,
    arrival: purchase.arrival,
    avarageTicket: purchase.average_ticket,
    departure: purchase.departure,
    frequency: purchase.frequency,
    leadTime: purchase.lead_time,
    share: purchase.share,
    value: purchase.value,
  }));
}

export function mapPercentageByItem(data: CustomerBiDTO["percentage_by_item"]) {
  return Object.entries(data || {})?.map(([key, byItem]) => ({
    item: byItem.item,
    percentValue: byItem.percent_value,
    value: byItem.value,
    percentBrokenPolicy: byItem.percent_broken_policy,
    data: byItem?.data?.map((filter) => ({
      date: filter.date,
      type: filter.type,
      day: filter.day,
      hour: filter.hour,
      month: filter.month,
      value: filter.value,
      year: filter.value,
    })),
  }));
}

export function mapTotalSpent(data: CustomerBiDTO["total_spent"]) {
  
  return data.map((spent) => ({
    value: spent.value,
    type: spent.type,
    data: spent.data.map((data) => ({
      date: data.date,
      day: data.day,
      hour: data.hour,
      month: data.month,
      value: data.value,
      year: data.year,
    })),
  }));
}

export function mapByLeadTime(data: CustomerBiDTO["lead_time"]) {
  return data.map((leadTime) => ({
    description: leadTime.description,
    month: leadTime.month,
    value: leadTime.value,
  }));
}

export function mapTopRoutes(data: CustomerBiDTO["top_routes"]) {
  return data.map((route) => ({
    arrival: route.arrival,
    average_ticket: route.average_ticket,
    departure: route.departure,
    value: route.value,
  }));
}

export function mapRouteAnalysis(data: CustomerBiDTO["route_analysis"]) {
  return data.map((route) => ({
    averageTicket: route.average_ticket,
    frequency: route.frequency,
    leadTime: route.lead_time,
    share: route.share,
    value: route.value,
  }));
}
