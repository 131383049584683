import { FC } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { CostCenterSchema, costCenterSchema } from "./utils";
import { TextInput } from "~/components/Input";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Container } from "~/components/Container";
import { H5 } from "~/components/Typography";
import { DialogBody } from "~/components/Dialog";
import { SvgKey } from "~/components/Icon/icons";
import { CostCenterDialogProps } from "./types";
import { Form } from "~/components/Form/Form";
import { Button } from "~/components/Button";
import { Col, Row } from "~/components/Grid";
import { Text } from "~/components/Text";
import { Box } from "~/components/Box";
import { CostCenter } from "~/application/types";

export const CostCenterDialog: FC<CostCenterDialogProps> = ({ data, onCloseClick, onSubmit }) => {
  const formData = useForm<CostCenterSchema>({
    defaultValues: data,
    resolver: yupResolver(costCenterSchema),
  });

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = formData;

  const isNew = !data;

  return (
    <Container size={{ "@initial": "8", "@mxlg": "4" }} fixed>
      <Form onSubmit={handleSubmit((data) => onSubmit(data as CostCenter))}>
        <FormDialog
          title={isNew ? "Novo centro de custo" : "Editar centro de custo"}
          negativeButton={
            <Button variant="tertiary" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          positiveButton={
            <Button disabled={isSubmitting} type="submit">
              <Text>{isNew ? "Adicionar" : "Aplicar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <DialogBody css={{ p: "$6", maxHeight: "70vh" }}>
            <Box css={{ mb: "$6" }}>
              <H5>Informações básicas</H5>
            </Box>

            <Row gap="6" css={{ "@mxlg": { flexDirection: "column" }}}>
              <Col sz={{ "@lg": "6" }}>
                <FormControl name="costCenterCode" control={control} required>
                  <FieldLabel>Integração externa</FieldLabel>
                  <TextInput leftIcon={SvgKey} placeholder="Número do ID" />
                  {errors.costCenterCode && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.costCenterCode.message}
                    </Text>
                  )}
                </FormControl>
              </Col>

              <Col sz={{ "@lg": "6" }}>
                <FormControl name="name" control={control} required>
                  <FieldLabel>Nome do centro de custo</FieldLabel>
                  <TextInput placeholder="Digite o nome do centro de custo" />
                  {errors.name && (
                    <Text variant="error-base" size="2" css={{ mt: "$2", fontWeight: "bold" }}>
                      {errors.name.message}
                    </Text>
                  )}
                </FormControl>
              </Col>
            </Row>
          </DialogBody>
        </FormDialog>
      </Form>
    </Container>
  );
};

CostCenterDialog.displayName = "CostCenterDialog";
