import { Fragment, useMemo } from "react"
import type { ByPercentagem, ItemData } from "~/application/types/entities/CustomerBI.type"
import { Card } from "~/components/Card"
import { Flex } from "~/components/Flex"
import { Icon } from "~/components/Icon"
import { Text } from "~/components/Text"
import { getServiceIcon, getServiceLabel } from "~/core/shared/utils/order.utils"
import { asCurrency } from "~/utils/mask.utils"
import { convertChartData, getPercentValueIcon } from "../utils"
import { PercentageChart } from "./PorcentageChart"

export interface ChartByItemProps {
  chartByItem?: ItemData[]
  percentageByItem?: ByPercentagem[]
}

export function ChartByItem({ chartByItem, percentageByItem }: ChartByItemProps) {
  const dataChartByItem = useMemo(() => {
    return chartByItem?.map((item) => {
      const percentBrokenPolicy = item.percentBrokenPolicy || 0
      return {
        name: item.item,
        value: item.value,
        percentValue: percentBrokenPolicy,
        data: convertChartData([
          {
            type: item.data[0]?.type,
            data: item.data,
            value: 30,
          },
        ]),
        percentages: [
          { name: "Fora da política", value: percentBrokenPolicy },
          { name: "Dentro da política", value: 100 - percentBrokenPolicy },
        ],
      }
    })
  }, [chartByItem])

  return (
    <Fragment>
      {(!dataChartByItem || dataChartByItem.length === 0) && (
        <Card
          css={{
            overflow: "hidden",
            height: "100%",
            width: "100%",
          }}
        >
         <Flex justify="center" align="center" css={{ height: "100%", width: "100%" }}>
           <Text fw="700">Nenhum dado disponível</Text>
         </Flex>
        </Card>
      )}
      {dataChartByItem?.map((item, index) => (
        <Card
          key={`${item.name}-${index}`}
          css={{
            overflow: "hidden",
            height: "100%",
            width: "100%",
          }}
        >
          <Flex css={{ width: "100%", height: "100%" }}>
            <Flex justify="center" align="center" css={{ background: "$primary-base", p: "$5", height: "auto" }}>
              <Icon fill="#fff" as={getServiceIcon(item.name)} />
            </Flex>
            <Flex gap="4" justify="between" css={{ px: "$5", py: "$4", width: "100%", flex: "1" }} direction="column">
              <Flex direction="column" gap="5">
                <Text css={{ fontWeight: "bold" }}>{getServiceLabel(item.name)}</Text>
                <Text size="7" fw="700">
                  {asCurrency(item.value)}
                </Text>
                <Flex gap="2" align="center">
                  <Icon as={getPercentValueIcon(item.value < 0)} css={{ color: "$success-base" }} />
                  <Text
                    css={{
                      fontSize: "$sm",
                      color: item.value < 0 ? "$error-base" : "$success-base",
                    }}
                  >
                    {`${item.percentValue.toFixed(2)}%`}
                  </Text>
                  <Text css={{ fontSize: "$sm" }}>vs último mês</Text>
                </Flex>
              </Flex>
              <Flex css={{ width: "100%" }}>
                <PercentageChart
                  size="small"
                  percents={item.percentages}
                  showLegend={true}
                  showTooltip={true}
                  legendPosition="right"
                  colors={["#90CAF9", "#4285F4"]}
                />
              </Flex>
            </Flex>
          </Flex>
        </Card>
      ))}
    </Fragment>
  )
}

