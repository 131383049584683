import { useAuth } from "~/presentation/core/contexts/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { logError } from "~/presentation/shared/utils/errors";
import { LOG_TAG } from "../CustomerPage/hooks/useApprovalModels";
import { customerBiService } from "~/application/usecases/CustomerDashboardBI";
import { CustomerBiContaner } from "./CustomerBiContainer";
import { CustomerBI } from "~/application/types/entities/CustomerBI.type";
import { CustomerBiQuery } from "~/application/usecases/CustomerDashboardBI/CustomerDashboardBIService";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { CostCenter } from "~/application/types";
import { QueryKeys, QueryTimes } from "~/application/constants";
import { costCenterService } from "~/application/usecases";
import { AGENCY_CUSTOMER_ID } from "~/application/constants/storageKeys";

const SNACKBAR_MESSAGES = {
  LOAD_ERROR_MESSAGE: "Erro ao buscar dados",
};

export function CustomerDashboardBI() {
  const { user } = useAuth();
  const filterData = useForm();
  const { periodFrom, periodUntil, costCenter } = filterData.watch();

  const customerId = user?.customer?.uuid || (localStorage.getItem(AGENCY_CUSTOMER_ID) as string);

  const safeCostCenters = costCenter?.map((costCenter: CostCenter) => costCenter.uuid) || [];

  const { data: dataByCostCenter, isLoading: isLoadingDataCostCenter } = useQuery(
    [QueryKeys.BY_COST_CENTER, periodFrom, periodUntil, safeCostCenters],
    async () =>
      await customerBiService.findBi({
        customerId: customerId,
        query: CustomerBiQuery.byCostCenter,
        filter: {
          startDate: periodFrom,
          endDate: periodUntil,
          costCenters: safeCostCenters,
        },
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: lastPurchases, isLoading: isLoadingLastPurchase } = useQuery(
    [QueryKeys.LAST_PURCHASE, periodFrom, periodUntil, safeCostCenters, customerId],
    async () =>
      await customerBiService.findBi({
        customerId: customerId,
        query: CustomerBiQuery.lastPurchases,
        filter: {
          startDate: periodFrom,
          endDate: periodUntil,
          costCenters: safeCostCenters,
        },
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: totalSpent, isLoading: isLoadingTotalSpent } = useQuery(
    [QueryKeys.TOTAL_SPENT, periodFrom, periodUntil, safeCostCenters, customerId],
    async () =>
      await customerBiService.findBi({
        customerId: customerId,
        query: CustomerBiQuery.totalSpent,
        filter: {
          startDate: periodFrom,
          endDate: periodUntil,
          costCenters: safeCostCenters,
        },
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: airwayItem, isLoading: isLoadingAirwayItem } = useQuery(
    [QueryKeys.AIRWAY_ITEM, periodUntil, periodFrom, safeCostCenters, customerId],
    async () =>
      await customerBiService.findItem({
        customerId: customerId,
        item: "airway",
        byItem: true,
        filter: {
          costCenters: safeCostCenters,
          endDate: periodUntil,
          startDate: periodFrom,
        },
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: hotelItem, isLoading: isLoadingHotelItem } = useQuery(
    [QueryKeys.HOTEL_ITEM, periodUntil, periodFrom, safeCostCenters, customerId],
    async () =>
      await customerBiService.findItem({
        customerId,
        item: "hotel",
        byItem: true,
        filter: {
          costCenters: safeCostCenters,
          endDate: periodUntil,
          startDate: periodFrom,
        },
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: roadItem, isLoading: isLoadingRoadItem } = useQuery(
    [QueryKeys.ROAD_ITEM, periodUntil, periodFrom, safeCostCenters, customerId],
    async () =>
      await customerBiService.findItem({
        customerId,
        item: "road",
        byItem: true,
        filter: {
          costCenters: safeCostCenters,
          endDate: periodUntil,
          startDate: periodFrom,
        },
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: vehicleItem, isLoading: isLoadingVehicleItem } = useQuery(
    [QueryKeys.VEHICLE_ITEM, periodUntil, periodFrom, safeCostCenters, customerId],
    async () =>
      await customerBiService.findItem({
        customerId,
        item: "vehicle",
        byItem: true,
        filter: {
          costCenters: safeCostCenters,
          endDate: periodUntil,
          startDate: periodFrom,
        },
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: dataByIssuer, isLoading: isLoadingByIssuer } = useQuery(
    [QueryKeys.BY_ISSUER, periodFrom, periodUntil, safeCostCenters, customerId],
    async () =>
      await customerBiService.findBi({
        customerId,
        query: CustomerBiQuery.byIssuer,
        filter: {
          startDate: periodFrom,
          endDate: periodUntil,
          costCenters: safeCostCenters,
        },
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: leadTime, isLoading: isLoadingLeadTime } = useQuery(
    [QueryKeys.LEAD_TIME, periodFrom, periodUntil, safeCostCenters, customerId],
    async () =>
      await customerBiService.findBi({
        customerId,
        query: CustomerBiQuery.byLeadTIme,
        filter: {
          startDate: periodFrom,
          endDate: periodUntil,
          costCenters: safeCostCenters,
        },
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: costCenterData, isLoading: isLoadingCostCenter } = useQuery(
    [QueryKeys.CUSTOMER_COST_CENTERS, periodFrom, periodUntil, safeCostCenters, customerId],
    async () => await costCenterService.find({ customerId, hasIssuedOrder: true }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: topRoutes, isLoading: isLoadingTopRoutes } = useQuery(
    [QueryKeys.TOP_ROUTES, periodFrom, periodUntil, safeCostCenters, customerId],
    async () =>
      await customerBiService.findBi({
        customerId: customerId,
        query: CustomerBiQuery.topRoutes,
        filter: {
          startDate: periodFrom,
          endDate: periodUntil,
          costCenters: safeCostCenters,
        },
      }),
    {
      staleTime: QueryTimes.SMALLEST,
      refetchOnMount: "always",
      onError: (error) => {
        logError({
          logTag: LOG_TAG,
          error,
          defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
        });
      },
    }
  );

  const { data: routeAnalysis, isLoading: isLoadingRouteAnalysis } = useQuery(
    [QueryKeys.ROUTE_ANALYSIS, periodFrom, periodUntil, safeCostCenters, customerId],
    async () =>
      await customerBiService.findBi({
        customerId: customerId,
        query: CustomerBiQuery.routeAnalysis,
        filter: {
          startDate: periodFrom,
          endDate: periodUntil,
          costCenters: safeCostCenters,
        },
      }),
      {
        staleTime: QueryTimes.SMALLEST,
        refetchOnMount: "always",
        onError: (error) => {
          logError({
            logTag: LOG_TAG,
            error,
            defaultErrorMessage: SNACKBAR_MESSAGES.LOAD_ERROR_MESSAGE,
          });
        },
      }
    );
    
    
  const dataByItem = useMemo(() => {
    const items = [];
    if (airwayItem) items.push(...airwayItem);
    if (hotelItem) items.push(...hotelItem);
    if (roadItem) items.push(...roadItem);
    if (vehicleItem) items.push(...vehicleItem);
    return items;
  }, [airwayItem, hotelItem, roadItem, vehicleItem]);

  const dashboard = {
    byCostCenter: dataByCostCenter,
    byIssuer: dataByIssuer,
    topRoutes,
    dataByItem,
    lastPurchases,
    leadTime,
    routeAnalysis,
    totalSpent,
    costCenterData,
  };

  const isLoadingStates = [
    isLoadingDataCostCenter,
    isLoadingLastPurchase,
    isLoadingAirwayItem,
    isLoadingHotelItem,
    isLoadingRoadItem,
    isLoadingVehicleItem,
    isLoadingByIssuer,
    isLoadingLeadTime,
    isLoadingTotalSpent,
    isLoadingRouteAnalysis,
    isLoadingTopRoutes
  ];

  const isLoadingDashboard = isLoadingStates.some((state) => state);

  return (
    <CustomerBiContaner
      isLoadingDashboard={isLoadingDashboard}
      filterData={filterData}
      dashboard={dashboard as CustomerBI}
    />
  );
}
