import { useCallback, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { FlightSegment, OrderAirwaySegment, OrderStatus, SegmentType } from "~/application/types";
import { NavigatorUtils, StringUtils } from "~/application/utils";
import { Alert } from "~/components/Alert";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Col } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import {
  SvgAlertTriangle,
  SvgCheck,
  SvgClose,
  SvgCopy,
  SvgLuggage,
  SvgReload,
  SvgRemove,
  SvgTrash,
} from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Text } from "~/components/Text";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { H5, Label } from "~/components/Typography";
import { TrackDots } from "~/presentation/shared/components/Track";
import { ViolatedPoliciesButton } from "~/presentation/shared/components/ViolatedPoliciesButton/ViolatedPoliciesButton";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { OrderApprovalItem, getAirlineUrl } from "~/presentation/shared/utils";
import * as DateUtils from "~/utils/date.utils";
import { ApprovalOrderContext } from "../../hooks/ApprovalOrderContext";
import { ApprovalButtons } from "../ApprovalButtons";
import { AirwayPassengerType } from "~/core/modules/Agency/pages/AgencyOrderPage/components/Airway";
import { useOrder } from "~/presentation/shared/hooks/useOrder";
import { Divider } from "~/components/Divider";
import { DateFormats, timestampInterval } from "~/application/utils/date-functions";
import { Box } from "~/components/Box";
import slugify from "slugify";

export interface OrderAirwayCardProps {
  data: OrderAirwaySegment;
  canShowReprovalMessage: boolean;
  canReloadTracker: boolean;
  airwayItem?: {
    item: OrderApprovalItem;
    itemOrderId: string;
  };
  onDeleteItem?: (itemId: string) => void;
  onReloadTracker?: () => void;
  onOpenDetails: (data: OrderAirwaySegment) => void;
  itemIndex: number;
}

export function OrderAirwayCard({
  data,
  canReloadTracker,
  onReloadTracker,
  onOpenDetails,
  onDeleteItem,
  itemIndex,
}: OrderAirwayCardProps) {
  const navigate = useNavigate();
  const { orderId } = useParams() as { orderId: string };
  const { order } = useOrder();
  const isInternationalCombined =
    data.isInternational && data.segments.some((segment) => segment.type === SegmentType.ARRIVAL);

  const scales = data.segments.length - 1;

  const displayText = useCallback(
    (scale?: number) => {
      if (!scale) return StringUtils.formatSentence([scales, "conexão", "conexões"]);
      return StringUtils.formatSentence([scale, "conexão", "conexões"]);
    },
    [data]
  );

  const firstIndex = data.segments.findLastIndex(
    (stretch) => stretch.type === SegmentType.DEPARTURE
  );
  const lastIndex = data.segments.findIndex((stretch) => stretch.type === SegmentType.ARRIVAL);

  const flightDurationDeparture = timestampInterval(
    data.segments.at(0)?.departureFlightInfo.dateTime as Date,
    data.segments.at(firstIndex)?.arrivalFlightInfo.dateTime as Date
  );

  const flightDurationArrival = timestampInterval(
    data.segments.at(lastIndex)?.departureFlightInfo.dateTime as Date,
    data.segments.at(data.segments.length - 1)?.arrivalFlightInfo.dateTime as Date
  );

  const connectionsAirportIatas = data.segments
    .slice(0, -1)
    .map((segment) => segment.arrivalFlightInfo.airportIata);

  const goFlight = data.segments[0].departureFlightInfo;
  const returnFlight = data.segments?.at(data.segments?.length - 1)?.arrivalFlightInfo;

  const isMobile = useMobile();

  const item = {
    item: OrderApprovalItem.AIRWAY_ORDERS,
    itemOrderId: data.uuid,
  };

  const { approveItem, reproveItem } = useContext(ApprovalOrderContext);

  const airline = data.segments[0].airline;
  const canShowTracker = data.tracker && data.tracker.toLowerCase() !== "gerando localizador";

  const onCopyText = useCallback((value: string) => NavigatorUtils.copyToClipboard(value), []);

  const renderCopyTracker = useCallback(
    (tracker: string) => (
      <Tooltip content={<TooltipLabel>Copiar localizador</TooltipLabel>}>
        <IconButton onClick={() => onCopyText(tracker)} size="md">
          <Icon as={SvgCopy} />
        </IconButton>
      </Tooltip>
    ),
    [onCopyText]
  );

  function hasAirportChangeInConnections(segments: FlightSegment[]): boolean {
    return segments.some(
      (segment, index) =>
        index < segments.length - 1 &&
        segment.arrivalFlightInfo.airportIata !==
          segments[index + 1].departureFlightInfo.airportIata
    );
  }

  function navigateToCheapestFlights() {
    navigate(
      `/pedidos/${orderId}/voos-mais-baratos?${
        data.flightType === "departure" ? "departureId" : "arrivalId"
      }=${data.uuid}`
    );
  }

  const showAirportChangeAlert =
    data.segments.length > 1 && hasAirportChangeInConnections(data.segments);

  const showAlertUnflownAirway =
    data?.passengers?.some((passenger: AirwayPassengerType) => passenger?.unflownAirway?.uuid) &&
    order?.status === OrderStatus.QUOTING;

  return (
    <>
      {showAirportChangeAlert && (
        <Alert variant="warning">
          <Flex direction="column" gap="3">
            <Text variant="warning-dark">Atenção: Troca de aeroporto necessária</Text>
            <Text css={{ lineHeight: "$6" }}>
              Você precisará se deslocar entre diferentes aeroportos durante sua conexão. Planeje
              tempo suficiente para o trajeto.
            </Text>
          </Flex>
        </Alert>
      )}

      <Col>
        <Col
          css={{
            mb: "$4",
            "@lg": {
              display: "none",
            },
          }}
        >
          <ApprovalButtons
            itemIndex={itemIndex}
            approveItem={approveItem}
            reproveItem={reproveItem}
            item={item}
          />
        </Col>

        <Card>
          <Flex css={{ p: "$4" }} gap="4" align="center">
            <Col>
              <CompanyLogo src={getAirlineUrl(slugify(airline.toString()))} size={"lg"} />
            </Col>
            {data.expiresAt.toString().toLowerCase() !== "invalid date" && (
              <Text size="2" variant="darkest" css={{ fw: "600" }}>
                Expira em{" "}
                {DateUtils.displayDate(data.expiresAt, DateUtils.DateFormats.SMALL_DATE_TIME)}
              </Text>
            )}

            <Text variant="darkest" css={{ fw: "600" }}>
              <Flex align="center">
                {data.tracker && data.tracker}

                {canShowTracker && renderCopyTracker(data.tracker as string)}

                {canReloadTracker && data.tracker?.toLowerCase() === "gerando localizador" && (
                  <Tooltip content={<TooltipLabel>Recarregar</TooltipLabel>}>
                    <IconButton css={{ ml: "$2" }} onClick={onReloadTracker} size="md">
                      <Icon as={SvgReload} />
                    </IconButton>
                  </Tooltip>
                )}
              </Flex>
            </Text>
          </Flex>
        </Card>
      </Col>
      {showAlertUnflownAirway && (
        <Alert variant="warning">
          <Flex
            align="center"
            justify="center"
            css={{
              width: "100%",
            }}
            gap="4"
          >
            <Icon as={SvgAlertTriangle} />
            <Text>Solicitação de remarcação de crédito</Text>
          </Flex>
        </Alert>
      )}
      <Col>
        <Card
          css={{
            overflow: "visible",
            "@mxlg": {
              border: "0",
            },
          }}
        >
          <Flex
            direction="column"
            as={CardBody}
            gap="6"
            css={{
              "@mxlg": {
                py: 20,
                px: 10,
              },
            }}
          >
            <Flex align="start" justify="between" gap="2" wrap="wrap">
              <CompanyLogo
                src={getAirlineUrl(slugify(airline.toString()))}
                alt={airline}
                size={"2xl"}
              />
              <Flex>
                {isMobile && (
                  <Button
                    variant="tertiary"
                    onClick={() => onOpenDetails(data)}
                    css={{
                      "@mxlg": {
                        p: "$3",
                        height: "$5",
                        mr: "$2",
                      },
                    }}
                  >
                    <Text
                      css={{
                        "@mxlg": {
                          fontSize: "12px",
                        },
                      }}
                    >
                      Ver detalhes
                    </Text>
                  </Button>
                )}

                {isMobile && (
                  <Tooltip content={<TooltipLabel>Remover item</TooltipLabel>}>
                    <IconButton
                      size="md"
                      css={{
                        "@lg": {
                          display: "none",
                        },
                      }}
                    >
                      <Icon as={SvgTrash} onClick={() => onDeleteItem?.(item.itemOrderId)} />
                    </IconButton>
                  </Tooltip>
                )}
              </Flex>
              <Flex
                align="center"
                direction={{ "@mxlg": "column" }}
                css={{ "@mxlg": { width: "100%" } }}
                gap="2"
                wrap="wrap"
              >
                <Flex align="center" gap="2" wrap="wrap">
                  {!!data.violatedPolicies?.length && (
                    <ViolatedPoliciesButton data={data.violatedPolicies} />
                  )}

                  {data.isTheCheapestFlight ? (
                    <Button
                      variant="success-light"
                      css={{ "@mxlg": { width: "100%" } }}
                      onClick={navigateToCheapestFlights}
                    >
                      <Icon as={SvgCheck} />
                      <Text>Fez a melhor escolha</Text>
                    </Button>
                  ) : (
                    <Button
                      variant="error-light"
                      css={{ "@mxlg": { width: "100%" } }}
                      onClick={navigateToCheapestFlights}
                    >
                      <Icon as={SvgRemove} />
                      <Text>Melhor opção disponível</Text>
                    </Button>
                  )}

                  {!isMobile && (
                    <Button
                      variant="tertiary"
                      onClick={() => onOpenDetails(data)}
                      css={{
                        "@mxlg": {
                          p: "$3",
                          height: "$5",
                        },
                      }}
                    >
                      <Text
                        css={{
                          "@mxlg": {
                            fontSize: "12px",
                          },
                        }}
                      >
                        Ver detalhes
                      </Text>
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Flex>

            {isInternationalCombined && (
              <Flex
                css={{
                  p: "$6",
                }}
                justify="center"
                direction="column"
                gap="3"
              >
                <Flex
                  direction="column"
                  css={{
                    width: "100%",
                  }}
                  gap="5"
                >
                  <Flex>
                    <Flex direction="column" justify="between" gap="2" css={{ flex: "1 0 0%" }}>
                      <Label css={{ fw: 400 }}>Saída</Label>
                      <H5>{data?.segments?.at(0)?.departureFlightInfo.airportIata}</H5>
                      <Text size={{ "@mxxxl": "1" }}>
                        {data.segments?.at(0)?.departureFlightInfo.airport}
                      </Text>
                      <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
                        {DateUtils.displayDate(
                          data.segments?.at(0)?.departureFlightInfo.dateTime as Date,
                          DateFormats.LONG_DATE
                        )}
                      </Text>
                      <Label css={{ fw: 400 }}>
                        {DateUtils.displayDate(
                          data.segments?.at(0)?.departureFlightInfo?.dateTime as Date,
                          DateFormats.SMALL_TIME
                        )}
                      </Label>
                    </Flex>

                    <Flex
                      direction="column"
                      align="center"
                      gap="4"
                      css={{
                        flex: "1 1 0%",
                        width: "$40",
                        "@xl": {
                          ml: "$4",
                        },
                      }}
                    >
                      <Label css={{ fw: 400 }}>Duração</Label>
                      <Text size="3">{flightDurationDeparture}</Text>
                      {scales > 0 &&
                        (isMobile ? (
                          <Box>
                            <Text variant="primary" size="2" css={{ lineHeight: 1.6, fw: 600 }}>
                              {displayText(lastIndex - 1)}
                            </Text>
                            <TrackDots numberOfDots={lastIndex - 1} />
                            <Text size="3" css={{ lineHeight: 1.25 }}>
                              Parada{connectionsAirportIatas.length > 1 && "s"}:{" "}
                              {connectionsAirportIatas.map((iata) => `(${iata}) `)}
                            </Text>
                          </Box>
                        ) : (
                          <>
                            <Box css={{ width: "70px" }}>
                              <TrackDots numberOfDots={lastIndex - 1} />
                              <Text variant="primary" size="2" css={{ lineHeight: "1.6", fw: 600 }}>
                                {displayText(lastIndex - 1)}
                              </Text>
                            </Box>
                            <Text size="3" css={{ lineHeight: 1.25 }}>
                              Parada{connectionsAirportIatas.length > 1 && "s"}:{" "}
                              {connectionsAirportIatas
                                .map((iata) => `(${iata}) `)
                                .slice(0, lastIndex - 1)}
                            </Text>
                          </>
                        ))}
                      <Flex direction="column">
                        <Label css={{ fw: 400 }}>Voo {data.segments[0].flightNumber}</Label>
                      </Flex>
                    </Flex>

                    <Flex align="end" justify="between" direction="column" css={{ flex: "1 0 0%" }}>
                      <Label css={{ fw: 400 }}>Chegada</Label>
                      <H5>{data.segments.at(firstIndex)?.arrivalFlightInfo.airportIata}</H5>
                      <Text size={{ "@mxxxl": "1" }}>
                        {data.segments.at(firstIndex)?.arrivalFlightInfo.airport}
                      </Text>
                      <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
                        {DateUtils.displayDate(
                          data.segments.at(firstIndex)?.arrivalFlightInfo.dateTime as Date
                        )}
                      </Text>
                      <Label css={{ fw: 400 }}>
                        {DateUtils.displayDate(
                          data.segments.at(firstIndex)?.arrivalFlightInfo.dateTime as Date,
                          DateFormats.SMALL_TIME_AM_PM
                        )}
                      </Label>
                    </Flex>
                  </Flex>

                  <Divider />

                  <Flex>
                    <Flex
                      align={{ "@mxxxl": "start" }}
                      justify={"between"}
                      direction="column"
                      css={{ flex: "1 0 0%" }}
                    >
                      <Label css={{ fw: 400 }}>Chegada</Label>
                      <H5>{data.segments.at(lastIndex)?.departureFlightInfo.airportIata}</H5>
                      <Text size={{ "@mxxxl": "1" }}>
                        {data.segments.at(lastIndex)?.departureFlightInfo.airport}
                      </Text>
                      <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
                        {DateUtils.displayDate(
                          data.segments.at(lastIndex)?.departureFlightInfo.dateTime as Date
                        )}
                      </Text>
                      <Label css={{ fw: 400 }}>
                        {DateUtils.displayDate(
                          data.segments.at(lastIndex)?.departureFlightInfo.dateTime as Date,
                          DateFormats.SMALL_TIME_AM_PM
                        )}
                      </Label>
                    </Flex>
                    <Flex
                      direction="column"
                      align={"center"}
                      gap="4"
                      css={{
                        flex: "1 1 0%",
                        width: "$40",
                        "@xl": {
                          ml: "$4",
                        },
                      }}
                    >
                      <Label css={{ fw: 400 }}>Duração</Label>
                      <Text size="3">{flightDurationArrival}</Text>

                      {scales > 0 &&
                        (isMobile ? (
                          <Box>
                            <Text variant="primary" size="2" css={{ lineHeight: 1.6, fw: 600 }}>
                              {displayText(connectionsAirportIatas.length - lastIndex)}
                            </Text>
                            <TrackDots numberOfDots={connectionsAirportIatas.length - lastIndex} />
                            <Text size="3" css={{ lineHeight: 1.25 }}>
                              Parada{connectionsAirportIatas.length > 1 && "s"}:{" "}
                              {connectionsAirportIatas
                                .map((iata) => `(${iata})`)
                                .slice(lastIndex, connectionsAirportIatas.length)}
                            </Text>
                          </Box>
                        ) : (
                          <>
                            <Box css={{ width: "70px" }}>
                              <TrackDots
                                numberOfDots={connectionsAirportIatas.length - lastIndex}
                              />
                              <Text variant="primary" size="2" css={{ lineHeight: "1.6", fw: 600 }}>
                                {displayText(connectionsAirportIatas.length - lastIndex)}
                              </Text>
                            </Box>
                            <Text size="3" css={{ lineHeight: 1.25 }}>
                              Parada{connectionsAirportIatas.length > 1 && "s"}:{" "}
                              {connectionsAirportIatas
                                .map((iata) => `(${iata})`)
                                .slice(lastIndex, connectionsAirportIatas.length)}
                            </Text>
                          </>
                        ))}

                      <Flex direction="column">
                        <Label css={{ fw: 400 }}>
                          Voo {data.segments?.at(lastIndex)?.flightNumber}
                        </Label>
                      </Flex>
                    </Flex>
                    <Flex
                      align={"end"}
                      justify={"between"}
                      direction="column"
                      css={{ flex: "1 0 0%" }}
                    >
                      <Label css={{ fw: 400 }}>Chegada</Label>
                      <H5>{returnFlight?.airportIata}</H5>
                      <Text size={{ "@mxxxl": "1" }}>{returnFlight?.airport}</Text>
                      <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
                        {DateUtils.displayDate(returnFlight?.dateTime as Date)}
                      </Text>
                      <Label css={{ fw: 400 }}>
                        {DateUtils.displayDate(
                          returnFlight?.dateTime as Date,
                          DateFormats.SMALL_TIME_AM_PM
                        )}
                      </Label>
                    </Flex>
                  </Flex>
                </Flex>

                {/* Detalhes de bagagem */}
                <Flex
                  css={{
                    p: "$2",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  gap="3"
                  direction="column"
                >
                  <Divider
                    css={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "$neutrals-base",
                      alignSelf: "stretch",
                    }}
                  />
                  <Flex justify="between">
                    <Flex align="center" gap="2">
                      <Icon as={SvgLuggage} size="md" />
                      <Text fw="600">Bagagem</Text>
                    </Flex>
                    <Flex direction="column" align="end" gap="1">
                      {data.baggages?.length ? (
                        data.baggages.map(({ amount, included, weight }, index) => (
                          <Text
                            key={index}
                            variant={!index ? "black" : "darkest"}
                            fw={!index ? "500" : "400"}
                            size={!index ? "3" : "2"}
                          >
                            {amount}º bagagem: {weight}kg {included ? "(inclusa)" : ""}
                          </Text>
                        ))
                      ) : (
                        <Icon as={SvgClose} variant="error" />
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            )}

            {!isInternationalCombined && (
              <Flex gap="8" align={{ "@mxlg": "center" }}>
                <Flex
                  align="start"
                  direction="column"
                  gap="4"
                  css={{
                    flex: "2 1 100%",
                  }}
                >
                  <Label>Saída</Label>
                  <Flex direction="column" gap="2">
                    <H5 size={{ "@initial": "5", "@mxlg": "3" }}>{goFlight.airportIata}</H5>
                    <Text size={{ "@initial": "3", "@mxlg": "1" }}>{goFlight.airport}</Text>
                  </Flex>

                  <Text size={{ "@initial": "3", "@mxlg": "1" }}>
                    {DateUtils.displayDate(goFlight?.dateTime as Date)}
                  </Text>

                  <Label>{DateUtils.toHour12(goFlight?.dateTime as Date)}</Label>
                </Flex>

                <Flex
                  direction={{ "@mxlg": "column" }}
                  align={{ "@mxlg": "center" }}
                  css={{
                    display: "none",
                    "@mxlg": {
                      flex: "2 1 100%",
                      width: "$20",
                      fontSize: "12px",
                      display: "flex",
                    },
                  }}
                >
                  {data.segments.length > 1 && (
                    <>
                      <TrackDots numberOfDots={data.segments.length - 1} />
                      <Text css={{ "@mxlg": { mt: "$3" } }}>{`${
                        data.segments.length - 1
                      } conexão`}</Text>
                    </>
                  )}
                </Flex>

                {returnFlight && (
                  <Flex
                    align="start"
                    direction="column"
                    gap="4"
                    css={{
                      flex: "2 1 100%",
                    }}
                  >
                    <Label>Chegada</Label>

                    <Flex direction="column" gap="2">
                      <H5 size={{ "@initial": "5", "@mxlg": "3" }}>{returnFlight.airportIata}</H5>
                      <Text size={{ "@initial": "3", "@mxlg": "1" }}>{returnFlight.airport}</Text>
                    </Flex>

                    <Text size={{ "@initial": "3", "@mxlg": "1" }}>
                      {DateUtils.displayDate(returnFlight.dateTime)}
                    </Text>

                    <Label>{DateUtils.toHour12(returnFlight.dateTime)}</Label>
                  </Flex>
                )}

                <Flex
                  align="start"
                  direction="column"
                  gap="4"
                  css={{
                    flex: "1 1 50%",

                    "@mxlg": {
                      display: "none",
                    },
                  }}
                >
                  <Label>Duração</Label>
                  {data.segments.length > 1 && (
                    <>
                      <TrackDots numberOfDots={data.segments.length - 1} />
                      <Text css={{ "@mxlg": { mt: "$3" } }}>{`${
                        data.segments.length - 1
                      } conexão`}</Text>
                    </>
                  )}
                  <Label>
                    {DateUtils.timeInterval(goFlight.dateTime, returnFlight?.dateTime as Date)}
                  </Label>
                </Flex>

                <Flex
                  align="start"
                  direction="column"
                  gap="4"
                  css={{
                    flex: "1 1 50%",

                    ml: "$16",
                    "@mxlg": {
                      display: "none",
                    },
                  }}
                >
                  <Label>Tarifa</Label>
                  <Label>{data.familyFare}</Label>
                </Flex>
                <Flex
                  align="start"
                  direction="column"
                  gap="4"
                  css={{
                    flex: "1 1 50%",

                    ml: "$16",
                    "@mxlg": {
                      display: "none",
                    },
                  }}
                >
                  <Label>Code share</Label>
                  <Label>{data.familyFare}</Label>
                </Flex>
              </Flex>
            )}

            {data.isInternational && !isInternationalCombined && (
              <Flex
                css={{
                  p: "$2",
                  width: "100%",
                  cursor: "pointer",
                }}
                gap="3"
                direction="column"
              >
                <Divider
                  css={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "$neutrals-base",
                    alignSelf: "stretch",
                  }}
                />
                <Flex justify="between">
                  <Flex align="center" gap="2">
                    <Icon as={SvgLuggage} size="md" />
                    <Text fw="600">Bagagem</Text>
                  </Flex>
                  <Flex direction="column" align="end" gap="1">
                    {data.baggages?.length ? (
                      data.baggages.map(({ amount, included, weight }, index) => (
                        <Text
                          key={index}
                          variant={!index ? "black" : "darkest"}
                          fw={!index ? "500" : "400"}
                          size={!index ? "3" : "2"}
                        >
                          {amount}º bagagem: {weight}kg {included ? "(inclusa)" : ""}
                        </Text>
                      ))
                    ) : (
                      <Icon as={SvgClose} variant="error" />
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Card>
      </Col>
    </>
  );
}
