import { Customer, CustomerSettingParameter } from "~/application/types";
import { getDefaultLabelParameterSelect, SettingParameterTypeValue } from "~/application/types/entities/SettingParameter.type";
import * as MaskUtils from "~/utils/mask.utils";

export type EditableCustomer = Customer;

export function editCustomer(data: Customer): EditableCustomer {
  return {
    ...data,
    domains: data.domains.map(({ domain }) => ({ domain })),
    cnpj: MaskUtils.asCNPJ(data.cnpj),
    zipCode: MaskUtils.asCEP(data.zipCode),
    stateRegistration: data.stateRegistration ?? undefined,
    wintourCode: data.wintourCode ?? undefined,
    dashboardLink: data.dashboardLink ?? undefined,
    phone: MaskUtils.asPhoneNumber(data.phone),
  };
}

export const getSettingParametersDefaultValues = (data: CustomerSettingParameter) => {
  if (data.typeValue === SettingParameterTypeValue.SELECT) {
    return {
      ...data,
      value: {
        label: getDefaultLabelParameterSelect(data.value as string),
        value: data.value,
      },
    };
  }

  return data;
};