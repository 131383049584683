import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";
import { useForm } from "react-hook-form";

import {
  Approver,
  Assessment,
  CostCenter,
  Customer,
  Order,
  OrderStatus,
  Phase,
  Project,
  ReasonTrip,
  UserContext,
} from "~/application/types";
import {
  branchService,
  costCenterService,
  customerService,
  orderService,
  reasonTripService,
} from "~/application/usecases";
import { log } from "~/application/utils/log";
import { Alert } from "~/components/Alert";
import { Button } from "~/components/Button";
import { Card, CardBody } from "~/components/Card";
import { Container } from "~/components/Container";
import { dialogService } from "~/components/DialogStack";
import { Flex } from "~/components/Flex";
import { Form } from "~/components/Form";
import { FieldLabel, FormControl } from "~/components/FormControl";
import { FormDialog } from "~/components/FormDialog";
import { Col } from "~/components/Grid";
import { Select, TextAreaInput } from "~/components/Input";
import { Spinner } from "~/components/Spinner";
import { Text } from "~/components/Text";
import { userIsIssuer } from "~/core/modules/Order/utils";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { OrderApprovalItem, consultOrderItemsPriceChange } from "~/presentation/shared/utils";
import { CreateAssessmentData } from "../../../../utils";
import { SNACKBAR_MESSAGES } from "../../hooks/useApprovalOrder";
import { Skeleton } from "~/components/Skeleton";
import { QueryKeys } from "~/application/constants";
import { useVerifyParameter } from "~/presentation/shared/hooks/useVerifyParameter";
import {
  ApprovalOption,
  BudgetOption,
  SettingParameterSlug,
} from "~/application/types/entities/SettingParameter.type";
import { useProjects } from "~/core/modules/Customer/pages/CustomerPage/hooks/useProjects";
import { QueryTimes } from "~/constants";
import { orderRoadRebooking } from "~/application/usecases/OrderRoadRebooking";
import { FindCostCenterState } from "~/application/usecases/CostCenter/ICostCenterService";
import { phaseService } from "~/application/usecases/Phase";
import { useCustomerEmployeesGroups } from "~/core/modules/Customer/pages/CustomerPage/hooks/useCustomerEmployeesGroups";
import { ApprovalTypeToApprover } from "~/application/usecases/Order/OrderService";

export interface ConfirmRequestApprovalDialogProps {
  order?: Order;
  enabled?: boolean;
  defaultData: Partial<CreateAssessmentData>;
  customerId: string;
  onSubmit: (data: any) => Promise<Assessment>;
  onCloseClick?: () => void;
  onRoadRebooking?: (data: { isRebookingRoad: boolean; isRoadRebookError: boolean }) => void;
}

const LOG_TAG = "Order/OrderPage/views/OrderItems/ConfirmRequestApprovalDialog";

export function ConfirmRequestApprovalDialog({
  order,
  enabled,
  defaultData,
  customerId,
  onSubmit,
  onCloseClick,
  onRoadRebooking,
}: ConfirmRequestApprovalDialogProps) {
  const { control, formState, handleSubmit, watch, setValue } = useForm<CreateAssessmentData>({
    defaultValues: defaultData,
    reValidateMode: "onBlur",
    mode: "onSubmit",
  });

  const reasonTrip = order?.reasonTrip;
  const orderStatus = order?.status as OrderStatus;
  const canFetchReasonTrips = [OrderStatus.OPEN, OrderStatus.REJECTED, OrderStatus.QUOTED].includes(
    orderStatus
  );

  const [searchBranch, setSearchBranches] = useState("");
  const [debouncedSearchBranch] = useDebounce(searchBranch, 700);
  const [searchReasonTrip, setSearchReasonTrip] = useState();
  const [debouncedSearchReasonTrip] = useDebounce(searchReasonTrip, 700);
  const [searchPhase, setSearchPhase] = useState();
  const [debouncedSearchPhase] = useDebounce(searchPhase, 700);
  const [searchCostCenter, setSearchCostCenter] = useState();
  const [debouncedSearcCostCenter] = useDebounce(searchCostCenter, 700);

  const canFetchCostCenters = canFetchReasonTrips;

  useEffect(() => {
    if (reasonTrip?.uuid) setValue("reasonTrip", reasonTrip);
  }, [reasonTrip]);

  const data = watch();
  const { costCenter, branch, project, phase, group } = data;

  const { user, contexts } = useUser();

  const orderId = order?.uuid;

  const hasCostCenterRequiredPhase = useVerifyParameter({
    customer: contexts.customer,
    parameter: SettingParameterSlug.COST_CENTER_REQUIRED_PHASE,
  });

  const { data: customer } = useQuery({
    queryKey: [QueryKeys.CUSTOMER, customerId],
    queryFn: () => customerService.findById(customerId),
    enabled: !!order && !user.customer,
  });

  const { data: costCenters, isFetching: isLoadingCostCenters } = useQuery(
    [QueryKeys.CUSTOMER_COST_CENTERS, customerId, data.phase?.uuid, debouncedSearcCostCenter],
    () =>
      costCenterService.find({
        customerId,
        page: 1,
        state: FindCostCenterState.ACTIVE,
        phase: !hasCostCenterRequiredPhase ? null : phaseId,
        name: debouncedSearcCostCenter,
      }),
    {
      retry: 2,
      enabled: canFetchCostCenters,
    }
  );

  const { data: branchs, isFetching: isLoadingBranchs } = useQuery(
    [QueryKeys.CUSTOMER_BRANCHES, customerId, debouncedSearchBranch],
    () => branchService.find({ customerId, page: 1, name: debouncedSearchBranch }),
    {
      enabled: !!customerId,
    }
  );

  const { data: reasonTrips, isFetching: isLoadingReasonTrips } = useQuery(
    [QueryKeys.CUSTOMER_REASON_TRIPS, debouncedSearchReasonTrip],
    async () =>
      await reasonTripService
        .find({
          customerId,
          reason: debouncedSearchReasonTrip,
          active: true,
          withApprovalModels: true,
        })
        .then(({ data }) => data.sort((a, b) => (a.reason > b.reason ? 1 : -1))),
    {
      retry: 2,
      enabled: canFetchReasonTrips,
      staleTime: QueryTimes.NORMAL,
    }
  );

  const customerValue = user.customer ?? customer;

  const parameterConfigs = [
    {
      key: "isPhaseParameterApproval",
      parameter: SettingParameterSlug.APPROVAL_BY,
      value: ApprovalOption.PHASE,
    },
    {
      key: "isPhaseParameterBudget",
      parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
      value: BudgetOption.PHASE,
    },
    {
      key: "isCostCenterParameterBudget",
      parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
      value: BudgetOption.COST_CENTER,
    },
    {
      key: "isCostCenterParameterApproval",
      parameter: SettingParameterSlug.APPROVAL_BY,
      value: ApprovalOption.COST_CENTER,
    },
    {
      key: "isProjectParameterBudget",
      parameter: SettingParameterSlug.MANAGE_BUDGET_BY,
      value: BudgetOption.PROJECT,
    },
    {
      key: "isProjectParameterApproval",
      parameter: SettingParameterSlug.APPROVAL_BY,
      value: ApprovalOption.PROJECT,
    },
    {
      key: "isGroupParameterApproval",
      parameter: SettingParameterSlug.APPROVAL_BY,
      value: ApprovalOption.GROUP,
    },
  ] as const;

  type ParameterKeys = typeof parameterConfigs[number]["key"];

  type ParametersMap = Record<ParameterKeys, ReturnType<typeof useVerifyParameter>>;

  const parameters: ParametersMap = parameterConfigs.reduce((acc, config) => {
    acc[config.key] = useVerifyParameter({
      customer: customerValue,
      parameter: config.parameter,
      value: config.value,
    });
    return acc;
  }, {} as ParametersMap);

  const {
    isPhaseParameterApproval,
    isPhaseParameterBudget,
    isCostCenterParameterBudget,
    isCostCenterParameterApproval,
    isProjectParameterBudget,
    isProjectParameterApproval,
    isGroupParameterApproval,
  } = parameters;

  const firstTraveler = order?.travelers.at(0);

  useEffect(() => {
    if (!group?.uuid) setValue("group", firstTraveler?.group);
  }, [firstTraveler?.group, group?.uuid]);

  const currentApprovalType = useMemo(() => {
    if (isPhaseParameterApproval)
      return { approvalType: ApprovalTypeToApprover.PHASE, uuid: phase?.uuid };
    if (isProjectParameterApproval)
      return { approvalType: ApprovalTypeToApprover.PROJECT, uuid: project?.uuid };
    if (isCostCenterParameterApproval)
      return { approvalType: ApprovalTypeToApprover.COST_CENTER, uuid: costCenter?.uuid };
    if (isGroupParameterApproval)
      return { approvalType: ApprovalTypeToApprover.GROUP, uuid: group?.uuid };
  }, [isPhaseParameterApproval, phase?.uuid, project?.uuid, group?.uuid, costCenter?.uuid]);

  const { data: customersApprovers, isFetching: isLoadingApprovers } = useQuery(
    [QueryKeys.APPROVERS, currentApprovalType],
    () =>
      orderService.findApprover({
        approvalType: currentApprovalType?.approvalType,
        uuid: currentApprovalType?.uuid,
      }),
    {
      enabled: !!currentApprovalType?.uuid,
    }
  );

  const {
    data: projects,
    isLoading: isLoadingProjects,
    search: searchProjects,
    onSearch: fetchProjects,
  } = useProjects({
    isActive: true,
    customerId,
    enabled: !!enabled && !!customerId,
    isOnlyWithPhase: isPhaseParameterApproval || isPhaseParameterBudget ? true : undefined,
    withApprovalModels: isProjectParameterApproval ? true : undefined,
  });

  const canFetchPhases = !!data?.project && !isLoadingProjects;
  const phaseId = data?.phase?.uuid;

  const { data: phases, isFetching: isLoadingPhases } = useQuery(
    [QueryKeys.CUSTOMER_PHASES, debouncedSearchPhase, data?.project],
    async () =>
      await phaseService
        .find({
          name: debouncedSearchPhase,
          projectId: data?.project?.uuid,
          isActive: true,
          withApprovalModels: true,
        })
        .then(({ data }) => data),
    {
      retry: 2,
      enabled: canFetchPhases,
      staleTime: QueryTimes.NORMAL,
    }
  );

  useEffect(() => {
    setValue("phase", null);
  }, [data?.project]);

  const canRebookRoad =
    [OrderStatus.OPEN, OrderStatus.REJECTED].includes(orderStatus) && order?.items.road;

  const { isError: isRoadRebookError, isFetching: isRebookingRoad } = useQuery(
    [QueryKeys.ORDER_ROAD_REBOOK, orderId],
    async () => await orderRoadRebooking.rebook(orderId!),
    {
      enabled: !!canRebookRoad,
      refetchOnMount: "always",
      staleTime: QueryTimes.SMALLEST,
      onError: (error: any) => {
        // eslint-disable-next-line no-console
        
      },
    }
  );

  useEffect(() => {
    onRoadRebooking?.({ isRebookingRoad, isRoadRebookError });
  }, [isRoadRebookError, isRebookingRoad]);

  const isSingleLevel = customersApprovers?.every((approver) => approver.level === 1);

  const hasSelectApproversSettingParameters = useVerifyParameter({
    customer: contexts.customer,
    parameter: SettingParameterSlug.SELECT_APPROVERS,
  });

  const hasBranch = branchs?.data?.length || searchBranch;
  const hasProject = (projects?.length || 0) > 0 || !!searchProjects;

  const customerEmployeeUuid = user.profiles.customer.uuid;

  const issuerIsSelfApprover = costCenter?.approvalModels.some((approvalModel) => {
    return approvalModel.approvers.some(({ uuid, isSelfApprover }) => {
      return uuid === order?.issuer.uuid && isSelfApprover;
    });
  });

  const userIsSelfApprover = costCenter?.approvalModels.some((approvalModel) => {
    return approvalModel.approvers.some(({ uuid, isSelfApprover }) => {
      return uuid === customerEmployeeUuid && isSelfApprover;
    });
  });

  const canAgencyApprove = user.context === UserContext.Agency && issuerIsSelfApprover;
  const isIssuer = userIsIssuer(user, order);
  const userId = user.profiles.customer?.uuid;
  const approvers = order?.approvalModel?.approvers || [];
  const approverIndex = approvers.findIndex((a) => a.uuid === userId);
  const approverLevel = approvers[approverIndex]?.level ?? 1;

  const thereIsNextLevelApprover = costCenter?.approvalModels.some((approvalModel) =>
    approvalModel.approvers.some(({ level }) => level === approverLevel + 1)
  );

  const shouldApproveInstantly = () => {
    if (
      data.approver?.uuid !== user?.profiles?.customer?.uuid &&
      data.approver &&
      !data?.approver?.selfApprover
    ) {
      return false;
    }

    if (isIssuer && userIsSelfApprover && !thereIsNextLevelApprover) {
      return true;
    }

    if (canAgencyApprove) {
      return true;
    }

    return false;
  };

  const canApproveInstantly = shouldApproveInstantly();

  const onContinue = async (data: CreateAssessmentData) => {
    if (branch?.uuid === order?.customer?.uuid) {
      data.branch = null;
    }

    onSubmit({ ...data, orderId: order?.uuid as string });
  };

  const onError = (error: any) => {
    // eslint-disable-next-line no-console
    
  };

  const filterApprovers = (approver: Approver) => {
    
    const approverIsTraveler = order?.travelers.find((traveler) => traveler.uuid === approver.uuid)
    
    if (parameters.isGroupParameterApproval && approverIsTraveler && !approver.selfApprover) {
     return false
    }
    
    if (user.profiles.customer.uuid === approver.uuid) {
      return approver.selfApprover;
    }
    
    return true;
  };

  const onRejectOrderDuePriceChange = handleSubmit(async () => {
    if (order?.status !== OrderStatus.REJECTED) {
      const rejectedItems = order?.items.road?.travels.map((travel) => ({
        item: OrderApprovalItem.ROAD_ORDERS,
        itemOrderId: travel.uuid,
        reasonRejected: SNACKBAR_MESSAGES.ORDER_REJECTED_DUE_PRICE_CHANGE_MESSAGE,
      }));

      await onSubmit({ ...data, rejectedItems });
    }

    dialogService.popDialog();
  }, onError);

  const negativeButton = (
    <Button variant="tertiary" type="reset" onClick={onRejectOrderDuePriceChange}>
      <Text>Cancelar</Text>
    </Button>
  );

  const positiveButton = (
    <Button onClick={handleSubmit(onContinue)}>
      <Text>Prosseguir</Text>
    </Button>
  );

  const { mutateAsync: onConsultOrderItemsPrice } = useMutation(
    async (orderUuid?: string) => {
      return await orderService.getOrderItemsPriceChange(orderUuid ?? (order?.uuid as string));
    },
    {
      onMutate: () => {
        dialogService.popDialog();
        dialogService.showDialog(<LoadingDialog message="Verificando alteração de preço" />);
      },
      onError: (error) => log.e(LOG_TAG, error),
      onSettled: () => dialogService.popAll(),
    }
  );

  const { onOrderItemsPriceChange } = consultOrderItemsPriceChange({
    negativeButton,
    positiveButton,
  });

  const combinedBranchs = [
    { ...contexts.customer, name: contexts?.customer?.companyName },
    ...(branchs?.data || []),
  ].filter((branch) => branch?.uuid);

  useEffect(() => {
    if (data.costCenter && data.phase && hasCostCenterRequiredPhase) {
      setValue("costCenter", null);
    }
  }, [data.phase]);

  return (
    <Container size={{ "@initial": "8", "@mxlg": "4" }} fixed>
      <Form
        onSubmit={handleSubmit(async (data) => {
          const orderItems = await onConsultOrderItemsPrice(order?.uuid);
          const someRoadIncreasedPrice = orderItems?.road?.some(({ newValue }) => newValue);

          if (someRoadIncreasedPrice) {
            dialogService.popDialog();
            return onOrderItemsPriceChange(orderItems);
          }

          return onContinue(data);
        }, onError)}
      >
        <FormDialog
          title="Confirmar solicitação de aprovação do pedido"
          negativeButton={
            <Button variant="tertiary" type="reset" onClick={onCloseClick}>
              <Text>Cancelar</Text>
            </Button>
          }
          alert={
            <>
              {canApproveInstantly && (
                <Alert
                  variant="info"
                  css={{
                    p: "$4",
                  }}
                >
                  <Text fw="400" variant="primary-dark" css={{ lineHeight: 1.4 }}>
                    Ao clicar em <Text fw="600">"Aprovar"</Text>, seu pedido será emitido
                    automaticamente. Verifique minuciosamente todos os detalhes antes de prosseguir.
                  </Text>
                </Alert>
              )}
            </>
          }
          positiveButton={
            <Button
              disabled={
                (isProjectParameterApproval && project?.approvalModels?.length === 0) ||
                (isPhaseParameterApproval && phase?.approvalModels?.length === 0) ||
                (isCostCenterParameterApproval && costCenter?.approvalModels?.length === 0) ||
                (isGroupParameterApproval && group?.approvalModels?.length === 0) ||
                formState.isSubmitting ||
                costCenter?.approvalModels.length === 0 ||
                isRebookingRoad
              }
              type="submit"
            >
              <Text>{canApproveInstantly ? "Aprovar" : "Enviar"}</Text>
            </Button>
          }
          onClickDismissButton={onCloseClick}
        >
          <CardBody
            css={{
              overflow: "auto",
              maxHeight: "360px",
            }}
          >
            <Flex direction="column" gap="3">
              {hasCostCenterRequiredPhase && (
                <Flex gap="3">
                  {hasProject && (
                    <Col sz={isPhaseParameterBudget ? "6" : "12"}>
                      <FormControl
                        name="project"
                        control={control}
                        required={isProjectParameterBudget || isPhaseParameterBudget}
                      >
                        <FieldLabel>Projeto</FieldLabel>
                        <Select
                          onInputChange={fetchProjects}
                          options={projects}
                          isLoading={isLoadingProjects}
                          getOptionLabel={(item: Project) => item.name}
                          getOptionValue={(item: Project) => item.uuid}
                          placeholder="Digite o Projeto"
                        />
                      </FormControl>
                    </Col>
                  )}

                  {hasProject && isPhaseParameterBudget && (
                    <Col sz="6">
                      <FormControl name="phase" control={control} required>
                        <FieldLabel>Fase</FieldLabel>
                        <Select
                          options={phases}
                          onInputChange={(e) => setSearchPhase(e)}
                          isLoading={isLoadingPhases}
                          getOptionLabel={(item: Phase) => item.name}
                          getOptionValue={(item: Phase) => item.uuid}
                          placeholder="Digite a fase"
                        />
                      </FormControl>
                    </Col>
                  )}
                </Flex>
              )}
              <Col sz="12">
                <FormControl
                  name="costCenter"
                  control={control}
                  required={isCostCenterParameterApproval || isCostCenterParameterBudget}
                >
                  <FieldLabel>Nome do centro de custo</FieldLabel>
                  <Select
                    options={costCenters?.filter((costCenter) =>
                      costCenter.approvalModels.some((approvalModel) => approvalModel.active)
                    )}
                    isLoading={isLoadingCostCenters}
                    disabled={!data.phase?.name && hasCostCenterRequiredPhase}
                    onInputChange={setSearchCostCenter}
                    onChange={(e) => {
                      setValue("costCenter", e);
                    }}
                    getOptionLabel={(item: CostCenter) => item.name}
                    getOptionValue={(item: CostCenter) => item.uuid}
                    placeholder="Digite o centro de custo"
                  />
                </FormControl>
              </Col>

              {isCostCenterParameterApproval && costCenter?.approvalModels.length === 0 && (
                <Col>
                  <Alert variant="error">
                    <Text fw={500} variant="error-base">
                      Esse centro de custo não possui um modelo de aprovação ativo
                    </Text>
                  </Alert>
                </Col>
              )}

              {isLoadingApprovers && hasSelectApproversSettingParameters && (
                <Col>
                  <Skeleton css={{ height: "$10" }} />{" "}
                </Col>
              )}

              {hasSelectApproversSettingParameters && isSingleLevel && !isLoadingApprovers && (
                <Col sz="12">
                  <FormControl name="approver" control={control}>
                    <FieldLabel>Aprovadores</FieldLabel>
                    <Select
                      options={(customersApprovers ?? []).filter(filterApprovers)}
                      isLoading={isLoadingApprovers}
                      getOptionValue={(option) => option.uuid}
                      getOptionLabel={(option) => option.name}
                      placeholder="Digite os aprovadores"
                    />
                  </FormControl>
                </Col>
              )}

              {hasBranch && (
                <Col sz="12">
                  <FormControl name="branch" control={control}>
                    <FieldLabel>Faturar para qual empresa</FieldLabel>
                    <Select
                      options={combinedBranchs}
                      isLoading={isLoadingBranchs}
                      onInputChange={setSearchBranches}
                      getOptionLabel={(item: Customer["agency"]) => item.name}
                      getOptionValue={(item: Customer["agency"]) => item.uuid}
                      placeholder="Digite a filial"
                    />
                  </FormControl>
                </Col>
              )}

              {isGroupParameterApproval && group?.approvalModels?.length === 0 && (
                <Col>
                  <Alert variant="error">
                    <Text fw={500} variant="error-base">
                      Esse grupo não possui um modelo de aprovação ativo
                    </Text>
                  </Alert>
                </Col>
              )}

              <Flex gap="2" align="center">
                {!hasCostCenterRequiredPhase && (
                  <Flex gap="3" css={{ width: "98%" }}>
                    {hasProject && (
                      <Col sz={isPhaseParameterBudget ? "6" : "12"}>
                        <FormControl
                          name="project"
                          control={control}
                          required={isProjectParameterBudget || isPhaseParameterBudget}
                        >
                          <FieldLabel>Projeto</FieldLabel>
                          <Select
                            onInputChange={fetchProjects}
                            options={projects}
                            isLoading={isLoadingProjects}
                            getOptionLabel={(item: Project) => item.name}
                            getOptionValue={(item: Project) => item.uuid}
                            placeholder="Digite o Projeto"
                          />
                        </FormControl>
                      </Col>
                    )}

                    {hasProject && isPhaseParameterBudget && (
                      <Col sz="6">
                        <FormControl name="phase" control={control} required>
                          <FieldLabel>Fase</FieldLabel>
                          <Select
                            options={phases}
                            onInputChange={(e) => setSearchPhase(e)}
                            isLoading={isLoadingPhases}
                            getOptionLabel={(item: Phase) => item.name}
                            getOptionValue={(item: Phase) => item.uuid}
                            placeholder="Digite a fase"
                          />
                        </FormControl>
                      </Col>
                    )}
                  </Flex>
                )}
                {!isPhaseParameterBudget && (
                  <Col sz={!hasProject ? "12" : "6"}>
                    <FormControl name="reasonTrip" control={control} required>
                      <FieldLabel>Motivo</FieldLabel>
                      <Select
                        options={reasonTrips}
                        onInputChange={(e) => setSearchReasonTrip(e)}
                        isLoading={isLoadingReasonTrips}
                        disabled={!!reasonTrip?.uuid}
                        getOptionLabel={(item: ReasonTrip) => item.reason}
                        getOptionValue={(item: ReasonTrip) => item.uuid}
                        placeholder="Digite o motivo da viagem"
                      />
                    </FormControl>
                  </Col>
                )}
              </Flex>

              {isProjectParameterApproval && project?.approvalModels?.length === 0 && (
                <Col>
                  <Alert variant="error">
                    <Text fw={500} variant="error-base">
                      Esse projeto não possui um modelo de aprovação ativo
                    </Text>
                  </Alert>
                </Col>
              )}

              {isPhaseParameterApproval && phase?.approvalModels?.length === 0 && (
                <Col>
                  <Alert variant="error">
                    <Text fw={500} variant="error-base">
                      Essa fase não possui um modelo de aprovação ativo
                    </Text>
                  </Alert>
                </Col>
              )}

              {(isPhaseParameterApproval || isPhaseParameterBudget) && (
                <Col sz={"12"}>
                  <FormControl name="reasonTrip" control={control} required>
                    <FieldLabel>Motivo</FieldLabel>
                    <Select
                      options={reasonTrips}
                      onInputChange={(e) => setSearchReasonTrip(e)}
                      isLoading={isLoadingReasonTrips}
                      disabled={!!reasonTrip?.uuid}
                      getOptionLabel={(item: ReasonTrip) => item.reason}
                      getOptionValue={(item: ReasonTrip) => item.uuid}
                      placeholder="Digite o motivo da viagem"
                    />
                  </FormControl>
                </Col>
              )}

              <Col sz="12">
                <FormControl name="observation" control={control}>
                  <FieldLabel>Observações sobre o pedido</FieldLabel>
                  <TextAreaInput placeholder="Digite alguma observação sobre o pedido" />
                </FormControl>
              </Col>

              {canAgencyApprove && (
                <Col sz="12" css={{ mt: "$5" }}>
                  <FormControl name="agencyObs" control={control}>
                    <FieldLabel>Justificativa</FieldLabel>
                    <TextAreaInput placeholder="Digite a justificativa de aprovação" />
                  </FormControl>
                </Col>
              )}

              {isRebookingRoad && (
                <Card css={{ width: "100%", border: "none" }}>
                  <Flex
                    as={CardBody}
                    justify="center"
                    align="center"
                    gap={6}
                    css={{
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                    }}
                  >
                    <Text size="4" css={{ fw: "bold" }}>
                      Aguarde estamos verificando sua reserva
                    </Text>
                    <Spinner />
                  </Flex>
                </Card>
              )}
            </Flex>
          </CardBody>
        </FormDialog>
      </Form>
    </Container>
  );
}
