import { FC, useCallback, useMemo, useState } from "react";
import { Flight } from "~/application/types";
import { asCurrency } from "~/application/utils/mask-functions";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { LazyList } from "~/components/LazyList";
import { Tag } from "~/components/Tag";
import { Text } from "~/components/Text";
import { BookingFlightListItem } from "~/presentation/Booking/BookingAirway/pages/FlightsPage/ui/views/BookingFlightListItem";
import { FiltersSection } from "~/presentation/shared/components/FiltersSection";
import { GoFlightsSectionUIProps } from "./types";
import { Flex } from "~/components/Flex";
import { Button } from "~/components/Button";
import { useFlightsPage } from "../../../contexts/FlightsPageContext";
import { Box } from "~/components/Box";
import { Alert } from "~/components/Alert";
import { Spinner } from "~/components/Spinner";
import { AirwayBudgetDispatchType } from "../../../hooks/useAirwayBudget/type";
import { Checkbox } from "~/components/Input";

export const GoFlightsSectionUI: FC<GoFlightsSectionUIProps> = ({
  data,
  filters,
  isAgency,
  flightsList,
  isLoading,
  bookingState,
  currentStep,
  isInternationalCombined,
  handleCheckboxChangeSegment,
  onFilterDurationChange,
  onFilterChange,
  onSelectFlight,
  setOrderType,
  orderType,
}) => {
  const minimumPrice = useMemo(() => data[0]?.minimumPrice.amount, [data]);

  const { airwayBudget, flightQuery } = useFlightsPage();
  const { onActiveBudget, activeBudget, dispatch, state } = airwayBudget;
  const hasArrival = !!flightQuery.data?.stretch.at(0)?.returnDate;

  const maxBudgetNumber = hasArrival ? 30 : 64;

  const allFlightsBudget = state?.departure?.length === maxBudgetNumber;

  const selectAllFlights = useCallback(() => {
    if (allFlightsBudget) {
      return dispatch?.({ type: AirwayBudgetDispatchType.DEPARTURE, paylod: [] });
    }
    dispatch?.({
      type: AirwayBudgetDispatchType.DEPARTURE,
      paylod: flightsList.slice(0, maxBudgetNumber),
    });
  }, [flightsList, allFlightsBudget, maxBudgetNumber]);

  const listRenderer = useCallback(
    (flight: Flight) => {
      const tagCss = {
        "@mxlg": {
          fontSize: "10px",
          border: 0,
        },
      };

      const isSelected =
        bookingState.goFlightSelected?.flight.id === flight.id ||
        bookingState.stretch.some((stretch) => stretch.flight.id === flight.id);

      const isSelectedCombined = isSelected && !!bookingState.goFlightSelected?.isCombined;

      const item: Flight =
        isSelected && bookingState.goFlightSelected ? bookingState.goFlightSelected.flight : flight;

      return (
        <BookingFlightListItem
          data={item}
          flightOrigin={isSelectedCombined || isInternationalCombined ? "combined" : "departure"}
          selectedOption={
            bookingState.goFlightSelected?.flightOption ||
            bookingState.stretch.at(currentStep)?.flightOption
          }
          isSelected={isSelected}
          onSelectFlight={onSelectFlight}
          isInternationalCombined={isInternationalCombined}
          TagsElement={
            <>
              <Tag variant="neutral-light" css={tagCss}>
                {isSelectedCombined ? "RT" : "OW"}
              </Tag>

              {item.discount && (
                <Tag variant="error-light" css={tagCss}>
                  {isAgency && typeof item.discount !== "boolean"
                    ? `- ${asCurrency(item.discount.amount)}`
                    : "$"}
                </Tag>
              )}

              {minimumPrice === item.minimumPrice.amount && (
                <Tag variant="success-light" css={tagCss}>
                  Melhor oferta
                </Tag>
              )}
            </>
          }
          key={item.hash}
        />
      );
    },
    [
      bookingState.goFlightSelected,
      minimumPrice,
      onSelectFlight,
      currentStep,
      isInternationalCombined,
    ]
  );

  return (
    <Row
      css={{
        "@mxlg": {
          flexDirection: "column",
          alignItems: "center",
          p: 0,
        },
      }}
    >
      <Col
        sz="4"
        css={{
          "@mxlg": {
            width: "90%",
          },
        }}
      >
        <FiltersSection
          isLoading={isLoading}
          flightsList={flightsList}
          data={data}
          filters={filters}
          title="Filtrar resultados"
          handleCheckboxChange={handleCheckboxChangeSegment}
          onFilterDurationChange={onFilterDurationChange}
          onFilterChange={onFilterChange}
          setOrderType={setOrderType}
          orderType={orderType}
        />
      </Col>

      <Col
        sz="8"
        css={{
          "@mxlg": {
            width: "100%",
            mt: "$5",
          },
        }}
      >
        {" "}
        <Flex direction="column" gap="6">
          <Flex justify={activeBudget ? "between" : "end"}>
            {activeBudget && (
              <Flex align="center" gap="2">
                <Checkbox checked={allFlightsBudget} onChange={selectAllFlights} />
                <Text fw="500" size="3">
                  Selecionar todos desta página
                </Text>
              </Flex>
            )}
            <Button
              css={{
                backgroundColor: activeBudget ? "$neutrals-black" : "transparent",
                color: activeBudget ? "$neutrals-white" : "$neutrals-darkest",
              }}
              variant="secondary-light"
              disabled={!data.length}
              onClick={() => onActiveBudget(!activeBudget)}
            >
              Cotação eletrônica
            </Button>
          </Flex>

          {isLoading && (
            <Box>
              <Alert variant="info">
                <Spinner size="sm" />
                <Text css={{ lineHeight: "$6" }}>
                  Aguarde, estamos buscando as melhores tarifas para você
                </Text>
              </Alert>
            </Box>
          )}

          <LazyList
            gap="8"
            items={data}
            isLoading={isLoading}
            render={listRenderer}
            skeletonQuantity={10}
            skeletonHeight={200}
            EmptyComponent={
              <EmptyState>
                <Text>Nenhuma passagem encontrada</Text>
              </EmptyState>
            }
          />
        </Flex>
      </Col>
    </Row>
  );
};

GoFlightsSectionUI.displayName = "GoFlightsSectionUI";
