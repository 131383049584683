import { Coverage } from "~/application/types";
import { CoverageDTO } from "../dtos";

export const mapVehicleCoverageDTO = (data: CoverageDTO): Coverage => {
  return {
    id: data.id,
    type: data.type,
    price: parseFloat(data.price),
    description: data.description,
    totalPriceWithCoverage: parseFloat(data?.total_price_with_coverage),
  };
};
