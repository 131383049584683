import { FC } from "react";
import { Icon } from "~/components/Icon";
import { SvgEdit } from "~/components/Icon/icons";
import { IconButton } from "~/components/IconButton";
import { Switch } from "~/components/Input";
import { DataColItem, DataListItem } from "~/components/List/DataListItem";
import { Tooltip, TooltipLabel } from "~/components/Tooltip";
import { Caption } from "~/components/Typography";
import { ReasonTripListItemProps } from "./types";
import useMobile from "../../hooks/useMobile";
import { Flex } from "~/components/Flex";

export const ReasonTripListItem: FC<ReasonTripListItemProps> = ({
  data,
  onEditClick,
  onToggleState,
  ...props
}) => {
  const isMobile = useMobile();

  return (
    <DataListItem data-active={data.isActive} {...props} css={{ "@mxlg": { flexDirection: "column" }}}>
      <DataColItem headerTitle="Motivo" data={data.reason} />

      {onEditClick && !isMobile && (
        <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
          <IconButton size="md" onClick={() => onEditClick(data)}>
            <Icon as={SvgEdit} />
          </IconButton>
        </Tooltip>
      )}

      {onToggleState && !isMobile && (
        <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
          <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
        </Switch>
      )}

      <Flex justify="between" css={{ "@lg": { display: "none" }, "@mxlg": { width: "100%" }}}>
      {onEditClick && (
        <Tooltip content={<TooltipLabel>Editar</TooltipLabel>}>
          <IconButton size="md" onClick={() => onEditClick(data)}>
            <Icon as={SvgEdit} />
          </IconButton>
        </Tooltip>
      )}

      {onToggleState && (
        <Switch checked={data.isActive} onClick={() => onToggleState(data)}>
          <Caption>{data.isActive ? "Ativo" : "Inativo"}</Caption>
        </Switch>
      )}
      </Flex>
    </DataListItem>
  );
};

ReasonTripListItem.displayName = "ReasonTripListItem";
