import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { FlightOption, OrderItems, UserContext } from "~/application/types";
import { NavigatorUtils } from "~/application/utils";
import { log } from "~/application/utils/log";
import { useLogTag } from "~/presentation/core/contexts/LogTagContext";
import { useFilters } from "~/presentation/shared/hooks/useFilters";
import {
  BookingAirwaySteps,
  DYNAMIC_FLIGHT_FILTERS,
  STATIC_FLIGHT_FILTERS,
} from "../../../constants";
import { useFlightsPage } from "../../../contexts/FlightsPageContext";
import { ReturnFlightsSectionUI } from "./ReturnFlightsSectionUI";
import { useRoundTripFlights } from "./hooks/useRoundTripFlights";
import { ReturnFlightType } from "./types";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { flightDuration, flightsDuration } from "~/presentation/shared/utils";
import { useDebounce } from "use-debounce";
import { usePoliciesFormats } from "~/presentation/shared/hooks/usePoliciesFormats";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { AirwayBookingDispatchActionType } from "../../../hooks/useAirwayReducer/types";

const VIEW_ID = BookingAirwaySteps.SELECT_RETURN_TICKET;

export const ReturnFlightsSection: FC = () => {
  const { contexts, user } = useUser();
  const { LOG_TAG } = useLogTag();

  const isMobile = useMobile();
  const customerId = user?.customer?.uuid || contexts?.customer?.uuid;

  const { airwayReducer, flightQuery, orderType, setOrderType, isInternational } = useFlightsPage();

  const { bookingState } = airwayReducer;

  const {
    data: flightsList,
    combinedGoFlight,
    isAvailable,
    isLoading,
  } = useRoundTripFlights({
    enabled: true,
    flightQueryLinks: flightQuery.links,
    retryCount: 50,
    goFlight: bookingState.goFlightSelected,
  });

  const flightsMaped = usePoliciesFormats({
    customerId,
    data: flightsList,
    itemType: OrderItems.AIRWAY,
    isReturn: true,
  }) as ReturnFlightType[];

  const { filteredData, filters, onFilterChange } = useFilters({
    filters: STATIC_FLIGHT_FILTERS.filter((filter) =>
      filter.key === "baggages" ? isInternational : true
    ),
    filtersGenerator: DYNAMIC_FLIGHT_FILTERS,
    itemExtractor: (i) => i.flight,
    data: flightsMaped,
    sort: false,
  });

  const returnFlightsList = filteredData.map((flights) => flights.flight);

  const [duration, setDuration] = useState({ min: 0, max: 0 });
  const [durations] = useDebounce(duration, 700);

  useEffect(() => {
    if (filteredData.length) {
      const { max, min } = flightsDuration(returnFlightsList);
      setDuration({ max, min });
    }
  }, [setDuration]);

  const filterDurationData = useMemo(
    () =>
      filteredData.filter((flight) => {
        const currentFlightDuration = flightDuration(flight.flight);
        return currentFlightDuration <= durations.min;
      }),
    [filteredData, durations]
  );

  const [selectedAirports, setSelectedAirports] = useState<string[]>([]);

  const handleCheckboxChange = useCallback((airport: string | null, isChecked: boolean) => {
    setSelectedAirports((prev) =>
      isChecked && airport ? [...prev, airport] : prev.filter((item) => item !== airport)
    );
  }, []);

  const currentFilteredData =
    duration.max === 0 && duration.min === 0 ? filteredData : filterDurationData;

  const onSelectFlight = useCallback(
    (flight: ReturnFlightType, option: FlightOption) => {
      log.i(LOG_TAG, "onSelectFlight", { flight: flight.flight, option });

      airwayReducer.dispatch({
        type: AirwayBookingDispatchActionType.RETURN_FLIGHT,
        payload: {
          goFlight: {
            flight: bookingState.goFlightSelected!.flight,
            combinedFlight: combinedGoFlight?.flight || null,
            flightOption: bookingState.goFlightSelected!.flightOption,
          },
          returnFlight: {
            combinedFlight: flight.combinedFlight,
            oneWayFlight: flight.oneWayFlight,
            flightOption: option,
          },
        },
      });

      if (!isMobile) {
        NavigatorUtils.scrollToTop(true);
      }
    },
    [bookingState.goFlightSelected, combinedGoFlight]
  );

  const calculateDiscount = useCallback(
    (flight: ReturnFlightType): number => {
      if (!flight.combinedFlight || !combinedGoFlight || !bookingState.goFlightSelected) return 0;

      const returnPrice = flight.oneWayFlight.minimumPrice.amount;
      const cReturnPrice = flight.combinedFlight.minimumPrice.amount;

      const goPrice = bookingState.goFlightSelected.flightOption.pricingInfo.total.amount;
      const cGoPrice = combinedGoFlight.flightOption.pricingInfo.total.amount;

      const oneWayPrice = Number(goPrice) + Number(returnPrice);
      const roundTripPrice = Number(cReturnPrice) + Number(cGoPrice);

      return (oneWayPrice - roundTripPrice) / oneWayPrice;
    },
    [combinedGoFlight, bookingState.goFlightSelected]
  );

  const filteredFlights = useMemo(() => {
    if (!selectedAirports.length) return currentFilteredData;

    return currentFilteredData?.filter((flight) =>
      flight.flight.segments.some((segment) => {
        const destination = segment?.departureFlightInfo.airport;

        return selectedAirports.includes(destination);
      })
    );
  }, [currentFilteredData, selectedAirports]);

  return (
    <ReturnFlightsSectionUI
      data={filteredFlights}
      filters={filters}
      flightsList={returnFlightsList}
      isLoading={isLoading}
      onFilterDurationChange={setDuration}
      handleCheckboxChangeSegment={handleCheckboxChange}
      isAgency={user?.context === UserContext.Agency}
      isAvailable={isAvailable}
      bookingState={bookingState}
      onFilterChange={onFilterChange}
      onSelectFlight={onSelectFlight}
      orderType={orderType}
      setOrderType={setOrderType}
      calculateDiscount={calculateDiscount}
    />
  );
};

ReturnFlightsSection.displayName = "ReturnFlightsSection";
