import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { HotelRoom, PolicyParamsSlug, Traveler } from "~/application/types";
import { orderHotelService, orderService, travelerService } from "~/application/usecases";
import { dialogService } from "~/components/DialogStack";
import { QueryKeys } from "~/constants";
import { LoadingDialog } from "~/core/shared/components/LoadingDialog";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { useCustomerEmployeeDialog } from "~/presentation/shared/hooks/useCustomerEmployeeDialog";
import { queryClient } from "~/services/queryClient";
import { log } from "~/utils/log";
import { useBooking } from "../../../../../contexts/BookingContext";
import { useBookingHotel } from "../../../contexts/BookingHotelContext";
import { HotelRoomSelected } from "../../../types";
import { getHotelDataFromUrl } from "../../../utils";
import { useBrokenPolicyJustification } from "~/core/modules/Order/pages/OrderPage/views/OrderItem/hooks/BrokenPolicyJustificationContext";
import { logError } from "~/presentation/shared/utils/errors";
import { CheckOrderDuplicityDialog } from "~/presentation/shared/views/CheckOrderDuplicityDialog/CheckOrderDuplicityDialog";

export interface UseSelectGuestsResult {
  travelers?: Traveler[];
  roomGuests?: Traveler[];
  roomsSelected?: HotelRoomSelected[];
  isLoadingGuests: boolean;
  onSearchGuest: Dispatch<SetStateAction<string>>;
  fetchGuests: (name: string) => Promise<Traveler[]>;
  onCreateBooking: () => void;
  onCreateCustomerEmployee: () => void;
  onSelectGuest: (guest: Traveler, roomIndex: number, position: number) => void;
}

export interface UseSelectGuestsOptions {
  enabled: boolean;
  issuerId: string;
  setIsShowCart: Dispatch<SetStateAction<boolean>>;
}

interface CreateBookingParams {
  rooms: {
    lowerFareViolated: boolean;
    room: HotelRoom;
    guests: Traveler[];
    brokenPolicyJustification: string | undefined;
  }[]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LOG_TAG = "Booking/BookingHotel/HotelDetailsPage/useSelectGuests";

const SNACKBAR_MESSAGES = {
  CHECK_ORDER_DUPLICITY_ERROR_MESSAGE: "Falha ao checar possível duplicidade de reserva",
} as const;

export function useSelectGuests({
  issuerId,
  setIsShowCart,
}: UseSelectGuestsOptions): UseSelectGuestsResult {
  const { user, contexts } = useUser();
  const customerId = contexts.customer?.uuid;
  const { order } = useBooking();
  const { hotelQuery, hotelReducer } = useBookingHotel();
  const { brokenPolicyItems, scrollToSection } = useBrokenPolicyJustification();

  const [searchGuest, setSearchGuest] = useState("");
  const [search] = useDebounce(searchGuest, 700);
  const navigate = useNavigate();

  const emptySelectedRooms = useMemo<HotelRoomSelected[] | undefined>(
    () =>
      hotelReducer.bookingState.roomsSelected?.map((room, roomIndex) => ({
        room,
        guests: Array(
          hotelQuery!.accommodations[roomIndex]?.adultQuantity +
          hotelQuery!.accommodations[roomIndex].guestsChildren.length
        ).fill(null),
      })),
    [hotelQuery, hotelReducer]
  );

  const [roomsSelected, setRoomsSelected] = useState<HotelRoomSelected[]>(emptySelectedRooms ?? []);

  const fetchGuestsByName = useCallback(
    async (name: string) => {
      return (
        order?.travelers ??
        (await travelerService.find(user.customer ?? contexts.customer, {
          name,
          isActive: "ativo",
        }))
      );
    },
    [order, user, contexts]
  );
  const { handleOpenCreateModal } = useCustomerEmployeeDialog({});

  const { data, isLoading } = useQuery(
    [QueryKeys.HOTEL_TRAVELERS, search],
    async () =>
      await travelerService.find(user.customer ?? contexts.customer, {
        name: searchGuest,
        isActive: "ativo",
      })
  );

  const { mutateAsync: checkDuplicity } = useMutation({
    mutationFn: orderHotelService.checkDuplicity,
    onError(error) {
      logError({
        error,
        logTag: LOG_TAG,
        defaultErrorMessage: SNACKBAR_MESSAGES.CHECK_ORDER_DUPLICITY_ERROR_MESSAGE,
      });
    },
  });

  const handleSelectGuest = useCallback((guest: Traveler, room: number, position: number) => {
    setRoomsSelected((old) => {
      old[room].guests[position] = guest;
      return [...old];
    });
  }, []);

  useEffect(() => {
    let totalGuests = 0;

    hotelQuery?.accommodations.forEach((accommodation) => {
      totalGuests += accommodation.adultQuantity;

      if (accommodation.guestsChildren && accommodation.guestsChildren.length > 0) {
        totalGuests += accommodation.guestsChildren.length;
      }
    });

    const totalGuestsSelected = roomsSelected?.reduce((total, quarto) => {
      return total + quarto.guests.filter((guest) => guest !== null).length;
    }, 0);

    if (totalGuestsSelected === totalGuests) {
      setIsShowCart(true);
    }
  }, [roomsSelected, setIsShowCart]);

  const handleCreateBooking = useCallback(
    async ({ rooms }: CreateBookingParams) => {
      let newOrder = order;

      if (!newOrder) {
        try {
          const travelersSummary = roomsSelected.reduce<Traveler[]>(
            (p, c) => [...p, ...(c.guests as Traveler[])],
            []
          );

          dialogService.showDialog(<LoadingDialog message="Aguarde, estamos criando seu pedido" />);

          newOrder = await orderService.create({
            customerId,
            issuerId,
            travelersIds: travelersSummary.map((t) => t.uuid),
            reasonTripId: hotelQuery?.reasonTrip?.uuid,
          });
        } catch (error) {
          log.e(LOG_TAG, error);
        } finally {
          dialogService.popDialog();
        }
      }

      try {
        dialogService.showDialog(<LoadingDialog message="Aguarde, estamos criando seu pedido" />);

        const hotel = hotelReducer.bookingState.hotelSelected! || getHotelDataFromUrl();

        await orderHotelService.create({
          orderId: newOrder!.uuid,
          hotelQuery: hotelQuery!,
          hotel,
          rooms,
        });

        queryClient.invalidateQueries([QueryKeys.ORDERS, newOrder!.uuid]);
        navigate(`/pedidos/${newOrder!.uuid}`);
      } catch (error) {
        log.e(LOG_TAG, error);
      } finally {
        dialogService.popDialog();
      }
    },
    [order, hotelQuery, hotelReducer, roomsSelected, issuerId]
  );

  const handleCheckDuplicity = useCallback(async () => {
    const items = hotelReducer.bookingState.roomsSelected
      ?.map((room) =>
        room.violatedPolicies.length ? `${room.roomId}-${room.accommodationIndex}` : ""
      )
      .filter((id) => id)
      .filter((item) => !brokenPolicyItems.map((broken) => broken.uuid).includes(item));

    if (items?.length) {
      return scrollToSection(items);
    }

    const rooms = roomsSelected.map(({ room, guests }) => ({
      lowerFareViolated: room.violatedPolicies.some(
        (policy) => policy.policyType === PolicyParamsSlug.LOWER_FARE
      ),
      room: room,
      guests: guests as Traveler[],
      brokenPolicyJustification: brokenPolicyItems.find(
        (brokenPolicy) => brokenPolicy.uuid === `${room.roomId}-${room.accommodationIndex}`
      )?.justification,
    }));

    const data = await checkDuplicity({
      hotelQuery: hotelQuery!,
      rooms: rooms.map((room) => ({ guests: room.guests })),
    });

    if (data.length) {
      return dialogService.showDialog(
        <CheckOrderDuplicityDialog
          data={data}
          onConfirm={async () => {
            dialogService.popDialog();
            await handleCreateBooking({ rooms });
          }}
          onCancel={() => dialogService.popDialog()}
        />
      );
    }

    await handleCreateBooking({ rooms });
  }, [hotelReducer, roomsSelected, brokenPolicyItems]);

  useEffect(() => {
    if (emptySelectedRooms) {
      setRoomsSelected(emptySelectedRooms);
    }
  }, [emptySelectedRooms]);

  return {
    roomsSelected,
    roomGuests: data,
    travelers: order?.travelers,
    isLoadingGuests: isLoading,
    onSearchGuest: setSearchGuest,
    fetchGuests: fetchGuestsByName,
    onSelectGuest: handleSelectGuest,
    onCreateCustomerEmployee: handleOpenCreateModal,
    onCreateBooking: handleCheckDuplicity,
  };
}
