import { VariantProps } from "@stitches/react";
import { ComponentProps } from "react";
import { styled } from "~/application/theme";

export type RadioButtonProps = ComponentProps<typeof RadioButton>;

export type RadioButtonVariants = VariantProps<typeof RadioButton>;

export const RadioButton = styled("span", {
  // Reset
  display: "block",
  boxSizing: "border-box",

  // Custom
  position: "relative",
  borderStyle: "solid",
  borderColor: "$neutrals-dark",
  transition: "$normal",
  borderRadius: "$circular",

  "&::after": {
    content: "",
    display: "none",
    borderRadius: "inherit",
    position: "absolute",
    left: "50%",
    top: "50%",
    size: "45%",
    transform: "translate(-50%, -50%)",
    background: "$primary-base",
  },

  "&:hover": {
    cursor: "pointer",
  },

  "&[data-checked=true]": {
    borderColor: "$primary-base",

    "&::after": {
      display: "block",
    },
  },
  "&[data-checked-white=true]": {
    borderColor: "$neutrals-white",

    "&::after": {
      background: "$neutrals-white",

      display: "block",
    },
  },

  "&[data-disabled=true]": {
    pointerEvents: "none",
    opacity: "$disabled",
  },

  variants: {
    size: {
      sm: {
        borderWidth: "1px",
        size: "$4",
      },
      md: {
        borderWidth: "1px",
        size: "$6",
      },
      lg: {
        borderWidth: "2px",
        size: "$8",
      },
    },
  },

  defaultVariants: {
    size: "md",
  },
});

RadioButton.displayName = "RadioButton";
