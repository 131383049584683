import type { OrderRoad, OrderRoadSegment } from "~/application/types";
import { api } from "~/infrastructure/api";
import { OrderRoadDTO } from "~/infrastructure/api/dtos";
import { mapOrderRoadDTO, mapOrderRoadSegmentDTO } from "~/infrastructure/api/mappers";
import type { IOrderRoadService } from "./IOrderRoadService";
import { CheckRoadOrderDuplicityParams, ICreateOrderRoadData } from "./types";
import { DateUtils } from "~/application/utils";

export class OrderRoadService implements IOrderRoadService {
  async create({
    orderId,
    goRoad,
    returnRoad,
    travelers,
    lowerFareViolated,
    brokenPolicyJustification,
  }: ICreateOrderRoadData): Promise<OrderRoadSegment[]> {
    const departure = {
      search_key: goRoad.road.searchKey,
      travel_id: goRoad.road.id,
      seats: goRoad.seats.map((seat, index) => ({
        seat_number: parseInt(seat.seat),
        passenger_uuid: travelers[index].uuid,
        seats_number_connection:
          goRoad.seatsConnection.length > 0 ? [parseInt(goRoad.seatsConnection[index].seat)] : [],
      })),
      broken_policy_justification: brokenPolicyJustification?.goPolicyJustification,
      lower_fare_violated: lowerFareViolated.departure,
    };

    const arrival = {
      search_key: returnRoad?.road.searchKey,
      travel_id: returnRoad?.road.id,
      seats: returnRoad?.seats.map((seat, index) => ({
        seat_number: parseInt(seat.seat),
        passenger_uuid: travelers?.[index].uuid,
        seats_number_connection:
          returnRoad?.seatsConnection.length > 0
            ? [parseInt(returnRoad.seatsConnection[index].seat)]
            : [],
      })),
      broken_policy_justification: brokenPolicyJustification?.returnPolicyJustification,
      lower_fare_violated: lowerFareViolated.arrival,
    };

    const body = {
      departure: departure,
      arrival: returnRoad ? arrival : undefined,
    };

    return await api
      .post<{ data: OrderRoadDTO }>(`/orders/${orderId}/road`, body)
      .then(({ data: response }) => response.data.map(mapOrderRoadSegmentDTO));
  }

  async findByOrderId(orderId: string): Promise<OrderRoad> {
    return await api
      .get<{ data: OrderRoadDTO }>(`/orders/${orderId}/road`)
      .then(({ data }) => mapOrderRoadDTO(data.data));
  }

  async delete(itemId: string): Promise<void> {
    await api.post<void>(`/orders/road/${itemId}/cancel`);
  }

  async checkDuplicity(
    data: CheckRoadOrderDuplicityParams
  ): Promise<{ orderId: string; orderNumber: string }[]> {
    const outboundFlight = data.goRoad.road;
    const returnFlight = data.returnRoad?.road;

    const originCityId = data.roadQuery?.originSelected?.city?.uuid;
    const destinationCityId = data.roadQuery?.destinationSelected?.city?.uuid;

    const body = {
      travelers: data.travelers.map((traveler) => traveler.uuid),
      origin_city_uuid: originCityId,
      destination_city_uuid: destinationCityId,
      departure: {
        departure_date: DateUtils.toISOString(outboundFlight.departureDate),
        arrival_date: DateUtils.toISOString(outboundFlight.arrivalDate),
      },
      arrival: returnFlight
        ? {
            departure_date: DateUtils.toISOString(returnFlight.departureDate),
            arrival_date: DateUtils.toISOString(returnFlight.arrivalDate),
          }
        : null,
    };

    return api
      .post<{ data: { order_uuid: string; order_number: string }[] }>(
        `/orders/road/check-duplicity`,
        body
      )
      .then(({ data }) =>
        data.data.map(({ order_number, order_uuid }) => ({
          orderId: order_uuid,
          orderNumber: order_number,
        }))
      );
  }
}
