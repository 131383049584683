import { useEffect, useState } from "react";
import { AGENCY_CUSTOMER_ID } from "~/application/constants/storageKeys";
import { DashboardBIPage } from "~/core/modules/Agency/pages/DashboardBIPage/DashboardBIPage";
import { useUser } from "~/presentation/core/contexts/UserContext";
import { CustomerDashboardBI } from "./DashboardCustomerPage";

export function DashboardsBIPage() {
  const { user } = useUser();
  const customerId = localStorage.getItem(AGENCY_CUSTOMER_ID);
  const [component, setComponent] = useState<JSX.Element>(<DashboardBIPage />);

  useEffect(() => {
    if (user?.agency && !customerId) {
      return setComponent(<DashboardBIPage />);
    }
    setComponent(<CustomerDashboardBI />);
  }, [customerId]);

  return component;
}
