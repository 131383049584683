import { useQuery } from "@tanstack/react-query";
import { Fragment, useCallback, useState } from "react";
import { ExpensePolicy, ExpenseType, Policy, PolicyParameterExpense } from "~/application/types";
import { AppBar, AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { Footer } from "~/components/Document";
import { EmptyState } from "~/components/EmptyState";
import { Col, Row } from "~/components/Grid";
import { Icon } from "~/components/Icon";
import { SvgChevronDown, SvgPlus } from "~/components/Icon/icons";
import { MultiSelect } from "~/components/Input/MultiSelect";
import { LazyList } from "~/components/LazyList";
import { Pagination } from "~/components/Pagination";
import { Text } from "~/components/Text";
import { QueryKeys } from "~/constants/queryKeys";
import { ExpensePolicyListItem } from "~/presentation/shared/components/ExpensePolicyListItem";
import { useExpensePoliciesPage } from "./logic/useExpensePoliciesPage";
import useMobile from "~/presentation/shared/hooks/useMobile";
import { Flex } from "~/components/Flex";

export function ExpensePoliciesPage() {
  const {
    data,
    isLoading,
    currentPage,
    lastPage,
    selectedFilters,
    onGoToPage,
    onCreateExpensePolicy,
    onEditExpensePolicy,
    onToggleExpensePolicyState,
    fetchExpenseTypes,
    fetchPolicyParameterExpenses,
    fetchPolicies,
    onChangeFilterExpenseTypes,
    onChangeFilterPolicies,
    onChangeFilterPolicyParameterExpenses,
    onCleanFilters,
  } = useExpensePoliciesPage();

  const isMobile = useMobile();
  const [filterState, setFilterState] = useState(isMobile ? true : false);

  const listRenderer = useCallback(
    (item: ExpensePolicy) => (
      <ExpensePolicyListItem
        data={item}
        onEditClick={onEditExpensePolicy}
        onToggleState={onToggleExpensePolicyState}
        key={item.uuid}
      />
    ),
    [onEditExpensePolicy, onToggleExpensePolicyState]
  );

  const { data: expenseTypes, isFetching: isFetchingExpenseTypes } = useQuery(
    [QueryKeys.CUSTOMER_EXPENSE_TYPES],
    fetchExpenseTypes,
    {
      cacheTime: 0,
      retry: 2,
    }
  );

  const { data: policyParameterExpenses, isFetching: isFetchingPolicyParameterExpenses } = useQuery(
    [QueryKeys.POLICY_PARAMETER_EXPENSES],
    fetchPolicyParameterExpenses,
    {
      cacheTime: 0,
      retry: 2,
    }
  );

  const { data: policies, isFetching: isFetchingPolicies } = useQuery(
    [QueryKeys.CUSTOMER_POLICIES],
    fetchPolicies,
    {
      cacheTime: 0,
      retry: 2,
    }
  );

  const getPolicyLabel = useCallback((item: Policy) => item.name, []);
  const getPolicyValue = useCallback((item: Policy) => item.uuid, []);

  const getExpenseTypeLabel = useCallback((item: ExpenseType) => item.name, []);
  const getExpenseTypeValue = useCallback((item: ExpenseType) => item.uuid, []);

  const getPolicyParameterExpenseLabel = useCallback(
    (item: PolicyParameterExpense) => item.description,
    []
  );
  const getPolicyParameterExpenseValue = useCallback(
    (item: PolicyParameterExpense) => item.type,
    []
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Políticas" />

          <AppBarActions>
            <Button
              title="Nova política"
              color="primary"
              onClick={onCreateExpensePolicy}
              css={{ fill: isMobile ? "#000" : undefined, background: "#fff" }}
            >
              <Icon as={SvgPlus} />
              {!isMobile && <Text>Nova política</Text>}
            </Button>
          </AppBarActions>
        </AppBarContainer>

        <Container css={{ py: "$6", borderTop: "1px solid $colors$neutrals-light" }}>
          <Row
            gap="4"
            align="center"
            wrap={{ "@lg": "noWrap" }}
            css={{ display: filterState ? "none" : "flex" }}
          >
            <Col>
              <MultiSelect
                value={selectedFilters.policies}
                options={policies}
                isLoading={isFetchingPolicies}
                onChange={onChangeFilterPolicies}
                getOptionLabel={getPolicyLabel}
                getOptionValue={getPolicyValue}
                placeholder="Política"
                portalled
              />
            </Col>

            <Col>
              <MultiSelect
                value={selectedFilters.policyParameterExpenses}
                options={policyParameterExpenses}
                isLoading={isFetchingPolicyParameterExpenses}
                onChange={onChangeFilterPolicyParameterExpenses}
                getOptionLabel={getPolicyParameterExpenseLabel}
                getOptionValue={getPolicyParameterExpenseValue}
                placeholder="Regra"
                portalled
              />
            </Col>

            <Col>
              <MultiSelect
                value={selectedFilters.expenseTypes}
                options={expenseTypes}
                isLoading={isFetchingExpenseTypes}
                onChange={onChangeFilterExpenseTypes}
                getOptionLabel={getExpenseTypeLabel}
                getOptionValue={getExpenseTypeValue}
                placeholder="Despesa"
                portalled
              />
            </Col>

            <Col sz="auto" css={{ "@mxlg": { width: "100%" } }}>
              <Button
                title="Limpar filtros"
                variant="tertiary"
                onClick={onCleanFilters}
                css={{ "@mxlg": { width: "100%" } }}
              >
                <Text>Limpar filtros</Text>
              </Button>
            </Col>
          </Row>
          {isMobile && (
            <Flex justify="center" css={{ p: "$2" }}>
              <Icon as={SvgChevronDown} onClick={() => setFilterState((prev) => !prev)} />
            </Flex>
          )}
        </Container>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20", "@mxlg": { p: "$5" } }}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui regras cadastradas</Text>
            </EmptyState>
          }
        />
      </Container>

      <Footer>
        <Container>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </Container>
      </Footer>
    </Fragment>
  );
}
