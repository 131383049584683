import { FC, useCallback, useMemo, useRef } from "react";
import { Collapse } from "react-collapse";
import { MaskUtils, StringUtils } from "~/application/utils";
import { Box } from "~/components/Box";
import { Button } from "~/components/Button";
import { CompanyLogo } from "~/components/CompanyLogo";
import { Flex } from "~/components/Flex";
import { Grid } from "~/components/Grid";
import { Text } from "~/components/Text";
import { H4, H5, Label } from "~/components/Typography";
import { formatFlightInfo, getAirlineUrl } from "~/presentation/shared/utils";
import useMobile from "../../hooks/useMobile";
import { TrackDots } from "../Track";
import { ViolatedPoliciesButton } from "../ViolatedPoliciesButton/ViolatedPoliciesButton";
import { FlightOptionItem } from "./FlightOptionItem";
import { Styled } from "./styled";
import { FlightListItemProps } from "./types";
import { Divider } from "~/components/Divider";
import { AirlineCode, FlightOption } from "~/application/types";
import { displayDate } from "~/utils/date.utils";
import { DateFormats, timestampInterval } from "~/application/utils/date-functions";
import { Icon } from "~/components/Icon";
import { SvgClose, SvgLuggage } from "~/components/Icon/icons";
import slugify from "slugify";
import InternationalCombined from "../InternationalCombined/InternationalCombined";
import { airlineImageSize } from "./utils/utils";

export enum Airlines {
  UNITED_AIRLINES = "United-Airlines",
  AIR_CANADA = "Air-Canada",
  DELTA_AIRLINES = "Delta-Airlines"
}

export const FlightListItem: FC<FlightListItemProps> = ({
  flightQuery,
  isInternationalCombined,
  isInternational,
  data,
  isSelected,
  selectedOption,
  optionsEnabled,
  optionsVisibility,
  TagsElement,
  OptionsElement,
  onOpenDetails,
  onSelectFlight,
  onChangeOptionsVisibility,
  ...props
}) => {
  const targetElementRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useMobile();

  const scrollOptions = (scrollTo: ScrollLogicalPosition) => {
    if (isMobile && targetElementRef.current) {
      targetElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: scrollTo,
        inline: "nearest",
      });
    }
  };

  const onClickOptions = (scrollTo: ScrollLogicalPosition) => {
    scrollOptions(scrollTo);
    onChangeOptionsVisibility();
  };

  const flightInfo = useMemo(
    () => formatFlightInfo(data, isInternationalCombined, flightQuery),
    [data]
  );

  const scales = flightInfo.flight.segments.length - 1;

  const displayText = useCallback(
    (scale?: number) => {
      if (!scale) return StringUtils.formatSentence([scales, "conexão", "conexões"]);
      return StringUtils.formatSentence([scale, "conexão", "conexões"]);
    },
    [data]
  );

  const airlines = Array.from(new Set(data.segments.map((segment) => segment.airline)));

  return (
    <Styled.Container
      ref={targetElementRef}
      data-open={optionsVisibility}
      data-active={isSelected}
      css={{
        "@mxxxl": {
          px: 0,
          py: "$5",
        },
      }}
      {...props}
    >
      {/* Header */}
      <Flex
        align="center"
        justify="between"
        gap="4"
        wrap="wrap"
        css={{
          p: "$5",
          "@mxxxl": {
            pb: 0,
          },
        }}
        {...props}
      >
        <Flex align="center" gap="4">
          {airlines.map((airline) => {
            return (
              <CompanyLogo
                key={airline}
                src={getAirlineUrl(slugify(airline.toString()))}
                size={airlineImageSize(slugify(airline.toString()))}
              />
            );
          })}
        </Flex>

        <Flex gap="2" wrap="wrap">
          {TagsElement}

          <Button
            variant="tertiary"
            size="sm"
            css={{
              display: "none",

              "@mxxxl": {
                display: "flex",
                px: "$2",
                height: "$6",
              },
            }}
            onClick={() => onOpenDetails(flightInfo.flight)}
          >
            <Text
              css={{
                "@mxxxl": {
                  fontSize: "12px",
                },
              }}
            >
              Ver detalhes
            </Text>
          </Button>

          {!!data.violatedPolicies?.length && (
            <ViolatedPoliciesButton data={data.violatedPolicies} />
          )}

          <Button
            variant="tertiary"
            size="sm"
            css={{
              "@mxxxl": {
                display: "none",
              },
            }}
            onClick={() => onOpenDetails(flightInfo.flight)}
          >
            <Text
              css={{
                "@mxxxl": {
                  fontSize: "12px",
                },
              }}
            >
              Ver detalhes
            </Text>
          </Button>
        </Flex>
      </Flex>
      {isInternationalCombined ? (
        <Flex
          css={{
            p: "$6",
          }}
          justify="center"
          direction={"column"}
          gap="3"
        >
          <InternationalCombined data={data} flightQuery={flightQuery} />

          <Flex
            css={{
              p: "$2",
              width: "100%",
              cursor: "pointer",
            }}
            gap="3"
            direction={"column"}
          >
            <Divider
              css={{
                width: "100%",
                height: "1px",
                backgroundColor: "$neutrals-base",
                alignSelf: "stretch",
              }}
            />
            <Flex
              css={{
                width: "100%",
              }}
              align="end"
              justify="between"
              onClick={() => onSelectFlight(flightInfo.flight, data.options?.at(0) as FlightOption)}
            >
              <Flex direction="column" gap="3">
                <Label>A partir de</Label>

                <H4 css={{ fw: 600 }}>
                  {MaskUtils.asCurrency(flightInfo.flight.minimumPrice.amount)}
                </H4>
              </Flex>
              <Button disabled={isSelected && selectedOption?.id === data.options?.at(0)?.id}>
                Reservar
              </Button>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex
          direction={{ "@mxxxl": "column" }}
          gap="6"
          css={{
            p: "$6",
            "@mxxxl": {
              p: 0,
            },
          }}
        >
          <Flex
            gap="8"
            justify="between"
            css={{
              pt: "$6",
              width: "80%",
              flex: "1",
              margin: "0 auto",
            }}
          >
            <Flex
              direction="column"
              justify="between"
              gap="2"
              css={{ flex: "1 0 0%" }}
              align={!isInternational ? { "@mxxxl": "start" } : "center"}
            >
              <Label css={{ fw: 400 }}>Saída</Label>
              <H5>{flightInfo.departureFlightInfo.airportIata}</H5>
              <Text size={{ "@mxxxl": "1" }}>{flightInfo.departureFlightInfo.airport}</Text>
              <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
                {flightInfo.departureFlightInfo.date}
              </Text>
              <Label css={{ fw: 400 }}>{flightInfo.departureFlightInfo.dateTimeHour12}</Label>
            </Flex>

            <Flex
              direction="column"
              align={!isInternational ? { "@mxxxl": "center" } : "center"}
              gap="4"
              css={{
                flex: "1 1 0%",
                width: "$40",
                "@xl": {
                  ml: "$4",
                },
              }}
            >
              <Label css={{ fw: 400 }}>Duração</Label>
              <Text size="3">{flightInfo.flightDuration}</Text>

              {scales > 0 &&
                (isMobile ? (
                  <Box>
                    <Text variant="primary" size="2" css={{ lineHeight: 1.6, fw: 600 }}>
                      {displayText()}
                    </Text>
                    <TrackDots numberOfDots={scales} />
                    <Text size="3" css={{ lineHeight: 1.25 }}>
                      Parada{flightInfo.connectionsAirportIatas.length > 1 && "s"}:{" "}
                      {flightInfo.connectionsAirportIatas.map((iata) => `(${iata}) `)}
                    </Text>
                  </Box>
                ) : (
                  <>
                    <Box css={{ width: "70px" }}>
                      <TrackDots numberOfDots={scales} />
                      <Text variant="primary" size="2" css={{ lineHeight: "1.6", fw: 600 }}>
                        {displayText()}
                      </Text>
                    </Box>
                    <Text size="3" css={{ lineHeight: 1.25 }}>
                      Parada{flightInfo.connectionsAirportIatas.length > 1 && "s"}:{" "}
                      {flightInfo.connectionsAirportIatas.map((iata) => `(${iata}) `)}
                    </Text>
                  </>
                ))}

              <Flex direction="column">
                <Label css={{ fw: 400 }}>Voo {data.segments[0].flightNumber}</Label>
              </Flex>
            </Flex>

            <Flex
              align={!isInternational ? { "@mxxxl": "end" } : "center"}
              justify={"between"}
              direction="column"
              css={{ flex: "1 0 0%" }}
            >
              <Label css={{ fw: 400 }}>Chegada</Label>
              <H5>{flightInfo.arrivalInfo.airportIata}</H5>
              <Text size={{ "@mxxxl": "1" }}>{flightInfo.arrivalInfo.airport}</Text>
              <Text css={{ "@mxxxl": { display: "none" }, lineHeight: 1.25 }} size="3">
                {flightInfo.arrivalInfo.date}
              </Text>
              <Label css={{ fw: 400 }}>{flightInfo.arrivalInfo.dateTimeHour12}</Label>
            </Flex>
          </Flex>

          {/* TODO: Questionar como exibir bagagens */}
          {!isInternational && (
            <Flex
              direction="column"
              gap="4"
              css={{
                alignItems: "flex-end",
                pt: "$6",
                "@mxxxl": {
                  alignItems: "center",
                  pt: 0,
                },
              }}
            >
              <Label css={{ ta: "end" }}>A partir de</Label>

              <H4 css={{ fw: 600, ta: "end" }}>
                {MaskUtils.asCurrency(flightInfo.flight.minimumPrice.amount)}
              </H4>

              {optionsEnabled && (
                <Button
                  css={{
                    "@mxxxl": {
                      width: "90%",
                    },
                  }}
                  onClick={() => onClickOptions("start")}
                >
                  {optionsVisibility ? "Ocultar opções" : "Ver opções"}
                </Button>
              )}
            </Flex>
          )}
        </Flex>
      )}

      {isInternational && !isInternationalCombined && (
        <>
          <Flex
            justify="between"
            css={{
              p: "$6",
            }}
          >
            <Flex align="center" gap="2">
              <Icon as={SvgLuggage} size="md" />
              <Text fw="600">Bagagem</Text>
            </Flex>
            <Flex direction="column" align="end" gap="1">
              {data.baggages?.length ? (
                data.baggages.map(({ amount, included, weight }, index) => (
                  <Text
                    key={index}
                    variant={!index ? "black" : "darkest"}
                    fw={!index ? "400" : "300"}
                    size={!index ? "3" : "2"}
                  >
                    {amount}º bagagem: {weight}kg {included ? "(inclusa)" : ""}
                  </Text>
                ))
              ) : (
                <Icon as={SvgClose} variant={"error"} />
              )}
            </Flex>
          </Flex>
          <Flex
            css={{
              width: "100%",
              px: "$6",
              pb: "$6",
            }}
            align="end"
            justify="between"
            onClick={() => onSelectFlight(flightInfo.flight, data.options?.at(0) as FlightOption)}
          >
            <Flex direction="column" gap="3">
              <Label>A partir de</Label>

              <H4 css={{ fw: 600 }}>
                {MaskUtils.asCurrency(flightInfo.flight.minimumPrice.amount)}
              </H4>
            </Flex>
            <Button disabled={isSelected && selectedOption?.id === data.options?.at(0)?.id}>
              Reservar
            </Button>
          </Flex>
        </>
      )}

      {optionsEnabled && (
        <Flex
          justify="start"
          css={{
            "@mxxxl": {
              overflowX: "scroll",
            },
          }}
        >
          <Collapse isOpened={optionsVisibility}>
            <Grid
              columns={{ "@initial": "3", "@mxxxl": "2" }}
              gap="8"
              css={{
                p: "$6 $4",
                justifyContent: "space-between",
                "@mxmd": {
                  display: "flex",
                  width: "120%",
                  overflowX: "auto",
                },
              }}
            >
              {OptionsElement ||
                data.options.map((option) => (
                  <FlightOptionItem
                    flight={data}
                    data={option}
                    css={{
                      "@mxmd": {
                        width: "110%",
                      },
                    }}
                    isSelected={isSelected && selectedOption?.id === option.id}
                    onSelect={() => {
                      onSelectFlight(flightInfo.flight, option);
                      scrollOptions("end");
                    }}
                    key={`${flightInfo.flight.hash}_${option.id}`}
                  />
                ))}
            </Grid>
          </Collapse>
        </Flex>
      )}
    </Styled.Container>
  );
};

FlightListItem.displayName = "FlightListItem";
