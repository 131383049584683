import { Fragment, useCallback } from "react";
import { CostCenter } from "~/application/types";
import { AppBar, AppBarActions, AppBarContainer, AppBarHeading } from "~/components/AppBar";
import { Button } from "~/components/Button";
import { Container } from "~/components/Container";
import { EmptyState } from "~/components/EmptyState";
import { Icon } from "~/components/Icon";
import { SvgPlus } from "~/components/Icon/icons";
import { LazyList } from "~/components/LazyList";
import { Text } from "~/components/Text";
import { CostCenterListItem } from "~/presentation/shared/components/CostCenterListItem";
import { useCostCentersPage } from "./logic/useCostCentersPage";
import { SearchBar } from "~/components/Input";
import { Card, CardBody } from "~/components/Card";
import { Pagination } from "~/components/Pagination";
import useMobile from "~/presentation/shared/hooks/useMobile";

export function CostCentersPage() {
  const {
    data,
    isLoading,
    searchText,
    currentPage,
    lastPage,
    onGoToPage,
    setSearchText,
    onCreateCostCenter,
    onEditCostCenter,
    onToggleActiveCostCenter,
  } = useCostCentersPage();

  const isMobile = useMobile()

  const listRenderer = useCallback(
    (item: CostCenter) => (
      <CostCenterListItem
        data={item}
        onEditClick={onEditCostCenter}
        onToggleState={onToggleActiveCostCenter}
        key={item.uuid}
      />
    ),
    [onEditCostCenter, onToggleActiveCostCenter]
  );

  return (
    <Fragment>
      <AppBar>
        <AppBarContainer>
          <AppBarHeading title="Centros de custo" css={{ "@mxlg": { display: "none" }}} />

          <AppBarActions>
            <SearchBar search={searchText} onSearchChange={setSearchText} placeholder="Encontrar" />

            <Button title="Novo centro de custo" color="primary" css={{ background: "White" }} onClick={onCreateCostCenter}>
              <Icon as={SvgPlus} fill="#000" />
              {!isMobile && <Text>Novo centro de custo</Text>}
            </Button>
          </AppBarActions>
        </AppBarContainer>
      </AppBar>

      <Container css={{ pt: "$10", pb: "$20", "@mxlg": { p: "30px" }}}>
        <LazyList
          gap="6"
          items={data}
          isLoading={isLoading}
          render={listRenderer}
          skeletonQuantity={8}
          skeletonHeight={92}
          EmptyComponent={
            <EmptyState>
              <Text>Você ainda não possui centros de custos cadastrados</Text>
            </EmptyState>
          }
        />
      </Container>

      <Card>
        <CardBody>
          <Pagination activePage={currentPage} pagesCount={lastPage} setActivePage={onGoToPage} />
        </CardBody>
      </Card>
    </Fragment>
  );
}
